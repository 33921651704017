import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import {
    planCancellationFeeSelector,
    isPlanCancellationFeeLoadingSelector
} from '@hero/redux-data/planCancellationFee/selectors';
import { fetchPlanCancellationFee } from '@hero/redux-data/planCancellationFee/actionCreators';

import Takeover, { WarnTakeoverProps } from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';
import BenefitListItem from '../components/BenefitListItem';
import Container from '@hero/ui-kit/layout/Container';
import { isBefore, parse } from 'date-fns';
import A from '@hero/ui-kit/typography/A';
import Loader from '@hero/ui-kit/components/Loader';

const COMPARE_DATE = '2020-07-31';

const MembershipConfirmCancellation: React.FC<Pick<WarnTakeoverProps, 'actions'>> = ({
    actions
}) => {
    const dispatch = useDispatch();
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const planCancellationFee = useSelector(planCancellationFeeSelector, shallowEqual);
    const isLoading = useSelector(isPlanCancellationFeeLoadingSelector, shallowEqual);

    React.useEffect(() => {
        dispatch(fetchPlanCancellationFee());
    }, [dispatch]);

    const activatedBefore = React.useMemo(() => {
        const dateActivated = membershipDetails?.activated_at;
        if (dateActivated) {
            const dateToCompare = parse(COMPARE_DATE, 'yyyyy-MM-dd', new Date());
            return isBefore(new Date(dateActivated), dateToCompare);
        }
        return false;
    }, [membershipDetails]);

    const chargeText = React.useMemo(() => {
        const fee = planCancellationFee?.cancellation_fee || 0;

        return fee > 0
            ? `, you will be charged a $${fee / 100}`
            : `, you will not be charged any cancellation fee.`;
    }, [planCancellationFee]);

    const link = React.useMemo(() => {
        return activatedBefore
            ? 'https://herohealth.com/returns-old'
            : 'https://herohealth.com/returns';
    }, [activatedBefore]);

    if (!membershipDetails) return null;

    return isLoading ? (
        <Loader />
    ) : (
        <Takeover type="warn" title="We are sorry to see you go..." actions={actions}>
            <P size="large" centered>
                Helping you manage your medications and health is our top priority.
            </P>
            <P size="large" centered strong>
                By canceling your membership, you will not be able to:
            </P>
            <Container>
                <BenefitListItem
                    status="fail"
                    benefit="Use the Hero device to store, sort or dispense your medications."
                />
                <BenefitListItem
                    status="fail"
                    benefit="Get medication reminders or alerts in case of a missed dose."
                />
                <BenefitListItem status="fail" benefit="Track your progress with Hero app." />
            </Container>

            <P size="large" centered>
                Based on your <A to={link}>membership agreement</A>
                {chargeText}
            </P>
        </Takeover>
    );
};

export default MembershipConfirmCancellation;
