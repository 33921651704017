import React from 'react';

const BackspaceImage: React.FC = () => (
    <svg width="55" height="35" viewBox="0 0 55 35">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#E6E5E0"
                d="M16.197.787h32.748a6 6 0 0 1 6 6v21.426a6 6 0 0 1-6 6H16.197L1.302 20.436a4 4 0 0 1 0-5.872L16.197.787z"
            />
            <path
                stroke="#979797"
                strokeLinecap="round"
                strokeWidth="1.6"
                d="M26.26 10.26l12.223 12.223M38.74 10.26L26.517 22.483"
            />
        </g>
    </svg>
);

export default BackspaceImage;
