import React from 'react';

import Takeover, { Props as TakeoverProps } from '@hero/ui-kit/components/Takeover';

const MembershipDowngraded: React.FC<Pick<TakeoverProps, 'externalControls'>> = ({
    externalControls
}) => (
    <Takeover title="Membership Downgraded" type="success" externalControls={externalControls}>
        Your Hero membership plan has been downgraded.
    </Takeover>
);

export default MembershipDowngraded;
