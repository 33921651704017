import { Pill } from '@hero/hero-types';

import { editPill, EditPill } from '@hero/redux-data/pill/actionCreators';

import { EditPillFormValues } from './getEditFormDefaults';

type SubmitPill = (
    cb: (data: ReturnType<EditPill>) => void
) => (pill: Pill) => (values: EditPillFormValues) => void;
const submitPill: SubmitPill = (cb) => (pill) => (values) => {
    const { requires_pin, isUnlimited, max_doses, exact_pill_count } = values;

    analytics.track('Update Medication Started');

    cb(
        editPill({
            ...pill,
            requires_pin,
            max_doses: !isUnlimited && max_doses ? +max_doses : 1000,
            exact_pill_count: exact_pill_count && +exact_pill_count ? +exact_pill_count : null
        })
    );
};

export default submitPill;
