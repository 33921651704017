import React from 'react';

import CheckboxGroup from '@hero/ui-kit/inputs/CheckboxGroup';
import Checkbox from '@hero/ui-kit/inputs/Checkbox';
import H from '@hero/ui-kit/typography/H';

const Passcode: React.FC = () => (
    <>
        <H role="h4">Protect with Passcode? (Optional)</H>
        <CheckboxGroup name="requires_pin" displayName="Use passcode protection">
            <Checkbox name="requires_pin" labelText="Enable" />
        </CheckboxGroup>
    </>
);

export default Passcode;
