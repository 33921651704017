import { all } from 'redux-saga/effects';
import { ConstAccessParams } from '@hero/api-services/types';
import {
    watchLoginCalls,
    watchUserStatusCalls,
    watchDeleteAccountCalls,
    watchGetNewUserStatusCalls,
    watchUserInfoCalls
} from '@hero/redux-data/userLogin/saga';
import { safetySettingsCall, setSafetySettingsCall } from '@hero/redux-data/safetySettings/saga';
import { watchGetUserFlagsCalls } from '@hero/redux-data/userFlags/saga';
import { watchUpdateAccountDetailsCall } from '@hero/redux-data/accountDetailsUpdate/saga';
import { watchSendTicketCall } from '@hero/redux-data/accountSupport/saga';
import {
    watchGetAlertNotificationCalls,
    watchSetAlertNotificationCalls,
    watchSetAlertChannelCalls
} from '@hero/redux-data/notification/saga';
import { watchGetDayToDayPills } from '@hero/redux-data/medication/saga';
import { watchCheckOldPasswordCall } from '@hero/redux-data/accountPassword/saga';
import { watchUpdatePasswordCall } from '@hero/redux-data/accountNewPassword/saga';
import { watchUnsubscribeCalls } from '@hero/redux-data/unsubscribe/saga';
import { watchListPlansCalls } from '@hero/redux-data/membershipListPlans/saga';
import { watchMembershipChangePlanCalls } from '@hero/redux-data/membershipChangePlan/saga';
import { watchPaymentUpdateCalls } from '@hero/redux-data/membershipPaymentUpdate/saga';
import {
    watchAddPillsCalls,
    watchMovePillsCalls,
    watchRemovePillsCalls,
    watchSetDayToDayPills,
    watchSetRefillStateCalls
} from '@hero/redux-data/pills/saga';
import {
    watchFetchPillOptionsStubCalls,
    watchFetchPillOptionsNameCalls,
    watchFetchPillOptionsFormCalls,
    watchFetchPillOptionsStrengthCalls
} from '@hero/redux-data/pillOptions/saga';
import { watchReactToSocketCalls } from '@hero/redux-data/sockets/saga';
import { watchSchedulesCalls, watchEditSchedulesCalls } from '@hero/redux-data/schedules/saga';
import { watchAddPillCalls, watchEditPillCalls, watchRemovePillCalls } from '@hero/redux-data/pill/saga';
import { watchSetPasscodeCall, watchRemovePasscodeCall } from '@hero/redux-data/accountPasscode/saga';
import { watchSetPhoneCalls, watchVerifyPhoneCalls } from '@hero/redux-data/setPhone/saga';
import {
    watchRefreshTokenCalls,
    watchLoginDoneCalls,
    watchLogoutCalls
} from '@hero/redux-data/auth/saga';
import { watchSetDataOptoutCalls } from '@hero/redux-data/accountDataOptout/saga';
import {
    watchEmailUpdateCalls,
    watchEmailUpdateConfirmCall
} from '@hero/redux-data/accountEmailUpdate/saga';
import { watchGetCaregiversCalls } from '@hero/redux-data/accountCaregivers/saga';
import { watchExpandShortlinkCalls } from '@hero/redux-data/shortlink/saga';
import { watchSendSmsAppLinkCalls } from '@hero/redux-data/smsAppLink/saga';
import { watchVerifyEmailCalls } from '@hero/redux-data/emailVerification/saga';
import { watchActivateUserCalls } from '@hero/redux-data/userActivation/saga';
import { watchFetchPlanCancellationFeeCalls } from '@hero/redux-data/planCancellationFee/saga';

export default function* rootSaga(constAccessParams: ConstAccessParams) {
    yield all([
        watchLoginCalls(constAccessParams),
        watchLogoutCalls(),
        watchUserStatusCalls(constAccessParams),
        watchDeleteAccountCalls(constAccessParams),
        watchGetNewUserStatusCalls(constAccessParams),
        watchUserInfoCalls(constAccessParams),
        watchGetUserFlagsCalls(constAccessParams),
        watchGetAlertNotificationCalls(constAccessParams),
        watchSetAlertNotificationCalls(constAccessParams),
        watchSetAlertChannelCalls(constAccessParams),
        safetySettingsCall(constAccessParams),
        setSafetySettingsCall(constAccessParams),
        watchUpdateAccountDetailsCall(constAccessParams),
        watchCheckOldPasswordCall(constAccessParams),
        watchUpdatePasswordCall(constAccessParams),
        watchSendTicketCall(constAccessParams),
        watchUnsubscribeCalls(constAccessParams),
        watchGetDayToDayPills(constAccessParams),
        watchSendTicketCall(constAccessParams),
        watchListPlansCalls(constAccessParams),
        watchMembershipChangePlanCalls(constAccessParams),
        watchPaymentUpdateCalls(constAccessParams),
        watchAddPillsCalls(constAccessParams),
        watchMovePillsCalls(constAccessParams),
        watchRemovePillsCalls(constAccessParams),
        watchSetDayToDayPills(constAccessParams),
        watchSetRefillStateCalls(constAccessParams),
        watchFetchPillOptionsStubCalls(constAccessParams),
        watchFetchPillOptionsNameCalls(constAccessParams),
        watchFetchPillOptionsFormCalls(constAccessParams),
        watchFetchPillOptionsStrengthCalls(constAccessParams),
        watchReactToSocketCalls(),
        watchSchedulesCalls(constAccessParams),
        watchEditSchedulesCalls(constAccessParams),
        watchAddPillCalls(constAccessParams),
        watchEditPillCalls(constAccessParams),
        watchRemovePillCalls(constAccessParams),
        watchSetPasscodeCall(constAccessParams),
        watchRemovePasscodeCall(constAccessParams),
        watchSetPhoneCalls(constAccessParams),
        watchVerifyPhoneCalls(constAccessParams),
        watchRefreshTokenCalls(),
        watchSetDataOptoutCalls(constAccessParams),
        watchEmailUpdateCalls(constAccessParams),
        watchEmailUpdateConfirmCall(constAccessParams),
        watchGetCaregiversCalls(constAccessParams),
        watchExpandShortlinkCalls(constAccessParams),
        watchSendSmsAppLinkCalls(constAccessParams),
        watchVerifyEmailCalls(constAccessParams),
        watchActivateUserCalls(constAccessParams),
        watchFetchPlanCancellationFeeCalls(constAccessParams),
        watchLoginDoneCalls()
    ]);
}
