import React from 'react';
import { useDispatch } from 'react-redux';

import { Step } from '@hero/ui-kit/inputs/WizardForm';

import { setPillOptionsStrength } from '@hero/redux-data/pillOptions/actionCreators';

import validationSchema from '@hero/validators/forms/addMedicationSchema/userStepStrength';
import UserStepStrengthInner from './UserStepStrengthInner';

const UserStepStrength: React.FC = () => {
    const stepNo = 1;
    const dispatch = useDispatch();

    const handleSetStepValues = (values: {
        strength?: number | null;
        unit?: 'g' | 'mg' | 'mcg' | 'iu' | null;
    }) => {
        dispatch(setPillOptionsStrength({ strength: `${values.strength} ${values.unit}` }));
        analytics.track('Dose Strength Completed');
    };

    return (
        <Step<typeof validationSchema>
            order={stepNo}
            validationSchema={validationSchema}
            onSetStepValues={handleSetStepValues}
        >
            <UserStepStrengthInner stepNo={stepNo} />
        </Step>
    );
};

export default UserStepStrength;
