const handledPaths = [
    // TODO legacy, remove in Q3/Q4 2021
    'unsubscribe/hero-weekly',
    'unsubscribe/hero-fill',
    'unsubscribe/hero-disconnected',
    // end legacy
    'continue-setup-with-hero-app',
    'unsubscribe/mailing-list',
    'change-email-confirm',
    'register-caregiver',
    'register-activate',
    'invite/caregiver',
    'reset-password',
    'verify-email',
    'refill',
    'select-a-plan'
];

type IsHandledRedirectPath = (pathname: string) => boolean;
const isHandledRedirectPath: IsHandledRedirectPath = (pathname) =>
    handledPaths.some((path) => pathname.includes(path));

export default isHandledRedirectPath;
