import React, { CSSProperties } from 'react';

import { Size } from '@hero/branding/types';

type Props = { size?: Size; children?: React.ReactNode };

const InnerWrapper: React.FC<Props> = ({ size = 'regular', children }) => {
    const styles: CSSProperties = {
        width: '100%',
        maxWidth: size === 'small' ? '36rem' : size === 'regular' ? '48rem' : '64rem',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    return <div style={styles}>{children}</div>;
};

export default InnerWrapper;
