import { useState, ChangeEvent } from 'react';

export type AdditionalStripeFields = {
    name_on_card: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
};

const initialValues: AdditionalStripeFields = {
    name_on_card: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: ''
};

const useAdditionalFields = () => {
    const [values, setValue] = useState(initialValues);
    const [errors, setErrors] = useState(initialValues);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { name, value } = e.currentTarget;
        setValue({ ...values, [name]: value });
        if (name !== 'address2') {
            setErrors({ ...errors, [name]: value ? '' : 'This field is required' });
        }
    };

    return { values, handleChange, errors, setErrors };
};

export default useAdditionalFields;
