import React from 'react';

import { Schedule } from '@hero/hero-types';
import getScheduledDoses from '@hero/hero-utils/getScheduledDoses';

import MaximumDosage from '../../formSections/MaximumDosage';

type Props = {
    schedules?: Schedule[];
    pillName: string;
    isRx: boolean;
};

const EditMaximumDosage: React.FC<Props> = ({ schedules, isRx, pillName }) => {
    const scheduledDosesCount = getScheduledDoses(schedules);

    return (
        <MaximumDosage
            scheduledDosesCount={scheduledDosesCount}
            isRx={isRx}
            isAsNeeded={!scheduledDosesCount}
            pillName={pillName}
        />
    );
};

export default EditMaximumDosage;
