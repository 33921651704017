import React from 'react';

import Takeover from '@hero/ui-kit/components/Takeover';
import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';

const MobileOnly: React.FC = () => {
    return (
        <Takeover icon={MobileIcon} type="brand" title="Mobile-Only Feature">
            Please tap on this link on your smartphone.
        </Takeover>
    );
};

export default MobileOnly;
