import React, { useMemo, CSSProperties } from 'react';

import usePageTitle from '@hero/react-hooks/usePageTitle';
import { UiComponentProps } from '@hero/ui-kit/types';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import useTheme from '@hero/branding/theme';

import { useScreenSize } from '../../context/screenSize';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import CaretIcon from '@hero/ui-kit/icons/utility/CaretIcon';
import { useNavigate } from 'react-router-dom';
import P from '@hero/ui-kit/typography/P';

type Props = {
    title: string;
    hideBorders?: boolean;
    children?: React.ReactNode;
    backLink?: string;
    backLinkLabel?: string;
} & UiComponentProps;

const Dialog: React.FC<Props> = ({
    title,
    children,
    hideBorders = false,
    backLink,
    backLinkLabel = 'Back',
    ...rest
}) => {
    usePageTitle(`Hero | ${title}`);
    const navigate = useNavigate();

    const screenSize = useScreenSize();

    const { sizing, borders } = useTheme();

    const sectionStyles: CSSProperties = useMemo(() => {
        const styles = !hideBorders
            ? {
                  marginTop: sizing.lineHeights.x2,
                  marginBottom: sizing.lineHeights.x2,
                  ...borders.base,
                  ...borders.shape.veryRounded
              }
            : {
                  marginTop: sizing.lineHeights.x4,
                  marginBottom: sizing.lineHeights.x2
              };
        return screenSize !== 'mobile' ? styles : {};
    }, [sizing, borders, screenSize, hideBorders]);

    return (
        <Section role="wrapper" centeredHorizontally {...rest}>
            {backLink ? (
                <Section
                    width="contained"
                    noDefaultPadding
                    styles={{
                        marginTop: sizing.lineHeights.x2,
                        ...(screenSize === 'mobile' && { paddingLeft: sizing.lineHeights.x1 })
                    }}
                >
                    <ClickableOpacity
                        onClick={() => navigate(backLink)}
                        alt="Back to new activity step"
                    >
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '0.4rem' }}>
                            <CaretIcon direction="backward" height={20} width={20} />
                            <P strong noDefaultMargin>
                                {backLinkLabel}
                            </P>
                        </div>
                    </ClickableOpacity>
                </Section>
            ) : null}
            <Section
                paddingVertical={screenSize === 'mobile' ? 'regular' : 'large'}
                paddingHorizontal="regular"
                subtheme={['regular', 'background']}
                width="contained"
                styles={sectionStyles}
            >
                <>
                    <H role="h4" centered>
                        {title}
                    </H>
                    {children}
                </>
            </Section>
        </Section>
    );
};

export default Dialog;
