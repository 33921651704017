export const hasMedicationName = (values: object): values is { name: string } => 'name' in values;

export const hasMedicationForm = (values: object): values is { form: string } => 'form' in values;

export const hasMedicationType = (values: object): values is { medication_type: string } =>
    'medication_type' in values;

export const hasMedicationStrength = (
    values: object
): values is { strength: string; unit: string } => 'strength' in values;

export const hasMedicationExpirationDate = (values: object): values is { expires: string } =>
    'expires' in values;

export const hasMedicationStoredInHero = (values: object): values is { stored_in_hero: string } =>
    'stored_in_hero' in values;

export const hasMedicationSchedules = (values: object): values is { regimen: string } =>
    'regimen' in values;

export const hasMedicationMaximumDosage = (values: object): values is { max_doses: string } =>
    'max_doses' in values;

export const hasMedicationUnlimited = (values: object): values is { isUnlimited: boolean } =>
    'isUnlimited' in values;

export const hasMedicationPillCount = (values: object): values is { exact_pill_count: string } =>
    'exact_pill_count' in values;

export const hasMedicationPasscode = (values: object): values is { requires_pin: boolean } =>
    'requires_pin' in values;
