import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { Step } from '@hero/ui-kit/inputs/WizardForm';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import H from '@hero/ui-kit/typography/H';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';

import validationSchema from '@hero/validators/forms/addMedicationSchema/stepStoredInHero';
import { setPillOptionsStoredInHero } from '@hero/redux-data/pillOptions/actionCreators';

const StepStoredInHero: React.FC = () => {
    const dispatch = useDispatch();
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const isDbPill = source === 'db';
    const stepNo = isDbPill ? 4 : 3;
    const dependentSteps = isDbPill ? [5] : [4];
    const handleSetStepValues = ({ stored_in_hero }: { stored_in_hero: 'Yes' | 'No' }) => {
        stored_in_hero === 'Yes' && analytics.track('Store in Hero Clicked');
        dispatch(
            setPillOptionsStoredInHero({ stored_in_hero: stored_in_hero === 'Yes' ? true : false })
        );
    };

    return (
        <Step<typeof validationSchema>
            order={stepNo}
            validationSchema={validationSchema}
            onSetStepValues={handleSetStepValues}
            dependentSteps={dependentSteps}
        >
            <H role="h4">Will you store this in Hero?</H>
            <RadioGroup name="stored_in_hero" displayName="Stored in Hero">
                <Radio id="stored_in_hero" labelText="Yes" value="Yes" />
                <Radio id="stored_not_in_hero" labelText="No" value="No" />
            </RadioGroup>
        </Step>
    );
};

export default StepStoredInHero;
