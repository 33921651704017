import { Pill } from '@hero/hero-types';
import { EnteredPillOptions } from '@hero/redux-data/pillOptions/selectors';

type CheckIfAlreadyExists = (
    existingPills: Pill[],
    newPill: Partial<EnteredPillOptions>
) => boolean;
const checkIfAlreadyExists: CheckIfAlreadyExists = (existingPills, newPill) => {
    if (!newPill.name || !newPill.strength) return false;

    const namesList = existingPills.map((pill) => pill.name);
    const newPillName = `${newPill.name} ${newPill.strength}`;

    return namesList.includes(newPillName);
};

export default checkIfAlreadyExists;
