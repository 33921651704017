import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AlertChannel } from '@hero/hero-types';
import {
    isNotificationLoadingSelector,
    isNotificationUpdatedSelector,
    notificationErrorSelector,
    userPhoneNumberSelector,
    notificationPreferencesSelector
} from '@hero/redux-data/notification/selectors';
import { setAlertChannel, fetchAlertPreferences } from '@hero/redux-data/notification/actionCreators';

import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';

import Dialog from '../../../components/Dialog';
import InnerWrapper from '../../../components/Dialog/InnerWrapper';
import ControlsWrapper from '../../../components/Dialog/ControlsWrapper';
import SubmitFailedModal from '../../../views/SubmitFailedModal';

const channelHelper = (data: number) => {
    if (data === 0) return 'You chose to remove notifications';
    else if (data === 1) return 'You chose push notifications';
    else if (data === 2) return 'You chose phone call notifications';
    else if (data === 3) return 'You chose text notifications';
    else return '';
};

const NotificationsMedicationReminder: React.FC = () => {
    const [selectedAlert, setAlert] = useState<number>();
    const navigate = useNavigate();
    const mobile = useSelector(userPhoneNumberSelector, shallowEqual);
    const { warning_after_15_min_channel } = useSelector(
        notificationPreferencesSelector,
        shallowEqual
    );
    const isSmsSet = warning_after_15_min_channel === 3;
    const isCallSet = warning_after_15_min_channel === 2;

    const dispatch = useDispatch();
    const submitFn = useCallback(
        (params: AlertChannel) => {
            setAlert(params);
            mobile && dispatch(setAlertChannel({ warning_after_15_min_channel: params }));
        },
        [dispatch, mobile]
    );

    const isUpdated = useSelector(isNotificationUpdatedSelector, shallowEqual);
    const isLoading = useSelector(isNotificationLoadingSelector, shallowEqual);
    const { error, errorMessage } = useSelector(notificationErrorSelector, shallowEqual);

    useEffect(() => {
        if (!mobile && selectedAlert) {
            navigate(`/settings/set-phone-number/${selectedAlert === 2 ? 'landline' : 'mobile'}`);
        }
        typeof warning_after_15_min_channel !== 'number' && dispatch(fetchAlertPreferences());
    }, [mobile, selectedAlert, navigate, warning_after_15_min_channel, dispatch]);

    if (isLoading) return <Loader />;

    return (
        <Dialog title="Medication Reminder">
            {error && <SubmitFailedModal>{errorMessage}</SubmitFailedModal>}
            {isUpdated && selectedAlert ? (
                <Takeover type="success" title="Notification Are Updated">
                    {channelHelper(selectedAlert)}
                </Takeover>
            ) : null}
            <InnerWrapper size="small">
                <Section centered>
                    <MobileIcon size="large" type="utility" />
                </Section>
                <P size="large" centered>
                    How would you like to receive a reminder if you are more than 15 minutes late?
                </P>
                <ControlsWrapper>
                    <Button onClick={() => submitFn(3)} width="large" disabled={isSmsSet}>
                        {`SMS Text${isSmsSet ? ' (Set)' : ''}`}
                    </Button>
                    <Button onClick={() => submitFn(2)} width="large" disabled={isCallSet}>
                        {`Phone Call${isCallSet ? ' (Set)' : ''}`}
                    </Button>
                    {(isSmsSet || isCallSet) && (
                        <Button onClick={() => submitFn(0)} variant="secondary" width="large">
                            Remove Notifications
                        </Button>
                    )}
                </ControlsWrapper>
            </InnerWrapper>
        </Dialog>
    );
};

export default NotificationsMedicationReminder;
