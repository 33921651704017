import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Step } from '@hero/ui-kit/inputs/WizardForm';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import H from '@hero/ui-kit/typography/H';
import Loader from '@hero/ui-kit/components/Loader';

import {
    enteredPillOptionsSelector,
    formPillOptionsSelector
} from '@hero/redux-data/pillOptions/selectors';
import { fetchPillOptionsForm, setPillOptionsForm } from '@hero/redux-data/pillOptions/actionCreators';
import { isLoadingSelector } from '@hero/redux-data/utils/helperSelectors';

import validationSchema from '@hero/validators/forms/addMedicationSchema/dbStepForm';

const DbStepForm: React.FC<{
    setStepValues: (stepNo: number) => (values: object) => void;
}> = ({ setStepValues }) => {
    const dispatch = useDispatch();
    const { stub, is_generic, name, form } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const formOptions = useSelector(formPillOptionsSelector, shallowEqual);
    const isLoading = useSelector(isLoadingSelector('pillOptions'));

    const handleSetStepValues = (values: { form: string }) => dispatch(setPillOptionsForm(values));

    useEffect(() => {
        name && dispatch(fetchPillOptionsForm({ stub, is_generic, name }));
    }, [stub, is_generic, name, dispatch]);

    useEffect(() => {
        if (!formOptions || form || isLoading) return;
        if (formOptions.length === 1) {
            setStepValues(1)({ form: formOptions[0] });
            dispatch(setPillOptionsForm({ form: formOptions[0] }));
        }
        if (formOptions.length === 0) {
            setStepValues(1)({ form: 'N/A' });
            dispatch(setPillOptionsForm({ form: 'N/A' }));
        }
    }, [formOptions, dispatch, setStepValues, form, isLoading]);

    return (
        <Step<typeof validationSchema>
            order={1}
            validationSchema={validationSchema}
            onSetStepValues={handleSetStepValues}
            dependentSteps={[2]}
        >
            {isLoading || !formOptions ? (
                <Loader />
            ) : (
                <>
                    <H role="h4">Select the dose form.</H>
                    <RadioGroup name="form" displayName="Dose form">
                        {formOptions.map((form) => (
                            <Radio id={form} key={form} value={form} labelText={form} />
                        ))}
                    </RadioGroup>
                </>
            )}
        </Step>
    );
};

export default DbStepForm;
