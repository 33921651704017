import React, { ReactText } from 'react';

import Section from '@hero/ui-kit/layout/Section';
import Eyebrow from '@hero/ui-kit/typography/Eyebrow';
import P from '@hero/ui-kit/typography/P';

type Props = { title: ReactText; children?: ReactText | null };

export const PillProp: React.FC<Props> = ({ title, children }) => (
    <Section padding="small" centered>
        <Eyebrow>{title}</Eyebrow>
        <P noDefaultMargin strong>
            {children || typeof children === 'number' ? children : '-'}
        </P>
    </Section>
);

export default PillProp;
