import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Location, Action } from 'history';

import Takeover from '@hero/ui-kit/components/Takeover';
import CharIcon from '@hero/ui-kit/components/CharIcon';
import P from '@hero/ui-kit/typography/P';
import { usePrompt } from './usePrompt';

type Props = {
    when?: boolean;
};

type PromptProps = {
    when?: boolean;
    message: (nextLocation: Location, action: Action) => boolean | string;
};

const Prompt: React.FC<PromptProps> = ({ when, message }) => {
    usePrompt(message, when);
    return null;
};

const PreventNavigation: React.FC<Props> = ({ when }) => {
    const navigate = useNavigate();
    const [showModal, handleShowModal] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [hasConfirmedNavigation, setHasConfirmedNavigation] = useState(false);

    type HandlePreventNavigation = (nextLocation: Location) => boolean;
    const handlePreventNavigation: HandlePreventNavigation = (nextLocation) => {
        if (!hasConfirmedNavigation) {
            handleShowModal(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };
    const handleNavigationConfirmation = () => {
        handleShowModal(false);
        setHasConfirmedNavigation(true);
    };

    useEffect(() => {
        if (hasConfirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
    }, [hasConfirmedNavigation, lastLocation, navigate]);

    return (
        <>
            <Prompt when={when} message={handlePreventNavigation} />
            {showModal && (
                <Takeover
                    type="warn"
                    title="Cancel Adding Medication"
                    actions={[
                        { label: 'Yes', action: handleNavigationConfirmation },
                        { label: 'No', action: () => handleShowModal(false) }
                    ]}
                >
                    <P size="large">Are you sure you want to cancel adding medication?</P>
                    <CharIcon>!</CharIcon>
                </Takeover>
            )}
        </>
    );
};

export default PreventNavigation;
