import { MembershipDetails } from '@hero/hero-types';

export const getIsPlanActive = (membership_details?: MembershipDetails): boolean => {
    const status = membership_details?.current_plan?.status;
    const isUsedPlan = ['biannual', 'annual', 'basic', 'basic-extended-trial'].includes(
        membership_details?.current_plan?.storefront_key || ''
    );

    if (isUsedPlan && status === 'ACTIVE') {
        return true;
    }
    return false;
};
