import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PrivacySettingsParams } from '@hero/hero-types';
import { setDataOptout, setDataOptoutReset } from '@hero/redux-data/accountDataOptout/actionCreators';
import {
    accountDataOptoutErrorSelector,
    isAccountDataOptoutLoadingSelector,
    isAccountDataOptoutUpdatedSelector
} from '@hero/redux-data/accountDataOptout/selectors';
import { userPrivacySettingsSelector } from '@hero/redux-data/userStatus/selectors';
import privacySettingsSchema from '@hero/validators/forms/privacySettings';

import P from '@hero/ui-kit/typography/P';
import Form from '@hero/ui-kit/inputs/Form';
import CheckboxGroup from '@hero/ui-kit/inputs/CheckboxGroup';
import Checkbox from '@hero/ui-kit/inputs/Checkbox';
import Button from '@hero/ui-kit/inputs/Button';
import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';

import Dialog from '../../../components/Dialog';
import InnerWrapper from '../../../components/Dialog/InnerWrapper';

const SecurityPrivacySettings: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, handleShowModal] = useState(true);
    const isUpdated = useSelector(isAccountDataOptoutUpdatedSelector, shallowEqual);
    const isLoading = useSelector(isAccountDataOptoutLoadingSelector, shallowEqual);
    const { error, errorMessage } = useSelector(accountDataOptoutErrorSelector, shallowEqual);
    const data_opt_out = useSelector(userPrivacySettingsSelector, shallowEqual);
    const defaults = {
        data_opt_out: data_opt_out || false
    };
    const handleSubmit = useCallback(
        (data: PrivacySettingsParams) => {
            dispatch(setDataOptout(data));
        },
        [dispatch]
    );
    const handleOkClick = useCallback(() => {
        handleShowModal(false);
        dispatch(setDataOptoutReset());
        navigate('/settings');
    }, [navigate, dispatch]);

    if (isLoading) return <Loader />;

    return (
        <Dialog title="Privacy Settings">
            {error && (
                <Takeover type="fail" title="An Error Ocurred" actions={[{ label: 'OK' }]}>
                    {errorMessage}
                </Takeover>
            )}
            {isUpdated && (
                <Takeover
                    type="success"
                    title="Settings Updated"
                    externalControls={[showModal, handleOkClick]}
                >
                    It may take 10 days to complete the process.
                </Takeover>
            )}
            <InnerWrapper size="small">
                <P>
                    Hero may send your data to select third parties such as marketing partners and
                    service providers. If you would like to opt-out of a transfer, please turn on
                    the slider below.
                </P>
                <Form
                    validationSchema={privacySettingsSchema}
                    defaultValues={defaults}
                    submitFn={handleSubmit}
                    render={(values) => (
                        <>
                            <CheckboxGroup name="data-sharing" displayName="Data Sharing Opt-Out">
                                <Checkbox labelText="Opt-Out My Account" name="data_opt_out" />
                            </CheckboxGroup>
                            <Button
                                type="submit"
                                width="full"
                                disabled={
                                    values.data_opt_out === undefined ||
                                    values.data_opt_out === data_opt_out
                                }
                            >
                                Update
                            </Button>
                        </>
                    )}
                ></Form>
            </InnerWrapper>
        </Dialog>
    );
};

export default SecurityPrivacySettings;
