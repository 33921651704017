import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import updateAccountSchema, { UpdateAccountSchema } from '@hero/validators/forms/updateAccountSchema';
import {
    updateAccountDetails,
    updateAccountDetailsReset
} from '@hero/redux-data/accountDetailsUpdate/actionCreators';
import {
    accountDetailsSelector,
    areAccountDetailsLoadingSelector,
    areAccountDetailsUpdatedSelector,
    accountDetailsErrorSelector
} from '@hero/redux-data/accountDetailsUpdate/selectors';
import {
    accountEmailUpdateErrorSelector,
    isAccountEmailUpdateLoadingSelector,
    isAccountEmailUpdateUpdatedSelector
} from '@hero/redux-data/accountEmailUpdate/selectors';
import { emailUpdate, emailUpdateReset } from '@hero/redux-data/accountEmailUpdate/actionCreators';

import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import Button from '@hero/ui-kit/inputs/Button';
import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';
import Section from '@hero/ui-kit/layout/Section';
import EmailIcon from '@hero/ui-kit/icons/branding/EmailIcon';

import Dialog from '../../../components/Dialog';

const SecurityUpdateAccount: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accountDetails = useSelector(accountDetailsSelector);
    const areAccountDetailsUpdated = useSelector(areAccountDetailsUpdatedSelector, shallowEqual);
    const areAccountDetailsLoading = useSelector(areAccountDetailsLoadingSelector, shallowEqual);
    const { error: accountDetailsError, errorMessage: accountDetailsErrorMessage } = useSelector(
        accountDetailsErrorSelector,
        shallowEqual
    );
    const isEmailUpdateUpdated = useSelector(isAccountEmailUpdateUpdatedSelector, shallowEqual);
    const isEmailUpdateLoading = useSelector(isAccountEmailUpdateLoadingSelector, shallowEqual);
    const { error: emailUpdateError, errorMessage: emailUpdateErrorMessage } = useSelector(
        accountEmailUpdateErrorSelector,
        shallowEqual
    );
    const handleEmailUpdateSuccessCloseClick = useCallback(() => {
        navigate('/settings');
        dispatch(emailUpdateReset());
    }, [navigate, dispatch]);
    const submitFn = (params: UpdateAccountSchema) => {
        const { email, ...rest } = params;
        if (email && email !== accountDetails.email) {
            dispatch(emailUpdate({ email }));
        }
        if (
            rest.first_name !== accountDetails.first_name ||
            rest.last_name !== accountDetails.last_name ||
            rest.gender !== accountDetails.gender ||
            rest.date_of_birth !== accountDetails.date_of_birth
        ) {
            const newParams = {
                ...rest,
                ...(!rest.first_name && { first_name: accountDetails.first_name }),
                ...(!rest.last_name && { last_name: accountDetails.last_name })
            };
            dispatch(updateAccountDetails(newParams));
        }
    };

    useEffect(() => {
        dispatch(updateAccountDetailsReset());
        dispatch(emailUpdateReset());
        return () => {
            dispatch(updateAccountDetailsReset());
            dispatch(emailUpdateReset());
        };
    }, [dispatch]);

    if (areAccountDetailsLoading || isEmailUpdateLoading) return <Loader />;

    return (
        <Dialog title="Update Account">
            {isEmailUpdateUpdated && (
                <Takeover
                    type="warn"
                    title="Check your Email"
                    icon={EmailIcon}
                    actions={[{ label: 'Close', action: handleEmailUpdateSuccessCloseClick }]}
                >
                    Open the message from Team Hero and click the "Verify Email" link.
                </Takeover>
            )}
            {areAccountDetailsUpdated && (
                <Takeover
                    type="success"
                    title="Account Updated"
                    onClose={() => dispatch(updateAccountDetailsReset())}
                >
                    You successfully updated your account
                </Takeover>
            )}
            {emailUpdateError && (
                <Takeover
                    type="fail"
                    title="Failed updating account email"
                    actions={[{ label: 'Close', action: () => dispatch(emailUpdateReset()) }]}
                >
                    {emailUpdateErrorMessage}
                </Takeover>
            )}
            {accountDetailsError && (
                <Takeover
                    type="fail"
                    title="Failed updating account details"
                    actions={[
                        { label: 'Close', action: () => dispatch(updateAccountDetailsReset()) }
                    ]}
                >
                    {accountDetailsErrorMessage}
                </Takeover>
            )}
            <Section marginLeft="large" marginRight="large">
                <Form
                    defaultValues={{
                        gender: accountDetails.gender || 'wont_say',
                        first_name: accountDetails.first_name,
                        last_name: accountDetails.last_name,
                        email: accountDetails.email,
                        date_of_birth: accountDetails.date_of_birth || ''
                    }}
                    validationSchema={updateAccountSchema}
                    submitFn={submitFn}
                >
                    <Input type="text" name="first_name" displayName="first name" />
                    <Input type="text" name="last_name" displayName="last name" />
                    <Input
                        type="email"
                        placeholder={accountDetails.email}
                        name="email"
                        displayName="email address"
                    />
                    <Select
                        name="gender"
                        displayName="gender"
                        defaultValue={accountDetails.gender || 'wont_say'}
                    >
                        <Option labelText="Male" value="male" />
                        <Option labelText="Female" value="female" />
                        <Option labelText="Other" value="other" />
                        <Option labelText="Prefer not to say" value="wont_say" />
                    </Select>
                    <Input
                        name="date_of_birth"
                        type="date"
                        placeholder="yyyy-mm-dd"
                        displayName="date of birth"
                    />
                    <Button type="submit" width="full">
                        Update
                    </Button>
                </Form>
            </Section>
        </Dialog>
    );
};

export default SecurityUpdateAccount;
