import React, { useCallback, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { EditSchedulesConfig } from '@hero/hero-types';
import { editSchedules, fetchSchedules } from '@hero/redux-data/schedules/actionCreators';
import {
    scheduleSelector,
    scheduleUpdatedSelector,
    isLoadingSchedulesSelector,
    schedulesErrorSelector,
    isAutoAppliedSelector
} from '@hero/redux-data/schedules/selectors';
import editScheduleTimeSchema from '@hero/validators/forms/editScheduleTime';

import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Button from '@hero/ui-kit/inputs/Button';
import Loader from '@hero/ui-kit/components/Loader';

import Dialog from '../../../../components/Dialog';
import InnerWrapper from '../../../../components/Dialog/InnerWrapper';
import SaveChangesModal from '../../../../views/SaveChangesModal';
import Fail from './Fail';

const ScheduleDetails: React.FC = () => {
    const params = useParams();
    const scheduleId = params.id || '';
    const schedule = useSelector(scheduleSelector(scheduleId), shallowEqual);
    const isLoading = useSelector(isLoadingSchedulesSelector);
    const isUpdated = useSelector(scheduleUpdatedSelector);
    const isAutoApplied = useSelector(isAutoAppliedSelector);
    const { error, errorMessage } = useSelector(schedulesErrorSelector, shallowEqual);

    const dispatch = useDispatch();
    const handleSubmit = useCallback(
        (data: { time: string }) => {
            if (!schedule) return;

            const { time, ...rest } = schedule;
            const editSchedulesConfig: EditSchedulesConfig = {
                ...rest,
                old_time: time,
                new_time: data.time
            };

            dispatch(editSchedules(editSchedulesConfig));
        },
        [schedule, dispatch]
    );

    const navigate = useNavigate();
    const handleCloseSuccess = () => navigate('/schedules');

    useEffect(() => {
        !schedule && !isUpdated
            ? dispatch(fetchSchedules())
            : analytics.track('Schedule Time Edit Clicked');
    }, [dispatch, schedule, isUpdated]);

    if (isUpdated)
        return <SaveChangesModal onClose={handleCloseSuccess} isAutoApplied={isAutoApplied} />;
    if (isLoading || !schedule) return <Loader />;
    if (error) return <Fail msg={errorMessage} />;

    return (
        <Dialog title="Change time">
            <InnerWrapper>
                <Form
                    validationSchema={editScheduleTimeSchema}
                    submitFn={handleSubmit}
                    render={(_, formState) => (
                        <>
                            <Input
                                name="time"
                                type="time"
                                placeholder="hh:mm"
                                displayName="Enter a new time"
                            />
                            <Button type="submit" width="full" disabled={!formState.isValid}>
                                Submit
                            </Button>
                        </>
                    )}
                />
            </InnerWrapper>
        </Dialog>
    );
};

export default ScheduleDetails;
