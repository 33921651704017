import React from 'react';

import { CommonProps as NavItemProps } from '@hero/ui-kit/inputs/NavGroup/types';

import useClickProtection from '../../hooks/useClickProtection';
import ProtectiveModals from '../ProtectiveModals';
import { LinkNavItem, HandlerNavItem } from '@hero/ui-kit/inputs/NavGroup';

type Props = NavItemProps & { to?: string; onClick?: () => void };

const ProtectedNavItem: React.FC<Props> = ({ to, onClick, ...rest }) => {
    const {
        isProtected,
        isReadOnly,
        isDisconnected,
        isInVacationMode,
        isInTravelMode,
        showModal,
        handleShowModal
    } = useClickProtection();

    if (!isProtected)
        return to ? (
            <LinkNavItem to={to} {...rest} />
        ) : onClick ? (
            <HandlerNavItem
                onClick={onClick}
                {...rest}
                children={rest.children as React.ReactText}
            />
        ) : null;

    return (
        <>
            <HandlerNavItem
                onClick={handleShowModal}
                {...rest}
                children={rest.children as React.ReactText}
            />
            {showModal && (
                <ProtectiveModals
                    isReadOnly={isReadOnly}
                    isDisconnected={isDisconnected}
                    isInVacationMode={isInVacationMode}
                    isInTravelMode={isInTravelMode}
                />
            )}
        </>
    );
};

export default ProtectedNavItem;
