import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { Step } from '@hero/ui-kit/inputs/WizardForm';
import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import validationSchema from '@hero/validators/forms/addMedicationSchema/stepMaximumDosage';
import getScheduledDoses from '@hero/hero-utils/getScheduledDoses';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import calcSchedulesFromState from '../../../utils/calcSchedulesFromState';
import MaximumDosage from '../../../formSections/MaximumDosage';
import { hasMedicationMaximumDosage } from '../Sidebar/typeGuards';

const StepMaximumDosage: React.FC = () => {
    const { steps } = useStateMachineContext();
    const { source, schedules, pill_type, name } = useSelector(
        enteredPillOptionsSelector,
        shallowEqual
    );
    const scheduledDosesCount = getScheduledDoses(calcSchedulesFromState(schedules));
    const stepNo = source === 'db' ? 7 : 6;
    const thisStep = steps[stepNo];
    const isAsNeeded = !schedules || schedules.length === 0;
    const maximumDosageStepValues = thisStep?.values || {};
    const maximumDosage = hasMedicationMaximumDosage(maximumDosageStepValues)
        ? maximumDosageStepValues.max_doses
        : '0';

    return (
        <Step<typeof validationSchema>
            order={stepNo}
            validationSchema={validationSchema}
            localDefaultValues={{ isUnlimited: false, max_doses: maximumDosage }}
        >
            <MaximumDosage
                scheduledDosesCount={scheduledDosesCount}
                isRx={pill_type === 'Rx'}
                isAsNeeded={isAsNeeded}
                pillName={name}
            />
        </Step>
    );
};

export default StepMaximumDosage;
