import React, { CSSProperties, useState, useEffect, useMemo } from 'react';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import QtyInput from '@hero/ui-kit/inputs/QtyInput';
import Container from '@hero/ui-kit/layout/Container';
import Eyebrow from '@hero/ui-kit/typography/Eyebrow';
import Button from '@hero/ui-kit/inputs/Button';

import MaximumDosageModal from './MaximumDosageModal';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import useTheme from '@hero/branding/theme';

type Props = {
    scheduledDosesCount: number;
    isRx: boolean;
    isAsNeeded: boolean;
    pillName: string;
};
const MaximumDosage: React.FC<Props> = ({ scheduledDosesCount, isRx, isAsNeeded, pillName }) => {
    const [maxCount, setMaxCount] = useState(scheduledDosesCount);
    const [showExplanationModal, setShowExplanationModal] = useState(false);
    const { watch } = useFormContext();
    const isUnlimited: boolean = watch('isUnlimited');
    const secondParagphMargin = useComponentMargin({ marginTop: 'regular' });
    const { colors } = useTheme();
    const buttonLinkStyles: CSSProperties = useMemo(
        () => ({
            lineHeight: 1.3,
            padding: 0,
            marginTop: 0,
            fontWeight: 400,
            borderRadius: 0
        }),
        []
    );

    const max_doses: string = watch('max_doses');
    const doseCountMargin: CSSProperties = { marginTop: '1.8rem' };

    useEffect(() => {
        setMaxCount(scheduledDosesCount + (max_doses ? +max_doses : 0));
    }, [max_doses, scheduledDosesCount]);

    return (
        <>
            {showExplanationModal && (
                <MaximumDosageModal handleClose={() => setShowExplanationModal(false)} />
            )}
            <H role="h4">Maximum pills per day</H>
            <P noDefaultMargin>
                {`This setting allows you to limit the number of ${pillName} pills that can be dispensed
                outside of the scheduled regimen per day.`}
            </P>
            <Button
                onClick={() => setShowExplanationModal(true)}
                variant="secondary"
                styles={buttonLinkStyles}
            >
                Learn more
            </Button>
            <P styles={secondParagphMargin}>
                Note: Make sure to abide dosing instructions as prescribed by the physician and/or
                specified on the label
            </P>
            <Container gridTemplateColumns="5fr 2fr 4fr 2fr 4fr">
                <QtyInput
                    name="max_doses"
                    displayName="Manual dispense"
                    size="tiny"
                    initialValue={max_doses && !isUnlimited ? +max_doses : isAsNeeded ? 1 : 0}
                    disabled={isUnlimited && !isRx}
                    styles={{ marginRight: 'auto' }}
                    maxValue={24}
                />
                {scheduledDosesCount !== 0 && (
                    <>
                        <Eyebrow centered>+</Eyebrow>
                        <div>
                            <Eyebrow centered>Scheduled</Eyebrow>
                            <P centered strong styles={doseCountMargin}>
                                {scheduledDosesCount}
                            </P>
                        </div>
                        <Eyebrow centered>=</Eyebrow>
                        <div>
                            <Eyebrow centered>Maximum</Eyebrow>
                            <P centered strong styles={doseCountMargin}>
                                {isUnlimited ? 'Unlimited' : maxCount}
                            </P>
                        </div>
                    </>
                )}
            </Container>
            {!isUnlimited && maxCount > scheduledDosesCount ? (
                <P styles={{ color: colors.status.fail.beta }}>
                    May exceed recommended or prescribed dosage.
                </P>
            ) : null}
        </>
    );
};

export default MaximumDosage;
