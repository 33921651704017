import React from 'react';

import { MembershipDetails, MembershipPlan, MembershipPlanAction } from '@hero/hero-types';
import P from '@hero/ui-kit/typography/P';

type Props = {
    membershipPlanAction: MembershipPlanAction;
    isLegacyMonthly: boolean;
    isLegacyLimitedFree: boolean;
    isPickedPlanLegacyLimitedFree: boolean;
    pickedPlan: MembershipPlan;
    currentPlan: MembershipDetails['current_plan'];
};

const MembershipPlanDescription: React.FC<Props> = ({
    currentPlan,
    pickedPlan,
    membershipPlanAction,
    isLegacyLimitedFree,
    isLegacyMonthly,
    isPickedPlanLegacyLimitedFree
}) => {
    if (!isLegacyMonthly && !isLegacyLimitedFree) {
        if (membershipPlanAction === 'current')
            return <P strong>Your current membership plan includes:</P>;
        else if (membershipPlanAction === 'upgrade')
            return (
                <P strong>
                    Everything included in {currentPlan.description} plus proactive support:
                </P>
            );
        else if (membershipPlanAction === 'disgrade')
            return (
                <P strong>
                    You will lose all proactive membership support benefits by selecting the{' '}
                    {pickedPlan.description} subscription:
                </P>
            );
    } else if (isLegacyLimitedFree) {
        if (isPickedPlanLegacyLimitedFree)
            return <P strong>You are currently subscribed to the {pickedPlan.description} plan.</P>;
        else if (isLegacyMonthly)
            return <P strong>Everything included in your {pickedPlan.description} plus:</P>;
    } else if (isLegacyMonthly)
        return <P strong>Everything included in your {pickedPlan.description}:</P>;
    else return null;
    return null;
};

export default MembershipPlanDescription;
