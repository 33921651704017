import { Pill, Schedule, NewPillSchedule } from '@hero/hero-types';
import getUniqueArrVals from '@hero/hero-utils/getUniqueArrVals';

import { editPill, EditPill } from '@hero/redux-data/pill/actionCreators';

import calcScheduleFromForm from './calcScheduleFromForm';
import { EditScheduleFormValues } from './getEditScheduleDefaults';

type SubmitSchedule = (
    cb: (data: ReturnType<EditPill>) => void,
    setIsUnique: (isUnique: boolean) => void,
    onSuccess?: () => void
) => (pill: Pill, scheduleId?: string) => (values: EditScheduleFormValues) => void;
const submitSchedule: SubmitSchedule =
    (cb, setIsUnique, onSuccess) => (pill, scheduleId) => (values) => {
        if (!scheduleId) return;

        const isNewSchedule = scheduleId === 'new';

        const { regimen, mon, tue, wed, thu, fri, sat, sun, doses } = values;
        const schedules = calcScheduleFromForm({
            regimen,
            mon,
            tue,
            wed,
            thu,
            fri,
            sat,
            sun,
            doses
        });

        const transformedSchedules: Schedule[] = schedules.map((schedule) => ({
            ...schedule,
            id: isNewSchedule ? Math.random().toString().slice(2) : scheduleId,
            dow: schedule.dow
                .map(([first, ...rest]) => `${first.toUpperCase()}${rest.join('')}`)
                .join(', ')
        }));

        const otherSchedules = (pill.schedules || []).filter(
            (schedule) => schedule.id !== scheduleId
        );
        // all new schedules have the same dow
        const newDow = transformedSchedules.length ? transformedSchedules[0].dow : undefined;
        const uniqueTimes = getUniqueArrVals(
            otherSchedules
                .filter((otherSchedule) => otherSchedule.dow === newDow)
                .flatMap((otherSchedule) => otherSchedule.times)
        );
        const isUniqueSchedule = transformedSchedules.every((schedule) =>
            schedule.times.every((time) => !uniqueTimes.includes(time))
        );

        const preparedSchedules =
            regimen === 'as_needed' ? otherSchedules : [...transformedSchedules, ...otherSchedules];

        const preparedPillSchedules = () => {
            let pillSchedules: NewPillSchedule[] = [];
            preparedSchedules.forEach((schedule) => {
                schedule.times.forEach((time) => {
                    pillSchedules.push({
                        dow: schedule.dow,
                        every_x_days: schedule.every_x_days,
                        next_date: schedule.next_date,
                        time,
                        hero_schedule_id: null,
                        qty: schedule.qty
                    });
                });
            });

            return pillSchedules;
        };

        if (isUniqueSchedule) {
            cb(
                editPill({
                    ...pill,
                    schedules: preparedSchedules,
                    pill_schedules: preparedPillSchedules()
                })
            );
            onSuccess && onSuccess();
        } else {
            setIsUnique(false);
        }
    };

export default submitSchedule;
