import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import P from '@hero/ui-kit/typography/P';
import QtyInput from '@hero/ui-kit/inputs/QtyInput';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import Takeover from '@hero/ui-kit/components/Takeover';

import notificationSchema, {
    NotificationParams
} from '@hero/validators/forms/notificationPreferencesSchema';
import {
    notificationPreferencesSelector,
    isNotificationLoadingSelector,
    isNotificationUpdatedSelector
} from '@hero/redux-data/notification/selectors';
import {
    fetchAlertPreferences,
    setAlertPreferences,
    resetAlertPreferences
} from '@hero/redux-data/notification/actionCreators';
import Loader from '@hero/ui-kit/components/Loader';

import Dialog from '../../../components/Dialog';
import InnerWrapper from '../../../components/Dialog/InnerWrapper';

const NotificationsHeroReminders: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, handleShowModal] = useState(true);
    const isUpdated = useSelector(isNotificationUpdatedSelector, shallowEqual);
    const isLoading = useSelector(isNotificationLoadingSelector, shallowEqual);
    const handleOkClick = useCallback(() => {
        handleShowModal(false);
        navigate('/settings');
    }, [navigate]);

    useEffect(() => {
        dispatch(fetchAlertPreferences());
        return () => {
            dispatch(resetAlertPreferences());
        };
    }, [dispatch]);
    const submitFn = useCallback(
        ({ minutes, alerts }: NotificationParams) =>
            dispatch(setAlertPreferences({ minutes: +minutes, alerts })),
        [dispatch]
    );
    const notificationPreferences = useSelector(notificationPreferencesSelector);
    const { reminder_frequency_minutes, reminder_number_of_alerts } = notificationPreferences;
    const processedReminderFrequencyMinutes = !reminder_frequency_minutes
        ? undefined
        : String(reminder_frequency_minutes);

    if (isLoading) return <Loader />;

    return (
        <Dialog title="Reminders on Hero">
            {isUpdated && (
                <Takeover
                    type="success"
                    title="Save Changes on Hero"
                    externalControls={[showModal, handleOkClick]}
                >
                    Press the center button on Hero when prompted. Please verify your pill regimen
                    for accuracy.
                </Takeover>
            )}
            <InnerWrapper>
                <P centered>Hero will ring and light up when it is time to take your medication.</P>
                <Form
                    validationSchema={notificationSchema}
                    submitFn={submitFn}
                    defaultValues={{
                        minutes: processedReminderFrequencyMinutes
                    }}
                    children={
                        <Section centered>
                            {reminder_frequency_minutes && (
                                <Select
                                    name="minutes"
                                    displayName="How often should it alert you?"
                                    defaultValue={
                                        reminder_frequency_minutes
                                            ? `${reminder_frequency_minutes}`
                                            : undefined
                                    }
                                    styles={{ zIndex: 11 }}
                                >
                                    <Option value="2" labelText="Every 2 minutes" />
                                    <Option value="5" labelText="Every 5 minutes" />
                                    <Option value="10" labelText="Every 10 minutes" />
                                    <Option value="15" labelText="Every 15 minutes" />
                                    <Option value="30" labelText="Every 30 minutes" />
                                    <Option value="60" labelText="Every hour" />
                                </Select>
                            )}
                            {reminder_number_of_alerts && (
                                <QtyInput
                                    initialValue={reminder_number_of_alerts}
                                    minValue={0}
                                    maxValue={12}
                                    size="small"
                                    name="alerts"
                                    displayName="How many extra alerts?"
                                />
                            )}
                            <Button width="large" type="submit">
                                Submit
                            </Button>
                        </Section>
                    }
                />
            </InnerWrapper>
        </Dialog>
    );
};

export default NotificationsHeroReminders;
