import React from 'react';

import Takeover, { TakeoverAction } from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';

type Props = { startOver: (param: boolean) => void };

const AlreadyExists: React.FC<Props> = ({ startOver }) => {
    const actions: [TakeoverAction] = [{ label: 'Choose Another', action: () => startOver(true) }];

    return (
        <Takeover type="fail" title="You already have this medication setup!" actions={actions}>
            <P size="large" inverted>
                You cannot have duplicate pills in your Hero.
            </P>
        </Takeover>
    );
};

export default AlreadyExists;
