import React from 'react';

import { Sidebar } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import StepName from './StepName';
import StepForm from './StepForm';
import StepType from './StepType';
import StepStrength from './StepStrength';
import StepSchedule from './StepSchedule';
import StepExpirationDate from './StepExpirationDate';
import StepStoredInHero from './StepStoredInHero';
import StepPasscodeProtected from './StepPasscodeProtected';
import StepMaximumDosage from './StepMaximumDosage';
import StepPillCount from './StepPillCount';

const AddMedicationSidebar: React.FC = () => {
    const { steps } = useStateMachineContext();
    const hasAnyValues = !!steps[0] && Object.values(steps[0].values).length > 0;

    return hasAnyValues ? (
        <Sidebar>
            <StepName />
            <StepForm />
            <StepType />
            <StepStrength />
            <StepExpirationDate />
            <StepStoredInHero />
            <StepPasscodeProtected />
            <StepSchedule />
            <StepMaximumDosage />
            <StepPillCount />
        </Sidebar>
    ) : null;
};

export default AddMedicationSidebar;
