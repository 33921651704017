import React, { ReactNode, useMemo } from 'react';

import Takeover, { Props as TakeoverProps, TakeoverAction } from '@hero/ui-kit/components/Takeover';

const defaultActions: [TakeoverAction] = [{ label: 'OK' }];

type Props = {
    actions?: [TakeoverAction, TakeoverAction] | [TakeoverAction];
    title?: string;
    children?: ReactNode;
    resetAction?: () => void;
} & Pick<TakeoverProps, 'className' | 'styles' | 'id' | 'onClose' | 'isCancelable'>;

const SubmitFailedModal: React.FC<Props> = ({
    actions = defaultActions,
    title = 'An Error Occurred',
    children,
    isCancelable = false,
    resetAction,
    ...rest
}) => {
    const modalActions: [TakeoverAction] = useMemo(
        () => [{ label: 'OK', action: resetAction }],
        [resetAction]
    );

    return (
        <Takeover
            type="fail"
            actions={modalActions}
            title={title}
            isCancelable={isCancelable}
            {...rest}
        >
            <div>{children ? children : 'Please try again later.'}</div>
        </Takeover>
    );
};

export default SubmitFailedModal;
