import { combineReducers } from 'redux';

import auth from '@hero/redux-data/auth/reducer';
import user from '@hero/redux-data/userLogin/reducer';
import userFlags from '@hero/redux-data/userFlags/reducer';
import alertNotification from '@hero/redux-data/notification/reducer';
import safetySettings from '@hero/redux-data/safetySettings/reducer';
import accountDetails from '@hero/redux-data/accountDetailsUpdate/reducer';
import sendTicket from '@hero/redux-data/accountSupport/reducer';
import pills from '@hero/redux-data/medication/reducer';
import checkOldPassword from '@hero/redux-data/accountPassword/reducer';
import updatePassword from '@hero/redux-data/accountNewPassword/reducer';
import unsubscribe from '@hero/redux-data/unsubscribe/reducer';
import listPlans from '@hero/redux-data/membershipListPlans/reducer';
import membershipChangePlan from '@hero/redux-data/membershipChangePlan/reducer';
import paymentUpdate from '@hero/redux-data/membershipPaymentUpdate/reducer';
import pillOptions from '@hero/redux-data/pillOptions/reducer';
import schedules from '@hero/redux-data/schedules/reducer';
import passcode from '@hero/redux-data/accountPasscode/reducer';
import setPhone from '@hero/redux-data/setPhone/reducer';
import accountDataOptout from '@hero/redux-data/accountDataOptout/reducer';
import accountEmailUpdate from '@hero/redux-data/accountEmailUpdate/reducer';
import accountCaregivers from '@hero/redux-data/accountCaregivers/reducer';
import shortlink from '@hero/redux-data/shortlink/reducer';
import smsAppLink from '@hero/redux-data/smsAppLink/reducer';
import emailVerification from '@hero/redux-data/emailVerification/reducer';
import userActivation from '@hero/redux-data/userActivation/reducer';
import devMode from '@hero/redux-data/devMode';
import planCancellationFee from '@hero/redux-data/planCancellationFee/reducer';

const rootReducer = combineReducers({
    auth,
    user,
    userFlags,
    safetySettings,
    sendTicket,
    alertNotification,
    pills,
    accountDetails,
    checkOldPassword,
    updatePassword,
    unsubscribe,
    listPlans,
    membershipChangePlan,
    paymentUpdate,
    pillOptions,
    schedules,
    passcode,
    setPhone,
    accountDataOptout,
    accountEmailUpdate,
    accountCaregivers,
    shortlink,
    smsAppLink,
    emailVerification,
    userActivation,
    devMode,
    planCancellationFee
});

export default rootReducer;
