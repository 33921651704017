import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import { hasMedicationStrength } from './typeGuards';

const StepStrength: React.FC = () => {
    const { goToStep, steps } = useStateMachineContext();
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const isDbPill = source === 'db';
    const stepNo = isDbPill ? 2 : 1;
    const strengthStepValues = steps[stepNo]?.values || {};
    const strength = hasMedicationStrength(strengthStepValues)
        ? source === 'db'
            ? strengthStepValues.strength
            : `${strengthStepValues.strength} ${strengthStepValues.unit}`
        : '';
    const handleClick = useCallback(() => goToStep(stepNo), [goToStep, stepNo]);

    // covers the fish oil edge case where a db drug has no strength
    return strength !== 'N/A' ? (
        <SidebarButton onClick={handleClick}>{strength || 'Strength N/A'}</SidebarButton>
    ) : null;
};

export default StepStrength;
