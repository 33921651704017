import React from 'react';

import Takeover from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';

type Props = { mobile: string };

const SmsAppLinkSuccess: React.FC<Props> = ({ mobile }) => {
    return (
        <Takeover type="success" title="Download link sent">
            <P
                size="large"
                centered
            >{`We’ve just sent an sms message to ${mobile} Open the message and tap the link to download the Hero app`}</P>
        </Takeover>
    );
};

export default SmsAppLinkSuccess;
