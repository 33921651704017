import React from 'react';

import Takeover from '@hero/ui-kit/components/Takeover';
import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';

const ContinueSetup: React.FC = () => {
    return (
        <Takeover icon={MobileIcon} type="brand" title="Continue Setup with the Hero app">
            Please open the Hero app on your smartphone to continue the setup process
        </Takeover>
    );
};

export default ContinueSetup;
