import React, { CSSProperties, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '@hero/branding/logo';
import useTheme from '@hero/branding/theme';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import MembershipPayment from '.';
import useAuthStatus from '@hero/hero-auth/useAuthStatus';
import constAccessParams from '../../../../consts/envVars';

type LocationState = {
    ok?: boolean;
};

const LockedPayment: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { accessToken } = useAuthStatus(constAccessParams);

    useEffect(() => {
        const locationState = location.state as LocationState;
        !locationState?.ok && navigate(1);
    }, [location, navigate]);

    const { colors } = useTheme();
    const styles: CSSProperties = useMemo(
        () => ({
            position: 'absolute',
            top: '0',
            width: '100%',
            backgroundColor: colors.brand.primary.alpha
        }),
        [colors]
    );
    const logoMargin = useComponentMargin({ marginLeft: 'large', marginTop: 'regular' });
    const logoStyles: CSSProperties = useMemo(
        () => ({
            ...logoMargin,
            position: 'absolute',
            width: 71,
            height: 22,
            left: 0,
            top: 0
        }),
        [logoMargin]
    );

    return accessToken ? (
        <div style={styles}>
            <Logo styles={logoStyles} />
            <MembershipPayment />
        </div>
    ) : null;
};

export default LockedPayment;
