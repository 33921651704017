import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import P from '@hero/ui-kit/typography/P';
import QtyInput from '@hero/ui-kit/inputs/QtyInput';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import Loader from '@hero/ui-kit/components/Loader';
import InputError from '@hero/ui-kit/inputs/InputError';
import { SafetySettingsParams } from '@hero/hero-types';
import safetySettingsSchema from '@hero/validators/forms/safetySettingsSchema';
import {
    getSafetySettings,
    setSafetySettings,
    resetSafetySettings
} from '@hero/redux-data/safetySettings/actionCreators';
import {
    safetySettingsSelector,
    isUpdatedSelector,
    isLoadingSelector,
    errorSelector
} from '@hero/redux-data/safetySettings/selectors';

import Dialog from '../../../components/Dialog';
import SaveChangesModal from '../../../views/SaveChangesModal';
import useSubmitProtection from '../../../hooks/useSubmitProtection';

const SafetySettings: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const count = useSelector(safetySettingsSelector);
    const isUpdated = useSelector(isUpdatedSelector);
    const isLoading = useSelector(isLoadingSelector);
    const errorObject = useSelector(errorSelector);
    const { error, errorMessage } = errorObject;

    const handleOkClick = useCallback(() => {
        navigate('/settings');
    }, [navigate]);
    const submitFn = useCallback(
        (params: SafetySettingsParams) => {
            dispatch(setSafetySettings(params));
        },
        [dispatch]
    );

    const { protectSubmitFn, renderProtectiveModals } = useSubmitProtection();

    useEffect(() => {
        dispatch(getSafetySettings());
        return () => {
            dispatch(resetSafetySettings());
        };
    }, [dispatch]);

    if (isLoading || (!count && !error)) return <Loader />;

    return (
        <Dialog title="Skipped/Missed Dose Dispense Limit">
            {isUpdated && <SaveChangesModal onClose={handleOkClick} />}
            <Form validationSchema={safetySettingsSchema} submitFn={protectSubmitFn(submitFn)}>
                <Section centered>
                    {renderProtectiveModals()}
                    <P>If a user misses a dose only allow them to dispense it within:</P>
                    {count && (
                        <QtyInput
                            initialValue={count}
                            minValue={2}
                            maxValue={24}
                            size="small"
                            name="max_dose_dispense_time"
                            displayName="Set Limit"
                        />
                    )}
                    <P>hours after the scheduled time.</P>
                    <Button width="large" type="submit">
                        Submit
                    </Button>
                    {error && errorMessage && (
                        <InputError id={errorMessage} children={errorMessage} />
                    )}
                </Section>
            </Form>
        </Dialog>
    );
};

export default SafetySettings;
