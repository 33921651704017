import { EnteredPillOptions } from '@hero/redux-data/pillOptions/selectors';
import { addPill } from '@hero/redux-data/pill/actionCreators';
import calcExpirationDate from '@hero/hero-utils/calcExpirationDate';

import calcSchedulesFromState from '../../utils/calcSchedulesFromState';

type PillOptionsFromForm = {
    expires: string;
    stored_in_hero: 'Yes' | 'No';
    requires_pin: boolean;
    isUnlimited: boolean;
    max_doses: string;
    exact_pill_count: string;
};

export type SubmitAddMedication = (
    cb: (action: ReturnType<typeof addPill>) => void
) => (
    pillOptionsFromState: EnteredPillOptions
) => (pillOptionsFromForm: PillOptionsFromForm) => void;

const submitAddMedication: SubmitAddMedication = (cb) => (pillOptionsFromState) => (
    pillOptionsFromForm
) => {
    analytics.track('Add Medication Started');
    cb(
        addPill({
            is_generic: pillOptionsFromState.is_generic,
            name: pillOptionsFromState.name,
            name_short: pillOptionsFromState.name,
            form: pillOptionsFromState.form,
            dosage: pillOptionsFromState.strength,
            pill_type: pillOptionsFromState.pill_type,
            ...(!!pillOptionsFromState.gpi && {
                gpi: pillOptionsFromState.gpi
            }),
            schedules: calcSchedulesFromState(pillOptionsFromState.schedules),
            ...(pillOptionsFromForm.expires && {
                expires: calcExpirationDate(
                    new Date(pillOptionsFromForm.expires)
                        .toISOString()
                        .split('T')[0]
                        .substring(0, 7)
                )
            }),
            stored_in_hero: pillOptionsFromForm.stored_in_hero === 'Yes' ? true : false,
            requires_pin: pillOptionsFromForm.requires_pin,
            ...(!pillOptionsFromForm.isUnlimited && { max_doses: +pillOptionsFromForm.max_doses }),
            ...(+pillOptionsFromForm.exact_pill_count !== 0 && {
                exact_pill_count: +pillOptionsFromForm.exact_pill_count
            })
        })
    );
};

export default submitAddMedication;
