import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import { hasMedicationType } from './typeGuards';

const StepType: React.FC = () => {
    const { goToStep, steps } = useStateMachineContext();
    const typeStepValues = steps[0]?.values || {};
    const type = hasMedicationType(typeStepValues) ? typeStepValues.medication_type : '';
    const handleClick = useCallback(() => goToStep(0), [goToStep]);
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);

    return source === 'user' && type ? (
        <SidebarButton onClick={handleClick}>{type}</SidebarButton>
    ) : null;
};

export default StepType;
