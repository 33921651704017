import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { arePillsLoadingSelector, inHeroPillsSelector } from '@hero/redux-data/medication/selectors';
import { getDayToDayPills } from '@hero/redux-data/medication/actionCreators';
import schema from '@hero/validators/forms/securityPillsLock';

import Form from '@hero/ui-kit/inputs/Form';
import CheckboxGroup from '@hero/ui-kit/inputs/CheckboxGroup';
import Checkbox from '@hero/ui-kit/inputs/Checkbox';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import Loader from '@hero/ui-kit/components/Loader';

import Dialog from '../../../components/Dialog';
import InnerWrapper from '../../../components/Dialog/InnerWrapper';
import P from '@hero/ui-kit/typography/P';

const SecurityPillsLock: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState({
        redirect: false,
        slots: [0]
    });
    const isLoading = useSelector(arePillsLoadingSelector, shallowEqual);
    const inHeroPills = useSelector(inHeroPillsSelector, shallowEqual);

    const submitFn = (data: any) => {
        // filter only picked pills
        const pickedSlots = Object.keys(data).filter((item) => data[item]);
        // making number[] from string[]
        const finalSlots = Array.from(pickedSlots, (x: string) => parseInt(x));
        setRedirect({
            redirect: true,
            slots: finalSlots
        });
    };

    useEffect(() => {
        dispatch(getDayToDayPills());
    }, [dispatch]);

    useEffect(() => {
        if (redirect.redirect) {
            const state = redirect.slots.length
                ? { slots: redirect.slots }
                : { updatePasscode: true };
            navigate('/settings/security/passcode', { state });
        }
    }, [redirect, navigate]);

    if (isLoading) return <Loader />;

    return (
        <Dialog title="Click the arrows bellow to create a four-digit code">
            <InnerWrapper>
                <Section centered>
                    {inHeroPills.length > 0 ? (
                        <Form
                            submitFn={submitFn}
                            validationSchema={schema}
                            render={(_, formState) => (
                                <>
                                    <CheckboxGroup
                                        name="pills"
                                        displayName="Select medication to protect with a passcode"
                                    >
                                        {inHeroPills.map((item, key) => (
                                            <Checkbox
                                                key={key}
                                                name={item.slot.toString()}
                                                labelText={item.name}
                                            />
                                        ))}
                                    </CheckboxGroup>
                                    <Button
                                        width="full"
                                        type="submit"
                                        disabled={!formState.isValid}
                                    >
                                        Next
                                    </Button>
                                </>
                            )}
                        />
                    ) : (
                        <P size="large">No pills to protect with a passcode.</P>
                    )}
                </Section>
            </InnerWrapper>
        </Dialog>
    );
};

export default SecurityPillsLock;
