import React from 'react';

import Takeover from '@hero/ui-kit/components/Takeover';
import DeviceIcon from '@hero/ui-kit/icons/branding/DeviceIcon';

const DeviceDisconnected: React.FC = () => {
    return (
        <Takeover
            actions={[{ label: 'Close' }]}
            icon={DeviceIcon}
            type="bold"
            title="Device Disconnected"
        >
            Please check that it is plugged in and connected to WIFI.
        </Takeover>
    );
};

export default DeviceDisconnected;
