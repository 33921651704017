import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import getQueryParams from '@hero/hero-utils/getQueryParams';

import { expandShortlink, resetShortlink } from '@hero/redux-data/shortlink/actionCreators';
import {
    isShortlinkLoadingSelector,
    shortlinkErrorSelector,
    shortlinkSelector
} from '@hero/redux-data/shortlink/selectors';

import SplashView from '@hero/ui-kit/components/SplashView';
import Loader from '@hero/ui-kit/components/Loader';

import NotFound from '../NotFound';
import getRedirectPath from './getRedirectPath';

const Shortlink: React.FC = () => {
    const { shortlink } = useParams<{ shortlink: string }>();
    const { tokenExtracted } = getQueryParams<{ tokenExtracted?: string }>();
    const isExtracted = tokenExtracted === 'true';

    const dispatch = useDispatch();
    const expandedLink = useSelector(shortlinkSelector, shallowEqual);
    const isLoading = useSelector(isShortlinkLoadingSelector, shallowEqual);
    const { error } = useSelector(shortlinkErrorSelector, shallowEqual);
    const extractedLink = `${window.location}`.replace(/link\//, '');
    const redirectPath = getRedirectPath(isExtracted ? extractedLink : expandedLink);

    useEffect(() => {
        shortlink && !isExtracted && dispatch(expandShortlink(shortlink));
        return () => {
            dispatch(resetShortlink());
        };
    }, [shortlink, dispatch, isExtracted]);

    if (isLoading) return <Loader />;

    if (!shortlink || error) return <NotFound />;

    if (redirectPath) {
        window.location.replace(redirectPath);
    }

    return <SplashView type="brand" />;
};

export default Shortlink;
