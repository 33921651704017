import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Step } from '@hero/ui-kit/inputs/WizardForm';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import H from '@hero/ui-kit/typography/H';
import Loader from '@hero/ui-kit/components/Loader';

import {
    enteredPillOptionsSelector,
    namePillOptionsSelector
} from '@hero/redux-data/pillOptions/selectors';
import { fetchPillOptionsName, setPillOptionsName } from '@hero/redux-data/pillOptions/actionCreators';
import { isLoadingSelector } from '@hero/redux-data/utils/helperSelectors';

import validationSchema from '@hero/validators/forms/addMedicationSchema/dbStepName';
import calcNameOptions from '../../../utils/calcNameOptions';

const DbStepName: React.FC<{
    setStepValues: (stepNo: number) => (values: object) => void;
}> = ({ setStepValues }) => {
    const dispatch = useDispatch();
    const { stub, is_generic, name } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const nameOptions = useSelector(namePillOptionsSelector, shallowEqual);
    const isLoading = useSelector(isLoadingSelector('pillOptions'));

    const pillNames =
        nameOptions.generic.length || nameOptions.branded.length
            ? [...nameOptions.generic.map((name) => `Generic (${name})`), ...nameOptions.branded]
            : null;

    const handleSetStepValues = ({ name }: { name: string }) => {
        analytics.track('Medication Name Selected', { value: name });
        dispatch(setPillOptionsName(calcNameOptions(name)));
    };

    useEffect(() => {
        stub && !name && dispatch(fetchPillOptionsName({ stub, is_generic }));
    }, [stub, name, is_generic, dispatch]);

    useEffect(() => {
        if (!pillNames || name || isLoading) return;
        if (pillNames.length === 1) {
            setStepValues(0)({ name: calcNameOptions(pillNames[0]).name });
            dispatch(setPillOptionsName(calcNameOptions(pillNames[0])));
        }
        if (pillNames.length === 0) {
            setStepValues(0)({ name: stub });
            dispatch(setPillOptionsName({ name: stub, is_generic: true }));
        }
    });

    return (
        <Step<typeof validationSchema>
            order={0}
            validationSchema={validationSchema}
            onSetStepValues={handleSetStepValues}
            dependentSteps={[1, 2]}
        >
            {isLoading || !pillNames ? (
                <Loader />
            ) : (
                <div>
                    <H role="h4">Which one do you take?</H>
                    <RadioGroup name="name" displayName="Medication name" initiallyShow={6}>
                        {pillNames.map((name) => (
                            <Radio id={name} key={name} value={name} labelText={name} />
                        ))}
                    </RadioGroup>
                </div>
            )}
        </Step>
    );
};

export default DbStepName;
