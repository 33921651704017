import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import { hasMedicationExpirationDate } from './typeGuards';

const StepExpirationDate: React.FC = () => {
    const { goToStep, steps, currentStep } = useStateMachineContext();
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const stepNo = source === 'db' ? 3 : 2;
    const expirationDateStepValues = steps[stepNo]?.values || {};
    const expirationDate = hasMedicationExpirationDate(expirationDateStepValues)
        ? expirationDateStepValues.expires
        : '';
    const handleClick = useCallback(() => goToStep(stepNo), [goToStep, stepNo]);

    return expirationDate || currentStep > stepNo ? (
        <SidebarButton onClick={handleClick}>{`Expiration ${
            expirationDate
                ? new Date(expirationDate).toLocaleDateString('en-US', {
                      month: 'numeric',
                      year: 'numeric'
                  })
                : 'N/A'
        }`}</SidebarButton>
    ) : null;
};

export default StepExpirationDate;
