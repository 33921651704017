import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
    isPaymentMethodUpdateUpdatedSelector,
    paymentUpdateErrorSelector
} from '@hero/redux-data/membershipPaymentUpdate/selectors';
import { paymentUpdateReset } from '@hero/redux-data/membershipPaymentUpdate/actionCreators';
import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import {
    checkIfUserMembershipLocked,
    checkIfLegacyUserLock,
    checkIfLegacyLimitedFree
} from '@hero/hero-utils/membership';

import P from '@hero/ui-kit/typography/P';

import Dialog from '../../../../components/Dialog';
import InnerWrapper from '../../../../components/Dialog/InnerWrapper';
import MembershipLockedLegacy from '../../../../takeovers/MembershipLockedLegacy';
import MembershipLocked from '../../../../takeovers/MembershipLocked';

import StripeWrapper from './StripeWrapper';
import StripeForm from './StripeForm';
import Takeover from '@hero/ui-kit/components/Takeover';

const MembershipPayment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModal, handleShowModal] = useState(true);
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const isMembershipLocked =
        !!membershipDetails && checkIfUserMembershipLocked(membershipDetails);
    const isLegacyMembershipLocked =
        !!membershipDetails && checkIfLegacyUserLock(membershipDetails);
    const isLegacyLimitedFree = !!membershipDetails && checkIfLegacyLimitedFree(membershipDetails);
    const isUpdated = useSelector(isPaymentMethodUpdateUpdatedSelector, shallowEqual);
    const { error, errorMessage } = useSelector(paymentUpdateErrorSelector, shallowEqual);

    const handleOkClick = useCallback(() => {
        handleShowModal(false);
        dispatch(paymentUpdateReset());
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('hero_payment_success');
        } else {
            navigate('/settings', { state: { ok: true } });
        }
    }, [navigate, dispatch]);

    return (
        <StripeWrapper>
            <Dialog title="Update Payment Method">
                <InnerWrapper size="small">
                    <StripeForm isUpdated={!!isUpdated} />
                </InnerWrapper>
            </Dialog>
            {isUpdated &&
                (!isLegacyMembershipLocked || isLegacyLimitedFree) &&
                (!isMembershipLocked || isLegacyLimitedFree) && (
                    <Takeover
                        type="success"
                        title="Thank You!"
                        externalControls={[showModal, handleOkClick]}
                    >
                        {isLegacyMembershipLocked || isMembershipLocked ? (
                            <P size="large" centered>
                                Your credit card has been charged for the membership
                            </P>
                        ) : (
                            <P size="large" centered>
                                Your payment method has been updated.
                            </P>
                        )}
                    </Takeover>
                )}
            {isUpdated && isLegacyMembershipLocked && !isLegacyLimitedFree && (
                <MembershipLockedLegacy />
            )}
            {isUpdated && isMembershipLocked && !isLegacyLimitedFree && <MembershipLocked />}
            {error && (
                <Takeover
                    type="fail"
                    title="Payment Update Error"
                    actions={[{ label: 'OK', action: () => dispatch(paymentUpdateReset()) }]}
                >
                    <P size="large" inverted centered>
                        {errorMessage}
                    </P>
                </Takeover>
            )}
        </StripeWrapper>
    );
};

export default MembershipPayment;
