import React, { useCallback } from 'react';

import Takeover from '@hero/ui-kit/components/Takeover';

const UnsubscribeSuccessModal: React.FC = () => {
    const handleClose = useCallback(() => {
        window.location.replace('https://herohealth.com');
    }, []);

    return (
        <Takeover type="success" title="You are now unsubscribed" onClose={handleClose}></Takeover>
    );
};

export default UnsubscribeSuccessModal;
