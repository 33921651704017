import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getDayToDayPills } from '@hero/redux-data/medication/actionCreators';
import {
    pillsSelector,
    arePillsLoadingSelector,
    pillsErrorSelector
} from '@hero/redux-data/medication/selectors';
import { isFreSelector } from '@hero/redux-data/userStatus/selectors';
import { NavGroup, LinkNavItem } from '@hero/ui-kit/inputs/NavGroup';
import Loader from '@hero/ui-kit/components/Loader';
import checkPillStatus from '@hero/hero-utils/checkPillStatus';
import { getMedicationCountDisplay } from '@hero/hero-utils/schedules';

import ListView from '../../components/ListView';
import ProtectedButton from '../../components/ProtectedButton';
import usePillStatuses from '../../hooks/usePillStatuses';
import useAuthStatus from '@hero/hero-auth/useAuthStatus';
import constAccessParams from '../../consts/envVars';
import { PillStatus } from '@hero/hero-types';

const Menu: React.FC = () => {
    const dispatch = useDispatch();
    const { accessToken } = useAuthStatus(constAccessParams);
    const pills = useSelector(pillsSelector);
    const isLoading = useSelector(arePillsLoadingSelector, shallowEqual);
    const { error } = useSelector(pillsErrorSelector, shallowEqual);
    const title = useMemo(() => getMedicationCountDisplay(pills.length), [pills]);
    const isFre = useSelector(isFreSelector);

    useEffect(() => {
        // 403 is returned when FRE not completed
        if (isFre === false) {
            analytics.page('My Medicine Viewed');
            dispatch(getDayToDayPills());
        }
    }, [dispatch, isFre]);

    const pillStatuses = usePillStatuses();

    if (isLoading || isFre === null || !accessToken || (!pills && !error)) return <Loader />;
    return (
        <ListView
            title={title}
            actionTrigger={
                <ProtectedButton variant="secondary" to="/medication/add">
                    Add Medication
                </ProtectedButton>
            }
        >
            <NavGroup id="medications-menu">
                {pills
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((pill) => {
                        const pillStatus = checkPillStatus(pill);
                        const description = pillStatus?.length
                            ? pillStatus
                                  .map((status) => pillStatuses[status as PillStatus].name)
                                  .toString()
                            : undefined;
                        const pillName = !pill.stored_in_hero
                            ? `${pill.name} (Stored outside Hero)`
                            : pill.name;

                        return (
                            <LinkNavItem
                                description={description}
                                isWarning={!!pillStatus}
                                key={pill.slot}
                                to={`/medication/${pill.slot}`}
                            >
                                {pillName}
                            </LinkNavItem>
                        );
                    })}
            </NavGroup>
        </ListView>
    );
};

export default Menu;
