import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Pill } from '@hero/hero-types';
import usePageTitle from '@hero/react-hooks/usePageTitle';

import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import H from '@hero/ui-kit/typography/H';
import Section from '@hero/ui-kit/layout/Section';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';
import Loader from '@hero/ui-kit/components/Loader';
import Container from '@hero/ui-kit/layout/Container';

import {
    pillSelector,
    arePillsLoadingSelector,
    arePillsUpdatedSelector,
    pillsErrorSelector,
    passcodeSelector
} from '@hero/redux-data/medication/selectors';
import { resetPillOptions } from '@hero/redux-data/pillOptions/actionCreators';
import { softResetPills, getDayToDayPills } from '@hero/redux-data/medication/actionCreators';
import { removePill } from '@hero/redux-data/pill/actionCreators';
import { isAutoAppliedSelector } from '@hero/redux-data/pills/selectors';

import PillCount from '../../formSections/PillCount';
import SaveChangesModal from '../../../../views/SaveChangesModal';
import SubmitFailedModal from '../../../../views/SubmitFailedModal';

import validationSchema from './validationSchema';
import getEditFormDefaults from './getEditFormDefaults';
import submitPill from './submitPill';
import ConfirmRemoveMedication from '../ConfirmRemoveMedication';
import Passcode from '../../formSections/Passcode';
import EditSchedules from './EditSchedules';
import EditMaximumDosage from './EditMaximumDosage';

const AddMedication: React.FC = () => {
    const { id } = useParams();
    usePageTitle('Hero | Add New Medication');
    const dispatch = useDispatch();
    const [hasRemoveMedicationStarted, handleHasRemoveMedicationStarted] = useState(false);
    const [showRemoveModal, handleShowRemoveModal] = useState(false);
    const pill = useSelector(pillSelector(id || ''), shallowEqual);
    // necessary since pills can be removed here as well
    const pillStub = {} as Pill;
    const isLoading = useSelector(arePillsLoadingSelector);
    const isUpdated = useSelector(arePillsUpdatedSelector);
    const isAutoApplied = useSelector(isAutoAppliedSelector);
    const { error, errorMessage } = useSelector(pillsErrorSelector, shallowEqual);
    const passcode = useSelector(passcodeSelector, shallowEqual);

    const defaultValues = getEditFormDefaults(pill || pillStub);

    const handleSubmit = submitPill(dispatch)(pill || pillStub);

    const handleRemove = useCallback(() => {
        handleHasRemoveMedicationStarted(true);
        analytics.track('Remove Medication Started');
        pill && dispatch(removePill(pill.slot));
    }, [dispatch, pill]);
    const handleSoftReset = useCallback(() => dispatch(softResetPills()), [dispatch]);
    const handleSuccess = () => {
        dispatch(softResetPills());
        dispatch(getDayToDayPills());
        const handleRemoveMedicationSuccess = () => {
            analytics.track('Update Medication Succeeded');
            handleHasRemoveMedicationStarted(false);
        };
        pill?.slot
            ? handleRemoveMedicationSuccess()
            : analytics.track('Remove Medication Succeeded');
    };
    const schedules = (pill || pillStub).schedules || [];

    const nameMargin = useComponentMargin({ marginBottom: 'large' });

    useEffect(() => {
        !pill && !isUpdated && dispatch(getDayToDayPills());
        return () => {
            dispatch(resetPillOptions());
        };
    }, [dispatch, pill, isUpdated]);

    useEffect(() => {
        showRemoveModal && analytics.page('Remove Medication');
    }, [showRemoveModal]);

    useEffect(() => {
        const handleRemoveMedicationError = () => {
            analytics.track('Remove Medication Error');
            handleHasRemoveMedicationStarted(false);
        };
        if (error) {
            hasRemoveMedicationStarted
                ? handleRemoveMedicationError()
                : analytics.track('Update Medication Error');
        }
    }, [error, hasRemoveMedicationStarted]);

    if (isUpdated)
        return <SaveChangesModal onClose={handleSuccess} isAutoApplied={isAutoApplied} />;
    if (isLoading || !pill) return <Loader />;
    if (error)
        return (
            <SubmitFailedModal title="Editing Medication Failed" resetAction={handleSoftReset}>
                {errorMessage}
            </SubmitFailedModal>
        );

    return (
        <Section subtheme={['regular', 'background']} role="wrapper" centeredHorizontally>
            {showRemoveModal && (
                <ConfirmRemoveMedication
                    handleRemove={handleRemove}
                    pillName={pill.name}
                    externalControls={[showRemoveModal, handleShowRemoveModal]}
                />
            )}
            <Section marginVertical="large" paddingTop="regular">
                <Form
                    // multiple schedule branches make this hard to type
                    // @ts-ignore
                    submitFn={handleSubmit}
                    validationSchema={validationSchema}
                    defaultValues={defaultValues}
                    render={(_, formState) => (
                        <>
                            <Section width="contained">
                                <H role="h3" styles={nameMargin}>
                                    {pill.name}
                                </H>
                                <EditSchedules pill={pill || pillStub} />
                            </Section>
                            {passcode && (
                                <Section width="contained">
                                    <Passcode />
                                </Section>
                            )}
                            <Section width="contained">
                                <EditMaximumDosage
                                    schedules={schedules}
                                    isRx={pill.pill_type === 'Rx'}
                                    pillName={pill.name}
                                />
                            </Section>
                            <Section width="contained">
                                <PillCount />
                            </Section>
                            <Section width="contained">
                                <Container>
                                    <Button type="submit" disabled={!formState.isValid}>
                                        Update
                                    </Button>
                                    <LinkButton variant="secondary" to={`/medication/${pill.slot}`}>
                                        Cancel
                                    </LinkButton>
                                </Container>
                            </Section>
                        </>
                    )}
                ></Form>
            </Section>
        </Section>
    );
};

export default AddMedication;
