import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    membershipDetailsSelector,
    userEmailSelector
} from '@hero/redux-data/userStatus/selectors';
import {
    checkIfLegacyLimitedFree,
    checkIfLegacyMonthly,
    checkIfPickedPlanLegacyLimitedFree,
    getMembershipPlanAction,
    getPerPlanLabel,
    USED_PLANS
} from '@hero/hero-utils/membership';
import { isUpdatedSelector, isLoadingSelector } from '@hero/redux-data/accountSupport/selectors';
import { sendTicket, sendTicketReset } from '@hero/redux-data/accountSupport/actionCreators';
import { isMembershipPlanChangeLoadingSelector } from '@hero/redux-data/membershipChangePlan/selectors';
import { membershipChangePlanReset } from '@hero/redux-data/membershipChangePlan/actionCreators';
import { areCaregiversLoadingSelector } from '@hero/redux-data/accountCaregivers/selectors';
import { getCaregivers, resetCaregivers } from '@hero/redux-data/accountCaregivers/actionCreators';

import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';

import Dialog from '../../../../components/Dialog';
import InnerWrapper from '../../../../components/Dialog/InnerWrapper';
import MembershipConfirmCancellation from '../../../../takeovers/MembershipConfirmCancellation';

import MembershipPlanDescription from './MembershipPlanDescription';
import MembershipPlanBenefits from './MembershipPlanBenefits';
import MembershipPlanSelected from './MembershipPlanSelected';
import { MembershipPlan } from '@hero/hero-types';

const MembershipPlanDetails: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showConfirmCancellation, handleShowConfirmCancellation] = useState(false);
    const [showUpdatedModal, handleShowUpdatedModal] = useState(true);
    const userEmail = useSelector(userEmailSelector, shallowEqual);
    const isLoading = useSelector(isLoadingSelector, shallowEqual);
    const isUpdated = useSelector(isUpdatedSelector, shallowEqual);
    const isMembershipPlanChangeLoading = useSelector(
        isMembershipPlanChangeLoadingSelector,
        shallowEqual
    );

    const handleUpdatedOkClick = useCallback(() => {
        handleShowUpdatedModal(false);
        dispatch(sendTicketReset());
        dispatch(membershipChangePlanReset());
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('hero_payment_success');
        } else {
            navigate('/settings');
        }
    }, [dispatch, navigate]);
    const handleCancelMembershipClick = useCallback(() => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('cancel_membership');
        } else {
            handleShowConfirmCancellation(true);
        }
    }, []);
    const handleCancelClick = useCallback(() => {
        handleShowConfirmCancellation(false);
        dispatch(
            sendTicket({
                message: 'Cancel Membership',
                subject: `User ${userEmail} address is requesting to Cancel Membership.`
            })
        );
    }, [dispatch, userEmail]);

    useEffect(() => {
        dispatch(getCaregivers());

        return () => {
            dispatch(resetCaregivers());
        };
    }, [dispatch]);

    const areCaregiversLoading = useSelector(areCaregiversLoadingSelector, shallowEqual);
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const pickedPlan = membershipDetails?.current_plan as MembershipPlan;

    if (
        isLoading ||
        isMembershipPlanChangeLoading ||
        areCaregiversLoading ||
        !pickedPlan ||
        !membershipDetails
    )
        return <Loader />;

    const { current_plan } = membershipDetails;
    const membershipPlanAction = getMembershipPlanAction(current_plan.key, pickedPlan.key);
    const isLegacyLimitedFree = checkIfLegacyLimitedFree(membershipDetails);
    const isLegacyMonthly = checkIfLegacyMonthly(membershipDetails);
    const isPickedPlanLegacyLimitedFree = checkIfPickedPlanLegacyLimitedFree(pickedPlan);

    const title = () => {
        if (pickedPlan?.key?.length > 0 && USED_PLANS.includes(pickedPlan.key)) {
            return `${getPerPlanLabel(pickedPlan?.storefront_key)} subscription`;
        }
        return pickedPlan.name;
    };

    return (
        <Dialog title={title()}>
            <InnerWrapper size="small">
                {!isLegacyLimitedFree && (
                    <P centered>
                        ${pickedPlan.monthly_fee / 100}
                        {` / ${getPerPlanLabel(pickedPlan?.storefront_key)}`}
                    </P>
                )}
                <MembershipPlanDescription
                    membershipPlanAction={membershipPlanAction}
                    isLegacyLimitedFree={isLegacyLimitedFree}
                    isLegacyMonthly={isLegacyMonthly}
                    isPickedPlanLegacyLimitedFree={isPickedPlanLegacyLimitedFree}
                    pickedPlan={pickedPlan}
                    currentPlan={current_plan}
                />
                <MembershipPlanBenefits
                    membershipPlanAction={membershipPlanAction}
                    pickedPlan={pickedPlan}
                    currentPlan={current_plan}
                    isLegacyLimitedFree={isLegacyLimitedFree}
                    isLegacyMonthly={isLegacyMonthly}
                />
                <MembershipPlanSelected
                    pickedPlan={pickedPlan}
                    showUpdatedModal={showUpdatedModal}
                    handleUpdatedOkClick={handleUpdatedOkClick}
                />

                <Button width="full" onClick={handleCancelMembershipClick}>
                    Cancel Membership
                </Button>
            </InnerWrapper>
            {showConfirmCancellation && (
                <MembershipConfirmCancellation
                    actions={[
                        { label: 'No', action: () => handleShowConfirmCancellation(false) },
                        { label: 'Yes - Cancel Membership', action: handleCancelClick }
                    ]}
                />
            )}
            {isUpdated && (
                <Takeover
                    title="Processing Request"
                    type="success"
                    externalControls={[showUpdatedModal, handleUpdatedOkClick]}
                >
                    Your request has been received, we will contact you within 24 hours. If you have
                    any questions, please give us a call at 855-855-9962.
                </Takeover>
            )}
        </Dialog>
    );
};

export default MembershipPlanDetails;
