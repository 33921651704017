import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Loader from '@hero/ui-kit/components/Loader';
import { userIdSelector } from '@hero/redux-data/userStatus/selectors';
import { loginDone } from '@hero/redux-data/auth/actionCreators';
import persistAuthStatus from '@hero/hero-auth/persistAuthStatus';

import Membership from '../sections/settings/subsections/Membership';
import MembershipPayment from '../sections/settings/subsections/MembershipPayment';
import MembershipPlans from '../sections/settings/subsections/Membership/MembershipPlans';
import MembershipPlanDetails from '../sections/settings/subsections/Membership/MembershipPlanDetails';
import { getIsPlanActive } from '../sections/settings/subsections/Membership/utils';
import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import MembershipActivePlans from '../sections/settings/subsections/Membership/activePlans/MembershipActivePlans';
import MembershipActivePlanDetails from '../sections/settings/subsections/Membership/activePlans/MembershipActivePlanDetails';
import { accessTokenSelector } from '@hero/redux-data/auth/selectors';
import { getUserStatus } from '@hero/redux-data/userLogin/actionCreators';
import { isLoadingSelector } from '@hero/redux-data/utils/helperSelectors';
import NotFound from '../views/NotFound';

const MembershipMobileViewWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const accessToken = useSelector(accessTokenSelector);
    const isUserStatusReady = useSelector(userIdSelector);
    const { pathname } = useLocation();
    const membership = useSelector(membershipDetailsSelector, shallowEqual);
    const isPlanActive = getIsPlanActive(membership || undefined);
    const isLoading = useSelector(isLoadingSelector('user'));
    const navigate = useNavigate();

    useEffect(() => {
        if (accessToken && isUserStatusReady) {
            dispatch(getUserStatus());
        }
    }, [accessToken, isUserStatusReady, dispatch]);

    useEffect(() => {
        if (!accessToken) {
            if (window?.userObj) {
                const preselectedAccount =
                    window.userObj.account_id || window.userObj.status?.account_id;

                const { accessToken, access_token, ...rest } = window.userObj;
                dispatch(
                    loginDone({
                        access_token: access_token || accessToken,
                        ...rest,
                        preselectedAccount
                    })
                );
                persistAuthStatus('loggedIn');
            }
        } else {
            // check and possibly refresh at
        }
    }, [dispatch, accessToken]);

    useEffect(() => {
        // following redirects are for backward compatibility with older mobile app versions
        if (pathname === '/account/membership') {
            navigate('/settings/membership');
        }
        if (pathname === '/account/membership/payment') {
            navigate('/settings/membership/payment');
        }
        if (pathname === '/account/membership/plans') {
            navigate('/settings/membership/plans');
        }
        if (pathname === '/account/membership/plans/:key') {
            navigate('/settings/membership/plans/:key');
        }
    }, [pathname, navigate]);

    return accessToken && isUserStatusReady && !isLoading ? (
        <Routes>
            <Route path="/settings/membership" element={<Membership />} />
            <Route path="/settings/membership/payment" element={<MembershipPayment />} />
            <Route
                path="/settings/membership/plans"
                element={isPlanActive ? <MembershipActivePlans /> : <MembershipPlans />}
            />
            <Route
                path="/settings/membership/plans/:key"
                element={isPlanActive ? <MembershipActivePlanDetails /> : <MembershipPlanDetails />}
            />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    ) : (
        <Loader />
    );
};

export default MembershipMobileViewWrapper;
