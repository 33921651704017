import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import { hasMedicationName } from './typeGuards';

const StepName: React.FC = () => {
    const { goToStep, steps } = useStateMachineContext();
    const nameStepValues = steps[0]?.values || {};
    const name = hasMedicationName(nameStepValues) ? nameStepValues.name : '';
    const handleClick = useCallback(() => goToStep(0), [goToStep]);
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);

    return source === 'db' && name ? (
        <SidebarButton onClick={handleClick}>{name}</SidebarButton>
    ) : null;
};

export default StepName;
