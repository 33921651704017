import React, { useEffect } from 'react';

import { NavGroup, LinkNavItem, InfoNavItem } from '@hero/ui-kit/inputs/NavGroup';

import ListView from '../../../components/ListView';
import conf from '../../../../package.json';

const About: React.FC = () => {
    useEffect(() => {
        analytics.page('About Viewed');
    }, []);

    return (
        <ListView title="About">
            <NavGroup id="about-menu">
                <InfoNavItem infoText={conf.version}>App version</InfoNavItem>
                <LinkNavItem to="https://herohealth.com/terms">Terms and Conditions</LinkNavItem>
                <LinkNavItem to="https://herohealth.com/privacy">Privacy Policy</LinkNavItem>
            </NavGroup>
        </ListView>
    );
};

export default About;
