import React, { CSSProperties } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import H from '@hero/ui-kit/typography/H';
import Form from '@hero/ui-kit/inputs/Form';
import Combobox from '@hero/ui-kit/inputs/Combobox';
import Button from '@hero/ui-kit/inputs/Button';

import {
    stubPillOptionsSelector,
    arePillOptionsLoadingSelector,
    arePillOptionsUpdatedSelector,
    enteredPillOptionsSelector
} from '@hero/redux-data/pillOptions/selectors';
import {
    fetchPillOptionsStub,
    setPillOptionsStub,
    setPillOptionsSource,
    setPillOptionsName,
    resetPillOptions,
    setPillOptionsSearchString
} from '@hero/redux-data/pillOptions/actionCreators';

import validationSchema from '@hero/validators/forms/enterMedicationName';
import usePrevious from '@hero/react-hooks/usePrevious';

const StepStub: React.FC = () => {
    const dispatch = useDispatch();
    const suggestions = useSelector(stubPillOptionsSelector, shallowEqual);
    const { search_string } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const previous_search_string = usePrevious(search_string);
    const isUpdated = useSelector(arePillOptionsUpdatedSelector, shallowEqual);
    const isLoading = useSelector(arePillOptionsLoadingSelector, shallowEqual);
    const handleChange = (search_string: string) => {
        dispatch(setPillOptionsSearchString({ search_string }));
        if (search_string.length >= 3) {
            (!isLoading ||
                (isUpdated && suggestions.length) ||
                // backspace press
                (previous_search_string && previous_search_string.length > search_string.length)) &&
                dispatch(fetchPillOptionsStub({ search_string }));
        } else {
            dispatch(resetPillOptions());
        }
    };
    const handleSubmit = (values: { stub: string }) => {
        const source = suggestions
            .map((suggestion) => suggestion.toLocaleLowerCase())
            .includes(values.stub.toLocaleLowerCase())
            ? 'db'
            : 'user';
        if (source === 'user') {
            analytics.track('Medication Name Selected', { value: values.stub });
            dispatch(setPillOptionsName({ name: values.stub, is_generic: false }));
        }
        dispatch(setPillOptionsStub(values));
        dispatch(setPillOptionsSource({ source }));
    };

    const styles: CSSProperties = {
        width: '100%',
        maxWidth: '92rem',
        margin: '0 auto'
    };

    return (
        <div style={styles}>
            <Form
                validationSchema={validationSchema}
                submitFn={handleSubmit}
                render={(_, { isValid }) => (
                    <>
                        <H role="h4">Add medication</H>
                        <Combobox
                            suggestions={suggestions}
                            name="stub"
                            displayName="Medication name"
                            placeholder="Start typing (e.g. Aspirin)"
                            onChange={handleChange}
                            showCreateNewOption
                            isFetchingSuggestions={isLoading}
                        />
                        <Button type="submit" disabled={!isValid}>
                            Continue
                        </Button>
                    </>
                )}
            />
        </div>
    );
};

export default StepStub;
