import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { scheduleSelector } from '@hero/redux-data/schedules/selectors';
import { fetchSchedules } from '@hero/redux-data/schedules/actionCreators';
import useLocalization from '@hero/localization';

import { NavGroup, LinkNavItem } from '@hero/ui-kit/inputs/NavGroup';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Loader from '@hero/ui-kit/components/Loader';

import Dialog from '../../../../components/Dialog';
import InnerWrapper from '../../../../components/Dialog/InnerWrapper';
import ProtectedButton from '../../../../components/ProtectedButton';

const ScheduleDetails: React.FC = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const { formatOnlyTime } = useLocalization();
    const scheduleId = params.id || '';
    const schedule = useSelector(scheduleSelector(scheduleId), shallowEqual);
    const buttonMargin = useComponentMargin({ marginTop: 'large' });

    useEffect(() => {
        !schedule ? dispatch(fetchSchedules()) : analytics.page('Schedule Details Viewed');
    }, [dispatch, schedule]);

    if (!schedule) return <Loader />;

    return (
        <Dialog title={formatOnlyTime(schedule.time)}>
            <InnerWrapper>
                <NavGroup id="schedule-menu">
                    {schedule.pills.map(({ pill, qty }) => (
                        <LinkNavItem key={pill.slot} infoText={qty} to={`/medication/${pill.slot}`}>
                            {pill.name}
                        </LinkNavItem>
                    ))}
                </NavGroup>
                <ProtectedButton to={`edit`} styles={buttonMargin} width="full">
                    Change Time
                </ProtectedButton>
            </InnerWrapper>
        </Dialog>
    );
};

export default ScheduleDetails;
