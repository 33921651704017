import React from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';
import LinkButton, { SpecificProps as LinkButtonProps } from '@hero/ui-kit/inputs/LinkButton';
import Button from '@hero/ui-kit/inputs/Button';

import useClickProtection from '../../hooks/useClickProtection';
import ProtectiveModals from '../ProtectiveModals';

type Props = Omit<LinkButtonProps, 'to'> &
    UiComponentProps & { to?: string; onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void };

const ProtectedButton: React.FC<Props> = ({ to, onClick, ...rest }) => {
    const {
        isProtected,
        isReadOnly,
        isDisconnected,
        isInVacationMode,
        isInTravelMode,
        showModal,
        handleShowModal
    } = useClickProtection();

    if (!isProtected)
        return to ? <LinkButton to={to} {...rest} /> : <Button onClick={onClick} {...rest} />;

    return (
        <>
            <Button onClick={handleShowModal} {...rest} />
            {showModal && (
                <ProtectiveModals
                    isReadOnly={isReadOnly}
                    isDisconnected={isDisconnected}
                    isInVacationMode={isInVacationMode}
                    isInTravelMode={isInTravelMode}
                />
            )}
        </>
    );
};

export default ProtectedButton;
