import React from 'react';

import useHasValueChanged from '@hero/react-hooks/useHasValueChanged';
import useIsFirstMount from '@hero/react-hooks/useIsFirsMount';

import useAlerts from '../hooks/useAlerts';

import ContinueSetup from './ContinueSetup';
import DeviceDisconnected from './DeviceDisconnected';
import MembershipExpired from './MembershipExpired';
import MembershipLocked from './MembershipLocked';
import PaymentFailed from './PaymentFailed';
import MembershipLockedLegacy from './MembershipLockedLegacy';
import MembershipDowngraded from '../sections/settings/subsections/Membership/MembershipDowngraded';
import CaregiverNotice from './CaregiverNotice';

const Takeovers: React.FC = () => {
    const {
        isDevMode,
        isFre,
        hasNotCompletedAdminCaregiverSetup,
        isDisconnected,
        isInVacationMode,
        isInTravelMode,
        isLegacyLimitedFree,
        hasMembershipExpired,
        isMembershipLocked,
        isLegacyMembershipLocked,
        hasMembershipPaymentFailed,
        hasCaregiverAccounts,
        isOwner
    } = useAlerts();

    const hasLegacyMembershipLockedStatusChanged = useHasValueChanged(isLegacyMembershipLocked);
    const isFirstMount = useIsFirstMount();

    const renderMembershipTakeover = () => {
        if (!isFirstMount && hasLegacyMembershipLockedStatusChanged && !isLegacyMembershipLocked)
            return <MembershipDowngraded />;
        if (isLegacyMembershipLocked && !isLegacyLimitedFree) return <MembershipLockedLegacy />;
        if (isMembershipLocked && !isLegacyLimitedFree) return <MembershipLocked />;
        if (hasMembershipExpired) return <MembershipExpired />;
        if (hasMembershipPaymentFailed) return <PaymentFailed />;
    };

    if (isDevMode) return null;

    if (window.ReactNativeWebView) return null;

    // NOTE until included into requirements, designed and properly implemented,
    // CG flow is not supported in web app; only users which have their own account
    // can use the app at the moment, "pure" caregivers only see this takeover
    if (hasCaregiverAccounts && !isOwner) return <CaregiverNotice />;

    if (isFre || hasNotCompletedAdminCaregiverSetup) return <ContinueSetup />;

    // should show one membership and the device disconnected takeover at max
    return (
        <>
            {renderMembershipTakeover()}
            {isDisconnected && !isInTravelMode && !isInVacationMode && <DeviceDisconnected />}
        </>
    );
};

export default Takeovers;
