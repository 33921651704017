import { ConstAccessParams } from '@hero/api-services/types';

const modifyForCF = (env: string) => {
    if (process.env.REACT_APP_CF_PAGES === '1') {
        if (process.env.REACT_APP_CF_PAGES_BRANCH === 'master') {
            return process.env[env] || '';
        }
        if (process.env.REACT_APP_CF_PAGES_BRANCH === 'stg-02') {
            return process.env[env + '_STG'] || process.env[env + '_STG_02'] || '';
        }
        if (process.env.REACT_APP_CF_PAGES_BRANCH === 'stg-03') {
            return process.env[env + '_STG_03'] || '';
        }
        return process.env[env + '_DEV'] || '';
    }
    return process.env[env] || '';
};

const envVars: ConstAccessParams = {
    API_ENDPOINT: modifyForCF('REACT_APP_API_CLOUD_HOSTNAME'),
    API_CLOUD_HOSTNAME: modifyForCF('REACT_APP_API_CLOUD_HOSTNAME'),
    API_CLOUD_SOCKET: modifyForCF('REACT_APP_API_CLOUD_SOCKET'),
    API_COMMERCE_HOSTNAME: modifyForCF('REACT_APP_API_COMMERCE_HOSTNAME'),
    API_STOREFRONT_HOSTNAME: modifyForCF('REACT_APP_API_STOREFRONT_HOSTNAME'),
    API_ID_HOSTNAME: modifyForCF('REACT_APP_API_ID_HOSTNAME'),
    API_ID_CLIENT_ID: modifyForCF('REACT_APP_API_ID_CLIENT_ID'),
    API_RTM_HOSTNAME: modifyForCF('REACT_APP_API_RTM_HOSTNAME'),
    TRACKING_TOKEN: modifyForCF('REACT_APP_TRACKING_TOKEN'),
    SENTRY_ENVIRONMENT: modifyForCF('REACT_APP_SENTRY_ENVIRONMENT'),
    SENTRY_DSN: modifyForCF('REACT_APP_SENTRY_DSN'),
    API_PUBLIC_USER: process.env.REACT_APP_API_PUBLIC_USER || '',
    API_PUBLIC_PASS: process.env.REACT_APP_API_PUBLIC_PASS || '',
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY || '',
    GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
    ANDROID_STORE_URL: process.env.REACT_APP_ANDROID_STORE_URL || '',
    IOS_STORE_URL: process.env.REACT_APP_IOS_STORE_URL || '',
    HERO_TERMS_URL: process.env.REACT_APP_HERO_TERMS_URL || '',
    HERO_PRIVACY_POLICY_URL: process.env.REACT_APP_HERO_PRIVACY_POLICY_URL || '',
    HERO_GET_STARTED_URL: process.env.REACT_APP_HERO_GET_STARTED_URL || '',
    HERO_SUPPORT_PHONE_URL: process.env.REACT_APP_HERO_SUPPORT_PHONE_URL || '',
    PRISMIC_URL: process.env.REACT_APP_PRISMIC_URL || '',
    STRIPE_DASHBOARD_BASE_URL: process.env.REACT_APP_STRIPE_DASHBOARD_BASE_URL || '',
    VERSION: process.env.REACT_APP_VERSION || '',
    CLIENT_TYPE: process.env.REACT_APP_CLIENT_TYPE || '',
    IS_BACKOFFICE_APP: false
};

export default envVars;
