import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resetPillOptions } from '@hero/redux-data/pillOptions/actionCreators';
import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import {
    arePillsLoadingSelector,
    arePillsUpdatedSelector,
    pillsErrorSelector,
    isAutoAppliedSelector
} from '@hero/redux-data/pills/selectors';
import { softResetPills, getDayToDayPills } from '@hero/redux-data/medication/actionCreators';

import usePageTitle from '@hero/react-hooks/usePageTitle';
import Section from '@hero/ui-kit/layout/Section';
import Container from '@hero/ui-kit/layout/Container';
import Loader from '@hero/ui-kit/components/Loader';
import { Form } from '@hero/ui-kit/inputs/WizardForm';

import SaveChangesModal from '../../../../views/SaveChangesModal';
import SubmitFailedModal from '../../../../views/SubmitFailedModal';

import submitAddMedication from './submitAddMedication';
import Steps from './Steps';
import Sidebar from './Sidebar';
import Stub from './Stub';
import { pillsSelector } from '@hero/redux-data/medication/selectors';
import checkIfAlreadyExists from '../../utils/checkIfAlreadyExists';
import AlreadyExists from './AlreadyExists';

const AddMedication: React.FC = () => {
    usePageTitle('Hero | Add New Medication');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [shouldStartOver, startOver] = useState(false);
    const pills = useSelector(pillsSelector, shallowEqual);
    const enteredPillOptions = useSelector(enteredPillOptionsSelector, shallowEqual);
    const isLoading = useSelector(arePillsLoadingSelector);
    const isUpdated = useSelector(arePillsUpdatedSelector);
    const isAutoApplied = useSelector(isAutoAppliedSelector);
    const { error, errorMessage } = useSelector(pillsErrorSelector, shallowEqual);
    const alreadyExists = useMemo(
        () => checkIfAlreadyExists(pills, enteredPillOptions),
        [pills, enteredPillOptions]
    );

    const handleSubmitAddMedication = submitAddMedication(dispatch)(enteredPillOptions);

    const handleCloseSuccess = () => {
        dispatch(softResetPills());
        analytics.track('Add Medication Succeeded');
        navigate('/medication');
    };

    useEffect(() => {
        analytics.page('Add Medication Viewed');
        dispatch(getDayToDayPills());
    }, [dispatch]);

    useEffect(() => {
        if (shouldStartOver) {
            dispatch(resetPillOptions());
            startOver(false);
        }
        return () => {
            dispatch(resetPillOptions());
        };
    }, [dispatch, shouldStartOver]);

    useEffect(() => {
        error && analytics.track('Add Medication Error');
    }, [error]);

    useEffect(() => {
        alreadyExists && analytics.track('Pill With Same Name Entered');
    }, [alreadyExists]);

    if (alreadyExists) return <AlreadyExists startOver={startOver} />;
    if (isLoading) return <Loader />;
    if (isUpdated)
        return <SaveChangesModal onClose={handleCloseSuccess} isAutoApplied={isAutoApplied} />;

    return (
        <>
            {error && (
                <SubmitFailedModal title="Medication Not Added">{errorMessage}</SubmitFailedModal>
            )}
            <Section
                subtheme={['regular', 'background']}
                role="wrapper"
                width="full"
                centeredHorizontally
            >
                <Section width="page">
                    <Container>
                        <Section marginVertical="large" paddingTop="regular">
                            {!enteredPillOptions.source ? (
                                <Stub />
                            ) : (
                                <Form submitFn={handleSubmitAddMedication}>
                                    {!enteredPillOptions.name ? (
                                        <Steps source={enteredPillOptions.source} />
                                    ) : (
                                        <Container
                                            gridColumnGap="large"
                                            gridTemplateColumns="1fr 2fr"
                                        >
                                            <Sidebar />
                                            <Steps source={enteredPillOptions.source} />
                                        </Container>
                                    )}
                                </Form>
                            )}
                        </Section>
                    </Container>
                </Section>
            </Section>
        </>
    );
};

export default AddMedication;
