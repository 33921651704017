import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import Button from '@hero/ui-kit/inputs/Button';
import StripeInput from '@hero/ui-kit/components/StripeInput';

import { useScreenSize } from '../../../../context/screenSize';
import useStripeFields from './useStripeFileds';
import useAdditionalFields from './useAdditionalFields';
import StatelessInput from './StatelessInput';
import { isPaymentMethodUpdateLoadingSelector } from '@hero/redux-data/membershipPaymentUpdate/selectors';
import Loader from '@hero/ui-kit/components/Loader';

type Props = { isUpdated: boolean };
const StripeForm: React.FC<Props> = ({ isUpdated }) => {
    const dispatch = useDispatch();
    const {
        cardNumberError,
        handleCardNumberChange,
        cardExpError,
        handleCardExpChange,
        cardCvcError,
        handleCardCvcChange,
        handleSubmit
    } = useStripeFields();

    const { values, errors, handleChange, setErrors } = useAdditionalFields();
    const curriedHandleSubmit = handleSubmit(dispatch, values, setErrors);
    const [isDisabled, setIsDisabled] = useState(true);
    const isPaymentMethodUpdateLoading = useSelector(
        isPaymentMethodUpdateLoadingSelector,
        shallowEqual
    );

    const screenSize = useScreenSize();

    const hasError = useMemo<boolean>(() => {
        let isError = false;
        Object.values(errors).forEach((error) => {
            if (error.length > 0) {
                isError = true;
            }
        });
        return isError;
    }, [errors]);

    useEffect(() => {
        if (hasError) {
            setIsDisabled(true);
        } else {
            Object.values(values).forEach((value) => {
                if (value.length > 0) {
                    return setIsDisabled(false);
                }
            });
        }
    }, [values, hasError]);

    useEffect(() => {
        isUpdated && setIsDisabled(false);
    }, [isUpdated]);

    return isPaymentMethodUpdateLoading ? (
        <Loader />
    ) : (
        <form onSubmit={curriedHandleSubmit}>
            <StatelessInput
                name="name_on_card"
                displayName="name on card"
                placeholder="Name on card"
                value={values.name_on_card}
                error={errors.name_on_card}
                onChange={handleChange}
                size={screenSize === 'mobile' ? 'large' : 'regular'}
            />
            <StripeInput
                error={!!cardNumberError}
                errorMessage={cardNumberError}
                size={screenSize === 'mobile' ? 'large' : 'regular'}
                name="card_number"
                displayName="card number"
            >
                <CardNumberElement onChange={handleCardNumberChange} />
            </StripeInput>
            <StripeInput
                error={!!cardExpError}
                errorMessage={cardExpError}
                size={screenSize === 'mobile' ? 'large' : 'regular'}
                name="card_exp"
                displayName="expiration date"
            >
                <CardExpiryElement onChange={handleCardExpChange} />
            </StripeInput>
            <StripeInput
                error={!!cardCvcError}
                errorMessage={cardCvcError}
                size={screenSize === 'mobile' ? 'large' : 'regular'}
                name="card_cvc"
                displayName="cvc"
            >
                <CardCvcElement onChange={handleCardCvcChange} />
            </StripeInput>
            <StatelessInput
                name="address1"
                displayName="street address"
                placeholder="Address"
                value={values.address1}
                error={errors.address1}
                onChange={handleChange}
                size={screenSize === 'mobile' ? 'large' : 'regular'}
            />
            <StatelessInput
                name="address2"
                displayName="street address line 2"
                placeholder="Apt, suite, etc."
                value={values.address2}
                onChange={handleChange}
                size={screenSize === 'mobile' ? 'large' : 'regular'}
            />
            <StatelessInput
                name="city"
                displayName="city"
                placeholder="City"
                value={values.city}
                error={errors.city}
                onChange={handleChange}
                size={screenSize === 'mobile' ? 'large' : 'regular'}
            />
            <StatelessInput
                name="state"
                displayName="state"
                placeholder="State"
                value={values.state}
                error={errors.state}
                onChange={handleChange}
                size={screenSize === 'mobile' ? 'large' : 'regular'}
            />
            <StatelessInput
                name="zip_code"
                displayName="zip code"
                placeholder="90210"
                value={values.zip_code}
                error={errors.zip_code}
                onChange={handleChange}
                size={screenSize === 'mobile' ? 'large' : 'regular'}
            />
            <Button
                type="submit"
                styles={{ width: screenSize === 'mobile' ? '100%' : '32rem', marginTop: '1.6rem' }}
                disabled={isDisabled}
                onClick={() => setTimeout(() => setIsDisabled(true), 0)}
            >
                Update Payment Method
            </Button>
        </form>
    );
};

export default StripeForm;
