import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Takeover from '@hero/ui-kit/components/Takeover';
import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';
import constAccessParams from '../consts/envVars';
import handleLogout from '@hero/hero-auth/handleLogout';
import { accessTokenSelector } from '@hero/redux-data/auth/selectors';

const CaregiverNotice: React.FC = () => {
    const dispatch = useDispatch();
    const access_token = useSelector(accessTokenSelector)!;

    React.useEffect(() => {
        setTimeout(() => {
            handleLogout(constAccessParams, access_token, dispatch);
        }, 3000);
    }, [dispatch, access_token]);

    return (
        <Takeover icon={MobileIcon} type="brand" title="Mobile-Only Feature">
            Caregiver features are only available in our mobile app.
        </Takeover>
    );
};

export default CaregiverNotice;
