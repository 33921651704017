import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { Step } from '@hero/ui-kit/inputs/WizardForm';
import Input from '@hero/ui-kit/inputs/Input';
import H from '@hero/ui-kit/typography/H';

import validationSchema from '@hero/validators/forms/addMedicationSchema/stepExpirationDate';
import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';

const StepExpirationDate: React.FC = () => {
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const handleComplete = useCallback(({ expires }: { expires?: string }) => {
        expires && analytics.track('Expiration Changed', { value: expires });
    }, []);

    return (
        <Step<typeof validationSchema>
            order={source === 'db' ? 3 : 2}
            validationSchema={validationSchema}
            onSetStepValues={handleComplete}
        >
            <H role="h4">When does it expire? (Optional)</H>
            <Input
                name="expires"
                type="month"
                placeholder="yyyy-mm"
                displayName="Expiration Month & Year"
            />
        </Step>
    );
};

export default StepExpirationDate;
