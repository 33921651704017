import React, { CSSProperties, HTMLAttributes, ReactText } from 'react';
import useTheme from '@hero/branding/theme';
import { Size } from '@hero/branding/types';

import { UiComponentProps } from '@hero/ui-kit/types';
import withFocus, { FocusProps } from '@hero/ui-kit/hocs/withFocus';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Label from '@hero/ui-kit/typography/Label';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import InputError from '@hero/ui-kit/inputs/InputError';

type Props = {
    name: string;
    displayName: string;
    placeholder?: string;
    required?: boolean;
    type?:
        | 'text'
        | 'qty'
        | 'email'
        | 'password'
        | 'tel'
        | 'url'
        | 'date'
        | 'month'
        | 'time'
        | 'number';
    size?: Size;
    disabled?: boolean;
    centerLabel?: boolean;
    value?: ReactText;
    showErrMessage?: boolean;
    error?: string;
} & HTMLAttributes<HTMLInputElement> &
    FocusProps &
    UiComponentProps;

export const Input: React.FC<Props> = ({
    name,
    defaultValue,
    displayName,
    error,
    type = 'text',
    placeholder = '',
    required = false,
    size = 'large',
    disabled = false,
    centerLabel = false,
    showErrMessage = true,
    isFocused,
    handleFocus,
    handleBlur,
    styles,
    ...rest
}) => {
    const isQty = type === 'qty';
    const errorId = `${[name]}Error`;

    const {
        borders,
        sizing: { widths },
        inputs: { base, shape, size: sizeStyles, disabled: disabledInputStyles, utilityIconStyles },
        utility: {
            size: { fullWidthStyles }
        }
    } = useTheme();

    const marginStyles = useComponentMargin({
        marginVertical: 'small',
        ...(!error && { marginBottom: 'regular' })
    });

    const mergedStyles: CSSProperties = {
        ...base,
        ...shape.pill,
        ...sizeStyles.regular,
        ...fullWidthStyles,
        ...(disabled && disabledInputStyles),
        ...(!isFocused && borders.variant.secondary),
        // error styles MUST come after active styles to override them
        ...(!!error && borders.error),
        ...marginStyles,
        ...styles
    };

    const wrapperStyles: CSSProperties = {
        width: widths.input[size],
        position: 'relative'
    };

    return (
        <div style={wrapperStyles}>
            <Label htmlFor={name} required={required} disabled={disabled} centered={!!centerLabel}>
                {displayName}
            </Label>
            <input
                defaultValue={defaultValue}
                placeholder={placeholder}
                type={isQty ? 'text' : type}
                id={name}
                name={name}
                required={required}
                disabled={disabled}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={mergedStyles}
                aria-invalid={!!error}
                aria-describedby={errorId}
                {...rest}
            />
            {!isQty && error && <AlertIcon size="utility" styles={utilityIconStyles} />}
            {showErrMessage && error && <InputError id={errorId}>{error}</InputError>}
        </div>
    );
};

export default withFocus(Input);
