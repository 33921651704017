import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useLocalization from '@hero/localization';
import { parseScheduleDoses, parseDow } from '@hero/hero-utils/schedules';
import checkPillStatus from '@hero/hero-utils/checkPillStatus';
import getMaxDoses from '@hero/hero-utils/getMaxDoses';
import {
    pillSelector,
    arePillsLoadingSelector,
    arePillsUpdatedSelector,
    pillsErrorSelector,
    passcodeSelector
} from '@hero/redux-data/medication/selectors';
import { removePill } from '@hero/redux-data/pill/actionCreators';
import { getDayToDayPills, softResetPills } from '@hero/redux-data/medication/actionCreators';
import { setPasscodeReset } from '@hero/redux-data/accountPasscode/actionCreators';
import {
    isPasscodeLoadingSelector,
    passcodeErrorSelector
} from '@hero/redux-data/accountPasscode/selectors';
import { isAutoAppliedSelector } from '@hero/redux-data/pills/selectors';

import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Eyebrow from '@hero/ui-kit/typography/Eyebrow';
import P from '@hero/ui-kit/typography/P';
import { NavGroup, InfoNavItem } from '@hero/ui-kit/inputs/NavGroup';
import Loader from '@hero/ui-kit/components/Loader';

import Dialog from '../../../../components/Dialog';
import ControlsWrapper from '../../../../components/Dialog/ControlsWrapper';
import PillProp from '../../../../components/PillProp';
import PillWarning from '../../../../components/PillWarning';
import SaveChangesModal from '../../../../views/SaveChangesModal';
import SubmitFailedModal from '../../../../views/SubmitFailedModal';
import ProtectedButton from '../../../../components/ProtectedButton';
import ConfirmRemoveMedication from '../ConfirmRemoveMedication';
import usePillStatuses from '../../../../hooks/usePillStatuses';
import { PillStatus } from '@hero/hero-types';

const PillDetails: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { formatOnlyTime, formatExpiryDate } = useLocalization();
    const [showRemoveModal, handleShowRemoveModal] = useState(false);
    const pillId = params.id || '';
    const pill = useSelector(pillSelector(pillId));
    const pin = useSelector(passcodeSelector, shallowEqual);
    const isLoading = useSelector(arePillsLoadingSelector);
    const isUpdated = useSelector(arePillsUpdatedSelector);
    const isAutoApplied = useSelector(isAutoAppliedSelector);
    const { error, errorMessage } = useSelector(pillsErrorSelector, shallowEqual);
    const isPasscodeLoading = useSelector(isPasscodeLoadingSelector);
    const { error: passcodeError, errorMessage: passcodeErrorMessage } = useSelector(
        passcodeErrorSelector,
        shallowEqual
    );
    const pillStatus = pill && checkPillStatus(pill);
    const pillStatuses = usePillStatuses();

    const handleRemove = useCallback(() => {
        pill && dispatch(removePill(pill.slot));
        analytics.track('Remove Medication Started');
        handleShowRemoveModal(false);
    }, [dispatch, pill]);
    const handleSuccess = useCallback(() => {
        dispatch(softResetPills());
        dispatch(setPasscodeReset());
        navigate('/medication');
        analytics.track('Remove Medication Succeeded');
    }, [dispatch, navigate]);
    const handleReset = useCallback(() => dispatch(softResetPills()), [dispatch]);

    const schedulesMargin = useComponentMargin({ marginBottom: 'regular' });

    useEffect(() => {
        !pill && !isUpdated && dispatch(getDayToDayPills());
        if (pill) {
            analytics.track('View Medication Viewed', {
                slot: pill.slot,
                variant: pillStatus ? pillStatus.toString() : 'Default'
            });
        }
    }, [dispatch, pill, isUpdated, pillStatus, pillStatuses]);

    useEffect(() => {
        showRemoveModal && analytics.page('Remove Medication');
    }, [showRemoveModal]);

    useEffect(() => {
        error && analytics.track('Remove Medication Error');
    }, [error]);

    if (isUpdated)
        return <SaveChangesModal onClose={handleSuccess} isAutoApplied={isAutoApplied} />;
    if (isLoading || isPasscodeLoading || !pill) return <Loader />;
    if (error || passcodeError)
        return (
            <SubmitFailedModal resetAction={handleReset}>
                {errorMessage || passcodeErrorMessage}
            </SubmitFailedModal>
        );

    const { dosage, expires, slot, exact_pill_count, schedules } = pill;
    const parsedSchedules = parseScheduleDoses(schedules || []);

    return (
        <Dialog title={pill.name} backLink="/medication">
            {showRemoveModal && (
                <ConfirmRemoveMedication
                    pillName={pill.name}
                    handleRemove={handleRemove}
                    externalControls={[showRemoveModal, handleShowRemoveModal]}
                />
            )}
            {!pill.stored_in_hero ? (
                <P centered styles={{ fontWeight: 700, fontSize: '16px', marginBottom: '12px' }}>
                    Stored outside Hero
                </P>
            ) : null}
            <Section
                noDefaultPadding
                styles={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
            >
                {pillStatus?.length &&
                    pillStatus.map((status) => {
                        return (
                            <PillWarning
                                key={status}
                                text={pillStatuses[status as PillStatus].name}
                                backgroundColor={pillStatuses[status as PillStatus].warningColor}
                            />
                        );
                    })}
            </Section>

            <Container gridColumnGap="small" gridTemplateColumns="2fr 2fr 2fr">
                <PillProp title="Dose Strength">{dosage}</PillProp>
                <PillProp title="Expiration Date">
                    {expires ? formatExpiryDate(expires) : '-'}
                </PillProp>
                <PillProp title="Cartridge Slot">{slot}</PillProp>
                <PillProp title="Exact Pill Count">{exact_pill_count}</PillProp>
                <PillProp title="Max Doses Per Day">{getMaxDoses(pill)}</PillProp>
            </Container>
            {!!parsedSchedules.length && (
                <Section>
                    <Eyebrow styles={schedulesMargin}>SCHEDULED DOSES</Eyebrow>
                    <NavGroup id="medication-details-schecules">
                        {parsedSchedules.map((item, key) => (
                            <InfoNavItem key={key} infoText={item.qty}>
                                {`${parseDow(item.dow)} at ${formatOnlyTime(item.time)} `}
                            </InfoNavItem>
                        ))}
                    </NavGroup>
                </Section>
            )}
            {pill.requires_pin && pin && (
                <Section>
                    <NavGroup id="medication-details-passcode">
                        <InfoNavItem infoText={pin}>Passcode protected</InfoNavItem>
                    </NavGroup>
                </Section>
            )}
            <ControlsWrapper>
                {/* 0 is the default schedule idx for editing */}
                <ProtectedButton to={`edit`}>Edit</ProtectedButton>
                <ProtectedButton variant="secondary" onClick={() => handleShowRemoveModal(true)}>
                    Remove
                </ProtectedButton>
            </ControlsWrapper>
        </Dialog>
    );
};

export default PillDetails;
