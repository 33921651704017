import React, { useState, CSSProperties } from 'react';

import HeroWheel from './HeroWheel';
import BackspaceImage from './BackspaceImage';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';

const styles: CSSProperties = {
    cursor: 'pointer',
    height: 70,
    width: 70,
    position: 'absolute'
};

const topBox = {
    ...styles,
    top: 10,
    left: '50%',
    transform: 'translate(-50%, 0)'
};

const bottomBox = {
    ...styles,
    bottom: 10,
    left: '50%',
    transform: 'translate(-50%, 0)'
};

const leftBox = {
    ...styles,
    left: 10,
    top: '50%',
    transform: 'translate(0, -50%)'
};

const rightBox = {
    ...styles,
    top: '50%',
    right: 10,
    transform: 'translate(0, -50%)'
};

const wheelBox: CSSProperties = {
    position: 'relative',
    width: '236px'
};

const pinBox: CSSProperties = {
    display: 'flex',
    flexDirection: 'row'
};

const backspaceBox: CSSProperties = {
    width: '72px',
    cursor: 'pointer',
    padding: '20px 0'
};

const pinFieldBox: CSSProperties = {
    fontFamily: "'Gilroy', sans-serif",
    width: '72px',
    height: '76px',
    fontSize: '30px',
    fontWeight: 'bold',
    margin: '0 10px'
};

const pinField: CSSProperties = {
    padding: '20px',
    textAlign: 'center'
};

type Props = {
    addPasscode: (e: string) => void;
    removePasscode: () => void;
    passcode: boolean;
};

const SecurityPasscode: React.FC<Props> = ({ addPasscode, removePasscode, passcode }) => {
    const [pin, setPin] = useState('');
    const pinHandler = (e: string) => {
        if (pin.length < 4) {
            setPin(pin + e);
        }
    };
    const deleteHandler = () => {
        if (pin.length) {
            const b = pin.slice(0, pin.length - 1);
            setPin(b);
        }
    };
    const handleAddPasscode = () => addPasscode && addPasscode(pin);
    const handleRemovePasscode = () => removePasscode && removePasscode();

    return (
        <>
            <Section centered>
                <div style={wheelBox}>
                    <HeroWheel />
                    <div onClick={() => pinHandler('1')} style={topBox}></div>
                    <div onClick={() => pinHandler('2')} style={rightBox}></div>
                    <div onClick={() => pinHandler('3')} style={bottomBox}></div>
                    <div onClick={() => pinHandler('4')} style={leftBox}></div>
                </div>
            </Section>
            <div style={pinBox}>
                <div
                    style={{
                        width: '72px',
                        marginLeft: '26px'
                    }}
                ></div>
                {PinField(pin.slice(0, 1))}
                {PinField(pin.slice(1, 2))}
                {PinField(pin.slice(2, 3))}
                {PinField(pin.slice(3, 4))}
                {pin.length > 0 && (
                    <div onClick={deleteHandler} style={backspaceBox}>
                        <BackspaceImage />
                    </div>
                )}
            </div>
            <Section centered>
                {passcode ? (
                    <>
                        <Button
                            width="large"
                            disabled={pin.length !== 4}
                            onClick={handleAddPasscode}
                        >
                            Update passcode
                        </Button>
                        <Button variant="secondary" width="large" onClick={handleRemovePasscode}>
                            Remove passcode
                        </Button>
                    </>
                ) : (
                    <Button width="large" disabled={pin.length !== 4} onClick={handleAddPasscode}>
                        Save passcode
                    </Button>
                )}
            </Section>
        </>
    );
};

export default SecurityPasscode;

const PinField = (pin: string) => {
    const mergeStyles = {
        ...pinFieldBox,
        borderBottom: `2px solid ${pin ? '#FB7941' : '#e6e5e0'}`
    };
    return (
        <div style={mergeStyles}>
            <div style={pinField}>{pin || ''}</div>
        </div>
    );
};
