import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { passcodeSelector } from '@hero/redux-data/medication/selectors';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { hasMedicationPasscode } from './typeGuards';

const StepPasscodeProtected: React.FC = () => {
    const { goToStep, steps } = useStateMachineContext();
    const { source, stored_in_hero } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const isDbPill = source === 'db';
    const stepNo = isDbPill ? 5 : 4;
    const stepPasscodeProtectedValues = steps[stepNo]?.values || {};
    const passcodeProtected = hasMedicationPasscode(stepPasscodeProtectedValues)
        ? stepPasscodeProtectedValues.requires_pin
        : false;
    const passcode = useSelector(passcodeSelector, shallowEqual);

    const handleClick = useCallback(() => goToStep(isDbPill ? 5 : 4), [isDbPill, goToStep]);

    if (!passcode || !stored_in_hero) return null;

    return (
        <SidebarButton onClick={handleClick}>{`${
            !passcodeProtected ? 'Not ' : ''
        }Passcode Protected`}</SidebarButton>
    );
};

export default StepPasscodeProtected;
