import React from 'react';
import Takeover from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';

type Props = {
    handleRemove: () => void;
    pillName: string;
    externalControls: [boolean, (state: boolean) => void];
};

const ConfirmRemoveMedication: React.FC<Props> = ({ handleRemove, pillName, externalControls }) => {
    return (
        <Takeover
            type="warn"
            title="Remove medication"
            actions={[
                { label: 'Remove Medication', action: handleRemove },
                { label: 'Cancel', action: () => externalControls[1](false) }
            ]}
        >
            <P size="large">{`Are you sure you want to remove ${pillName}?`}</P>
        </Takeover>
    );
};

export default ConfirmRemoveMedication;
