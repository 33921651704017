import React, { useCallback } from 'react';

import ProtectiveModals from '../components/ProtectiveModals';
import useClickProtection from './useClickProtection';

const useSubmitProtection = () => {
    const {
        isProtected,
        isReadOnly,
        isDisconnected,
        isInVacationMode,
        isInTravelMode,
        showModal,
        handleShowModal
    } = useClickProtection();

    const protectSubmitFn = useCallback(
        <T extends Function>(submitFn: T) => (isProtected ? () => handleShowModal() : submitFn),
        [handleShowModal, isProtected]
    );

    const renderProtectiveModals = useCallback(
        () =>
            showModal && (
                <ProtectiveModals
                    isReadOnly={isReadOnly}
                    isDisconnected={isDisconnected}
                    isInVacationMode={isInVacationMode}
                    isInTravelMode={isInTravelMode}
                />
            ),
        [isDisconnected, isInTravelMode, isInVacationMode, isReadOnly, showModal]
    );

    return { protectSubmitFn, renderProtectiveModals };
};

export default useSubmitProtection;
