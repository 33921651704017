import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import { hasMedicationPillCount } from './typeGuards';

const StepPillCount: React.FC = () => {
    const { goToStep, steps } = useStateMachineContext();
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const stepNo = source === 'db' ? 8 : 7;
    const pillCountStepValues = steps[stepNo]?.values || {};
    const pillCount = hasMedicationPillCount(pillCountStepValues)
        ? pillCountStepValues.exact_pill_count
        : '';
    const handleClick = useCallback(() => goToStep(stepNo), [goToStep, stepNo]);

    return pillCount ? (
        <SidebarButton onClick={handleClick}>{`${pillCount} pills`}</SidebarButton>
    ) : null;
};

export default StepPillCount;
