import React, { CSSProperties, useMemo } from 'react';

import useTheme from '@hero/branding/theme';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Span from '@hero/ui-kit/typography/Span';
import Section from '@hero/ui-kit/layout/Section';

type Props = { text: string; backgroundColor?: string };

const PillWarning: React.FC<Props> = ({ text, backgroundColor }) => {
    const spanStyles = useComponentMargin({ marginLeft: 'small' });
    const padding = useComponentPadding({ paddingVertical: 'small', paddingHorizontal: 'regular' });
    const { colors, borderRadius, typography } = useTheme();
    const mergedStyles: CSSProperties = useMemo(() => {
        return {
            backgroundColor,
            borderRadius: borderRadius.regular,
            ...typography.eyebrow,
            display: 'flex',
            alignItems: 'center',
            textTransform: 'none',
            color: colors.neutrals.textAlpha,
            ...padding,
            margin: '1rem'
        };
    }, [padding, colors, typography, borderRadius, backgroundColor]);
    return (
        <Section centered noDefaultPadding>
            <div style={{ ...mergedStyles, fontSize: '14px', fontWeight: 600 }}>
                <Span styles={spanStyles}>{text}</Span>
            </div>
        </Section>
    );
};

export default PillWarning;
