import React, { CSSProperties, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import MainMenu from '@hero/ui-kit/components/MainMenu';
import Tab from '@hero/ui-kit/components/Tab';
import XButton from '@hero/ui-kit/inputs/XButton';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';

import useAlerts from '../../hooks/useAlerts';
import { useScreenSize } from '../../context/screenSize';

const Header: React.FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { shouldShowAlertBar } = useAlerts();

    const screenSize = useScreenSize();

    const padding = useComponentPadding({ paddingHorizontal: 'regular' });

    const styles: CSSProperties = useMemo(
        () => ({
            ...padding,
            boxSizing: 'border-box',
            marginTop: window.ReactNativeWebView ? '4.8rem' : shouldShowAlertBar ? '7.2rem' : 0
        }),
        [shouldShowAlertBar, padding]
    );

    const handleXButtonClick = useCallback(() => {
        if (pathname === '/settings/membership' || window.ReactNativeWebViewNoGoBack) {
            window.ReactNativeWebView.postMessage('hero_payment_success');
        } else {
            navigate(-1);
        }
    }, [navigate, pathname]);

    return window.ReactNativeWebView ? (
        <MainMenu styles={styles} aside={<XButton alt="close" onClick={handleXButtonClick} />} />
    ) : screenSize === 'mobile' ? (
        // NOTE just a quick fix to avoid the layout brakedown on smaller screens, but when
        // in a mobile browser (NOT webview) we might actually want to show a burger menu here
        <MainMenu homePath="/" styles={styles} inverted />
    ) : (
        <MainMenu homePath="/" tabsPosition="end" styles={styles} inverted>
            <Tab to="/medication">Medications</Tab>
            <Tab to="/schedules">Schedules</Tab>
            <Tab to="/settings">Settings</Tab>
        </MainMenu>
    );
};

export default Header;
