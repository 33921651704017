import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useFormContext, useFieldArray } from '@hero/ui-kit/inputs/Form';

import { pillsSelector } from '@hero/redux-data/medication/selectors';

import ChooseDays from '@hero/ui-kit/inputs/ChooseDays';
import Input from '@hero/ui-kit/inputs/Input';
import QtyInput from '@hero/ui-kit/inputs/QtyInput';
import Container from '@hero/ui-kit/layout/Container';
import XButton from '@hero/ui-kit/inputs/XButton';
import Button from '@hero/ui-kit/inputs/Button';
import Eyebrow from '@hero/ui-kit/typography/Eyebrow';

import getUniqueArrVals from '@hero/hero-utils/getUniqueArrVals';
import useHasValueChanged from '@hero/react-hooks/useHasValueChanged';
import useIsFirstMount from '@hero/react-hooks/useIsFirsMount';

import ScheduleDoses from './ScheduleDoses';

const initialDose = { time: '', qty: 1 };

const ScheduleInner: React.FC = () => {
    const [showExistingTimes, handleShowExistingTimes] = useState(false);
    const pills = useSelector(pillsSelector, shallowEqual);
    const isFirsMount = useIsFirstMount();
    const { watch, setValue } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'doses'
    });

    const existingScheduleTimes = useMemo(() => {
        return getUniqueArrVals(
            pills
                .flatMap((pill) => pill.schedules)
                .filter((schedule) => !!schedule)
                .flatMap((schedule) => schedule!.times)
        );
    }, [pills]);

    // in RHF 7 if the property being used for useFieldArray is optional the types end up being `never`
    const coercedFields = useMemo(() => {
        return fields as any as { time: string; qty: number; id: string }[];
    }, [fields]);

    const packedDoses = useMemo(() => {
        const setTimes = coercedFields.map((field) => field.time);
        const filteredTimes = existingScheduleTimes.filter((time) => !setTimes.includes(time));
        return filteredTimes.map((time) => ({ time, qty: 1 }));
    }, [coercedFields, existingScheduleTimes]);

    const handleDayClick = useCallback((day: string) => {
        analytics.track('Day Change Clicked', { value: day });
    }, []);
    const handleAppend = () => {
        analytics.track('Another Dose Clicked');
        append(initialDose);
    };
    const regimen = watch('regimen');
    const hasRegimenChanged = useHasValueChanged(regimen);

    useEffect(() => {
        if (isFirsMount || !regimen) return;
        if (hasRegimenChanged) {
            const humanReadableRegimen =
                regimen === 'as_needed'
                    ? 'As Needed'
                    : regimen === 'select_days'
                    ? 'Select Days'
                    : 'Every Day';
            analytics.track(
                `${
                    regimen === 'as_needed'
                        ? 'As Needed Option'
                        : regimen === 'select_days'
                        ? 'Select Days'
                        : 'Every Day'
                } Clicked`,
                {
                    value: humanReadableRegimen
                }
            );
            analytics.track('Schedule Changed', { value: humanReadableRegimen });
        }
    }, [hasRegimenChanged, regimen, isFirsMount]);

    const xButtonStyles = { marginTop: '1.2rem' };

    useEffect(() => {
        packedDoses.length > 0 && handleShowExistingTimes(true);
        fields.length === 0 && packedDoses.length === 0 && append(initialDose);
    }, [fields, append, packedDoses]);

    const handleTimeInputChange = useCallback(
        (i: number, event: FormEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            setValue(`doses[${i}].time`, value, {
                shouldValidate: true,
                shouldDirty: true
            });
            analytics.track('Dose Time Updated');
        },
        [setValue]
    );

    return (
        <>
            {regimen && regimen !== 'as_needed' && (
                <>
                    {regimen === 'select_days' && (
                        <ChooseDays
                            name="days"
                            displayName="Which days?"
                            onClick={handleDayClick}
                        />
                    )}
                    {coercedFields.map((field, i) => (
                        <Container
                            key={field.id}
                            gridColumnGap="small"
                            gridTemplateColumns="1fr 1fr min-content"
                        >
                            <Input
                                name={`doses[${i}].time`}
                                type="time"
                                placeholder="hh:mm"
                                displayName="At what time?"
                                defaultValue={field.time}
                                onChange={(event) => handleTimeInputChange(i, event)}
                            />
                            <QtyInput
                                name={`doses[${i}].qty`}
                                initialValue={+field.qty || 1}
                                minValue={1}
                                maxValue={10}
                                displayName="How many?"
                                onChange={() => analytics.track('Dose Quantity Updated')}
                            />
                            <XButton
                                alt="remove"
                                styles={xButtonStyles}
                                onClick={() => {
                                    remove(i);
                                    analytics.track('Remove Dose Clicked');
                                }}
                            />
                        </Container>
                    ))}
                    <div>
                        <Eyebrow>Suggested Times</Eyebrow>
                        {showExistingTimes && <ScheduleDoses doses={packedDoses} append={append} />}
                        <Button onClick={handleAppend}>
                            Add {`${packedDoses.length === 0 ? 'an' : ''}`}other time
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default ScheduleInner;
