import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    isReadOnlyCaregiverSelector,
    isReadOnlyOwnerSelector
} from '@hero/redux-data/userStatus/selectors';

const useIsReadOnly = () => {
    const isReadOnlyOwner = useSelector(isReadOnlyOwnerSelector);
    const isReadOnlyCaregiver = useSelector(isReadOnlyCaregiverSelector);

    return useMemo(() => isReadOnlyOwner || isReadOnlyCaregiver, [
        isReadOnlyCaregiver,
        isReadOnlyOwner
    ]);
};

export default useIsReadOnly;
