import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { NavGroup, LinkNavItem } from '@hero/ui-kit/inputs/NavGroup';
import { fetchAlertPreferences } from '@hero/redux-data/notification/actionCreators';
import {
    notificationPreferencesSelector,
    userPhoneNumberSelector,
    isNotificationLoadingSelector
} from '@hero/redux-data/notification/selectors';
import Loader from '@hero/ui-kit/components/Loader';

import ListView from '../../../components/ListView';
import ProtectedNavItem from '../../../components/ProtectedNavItem';

const warnHelper = (data: number, phone: string) => {
    if (data === 0) return 'Set Notification';
    else if (data === 1) return 'Push Notifications';
    else if (data === 2) return `Call ${phone}`;
    else if (data === 3) return `Text ${phone}`;
};

const Notifications: React.FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        analytics.page('Notification Preferences Viewed');
        dispatch(fetchAlertPreferences());
    }, [dispatch]);

    const notificationPreferences = useSelector(notificationPreferencesSelector);
    const { reminder_frequency_minutes, reminder_number_of_alerts, warning_after_15_min_channel } =
        notificationPreferences;
    const phoneNumber = useSelector(userPhoneNumberSelector);
    const isLoading = useSelector(isNotificationLoadingSelector, shallowEqual);

    if (isLoading) return <Loader />;
    return (
        <ListView title="Notification Preferences">
            <NavGroup id="notifications-menu">
                <ProtectedNavItem
                    to="/settings/notifications/hero-reminders"
                    infoText={`Every ${reminder_frequency_minutes} min, ${reminder_number_of_alerts} times`}
                >
                    Reminders on Hero
                </ProtectedNavItem>
                {phoneNumber ? (
                    <LinkNavItem
                        to="/settings/notifications/medication-reminder"
                        infoText={
                            warning_after_15_min_channel
                                ? warnHelper(warning_after_15_min_channel, phoneNumber)
                                : 'None'
                        }
                    >
                        Medication reminder
                    </LinkNavItem>
                ) : (
                    <LinkNavItem
                        to="/settings/notifications/medication-reminder"
                        infoText="First set your phone #"
                    >
                        Medication reminder
                    </LinkNavItem>
                )}
            </NavGroup>
        </ListView>
    );
};

export default Notifications;
