import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Takeover from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';
import Section from '@hero/ui-kit/layout/Section';
import Loader from '@hero/ui-kit/components/Loader';

import { membershipChangePlan } from '@hero/redux-data/membershipChangePlan/actionCreators';
import {
    isMembershipPlanChangeLoadingSelector,
    membershipPlanChangeErrorSelector
} from '@hero/redux-data/membershipChangePlan/selectors';

import MembershipConfirmDowngrade from './MembershipConfirmDowngrade';

const MembershipLockedLegacy: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, handleOpen] = useState(true);
    const [showDowngradeConfirmation, handleShowDowngradeConfirmation] = useState(false);
    const isLoading = useSelector(isMembershipPlanChangeLoadingSelector, shallowEqual);
    const { error, errorMessage } = useSelector(membershipPlanChangeErrorSelector, shallowEqual);

    const handleContinueMembership = useCallback(() => {
        navigate('/locked-payment');
        handleOpen(false);
        handleShowDowngradeConfirmation(false);
    }, [handleOpen, navigate]);
    const handleDowngradeMembership = useCallback(() => {
        dispatch(membershipChangePlan({ key: 'legacy-limited-free' }));
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <Takeover
            type="fail"
            title="Membership Locked"
            actions={[
                {
                    label: 'Continue Membership $9.99 /Mo.',
                    action: handleContinueMembership
                },
                {
                    label: 'Downgrade to Basic Membership',
                    action: () => handleShowDowngradeConfirmation(true)
                }
            ]}
            externalControls={[isOpen, () => handleOpen(!isOpen)]}
        >
            <Section>
                <P size="large" centered inverted>
                    Your app membership has expired. In order to continue using the Hero app please
                    select from the following options.
                </P>
                <P size="large" centered inverted>
                    It may take up to 2 hour to process your payment and unlock your account.
                </P>
            </Section>
            {showDowngradeConfirmation && (
                <MembershipConfirmDowngrade
                    actions={[
                        { label: 'Extend my membership', action: handleContinueMembership },
                        { label: 'I would like to downgrade', action: handleDowngradeMembership }
                    ]}
                />
            )}
            {error && (
                <Takeover
                    type="fail"
                    title="Failed Downgrading Membership"
                    actions={[{ label: 'OK', action: () => handleOpen(false) }]}
                >
                    <P size="large" inverted>
                        {errorMessage}
                    </P>
                </Takeover>
            )}
        </Takeover>
    );
};

export default MembershipLockedLegacy;
