import React, { CSSProperties } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import '@hero/branding/font/gilroy.css';

import useSocket from '@hero/sockets-middleware/useSocket';
import { accessTokenSelector } from '@hero/redux-data/auth/selectors';
import { userIdSelector } from '@hero/redux-data/userStatus/selectors';

import envVars from './consts/envVars';
import AlertBar from './components/AlertBar';
import Routes from './routes';
import Takeovers from './takeovers';
import Header from './components/Header';

const styles: CSSProperties = { minHeight: '100vh', display: 'flex', flexDirection: 'column' };

const App: React.FC = () => {
    const user_id = useSelector(userIdSelector, shallowEqual);
    const access_token = useSelector(accessTokenSelector, shallowEqual);

    useSocket({ type: 'cloud', url: envVars.API_CLOUD_SOCKET, user_id, access_token });

    return (
        <div className="App" style={styles}>
            {access_token && <AlertBar />}
            {access_token && <Header />}
            <Routes />
            <Takeovers />
        </div>
    );
};

export default App;
