import React from 'react';
import useTheme from '@hero/branding/theme';
import getQueryParams from '@hero/hero-utils/getQueryParams';
import Takeover from '@hero/ui-kit/components/Takeover';
import SentToPhoneIcon from '@hero/ui-kit/icons/utility/SentToPhoneIcon';
import P from '@hero/ui-kit/typography/P';
import constAccessParams from '../../consts/envVars';

type QueryParams = {
    email?: string;
};

const MobileOnly: React.FC = () => {
    const theme = useTheme();
    const params = getQueryParams<QueryParams>();

    const email = React.useMemo(() => {
        return params?.email ? encodeURIComponent(params.email) : '';
    }, [params]);

    return (
        <Takeover
            icon={SentToPhoneIcon}
            type="brand"
            title="Signing up is available on Hero's mobile app"
        >
            <P centered strong size="large">
                Tap this button on your phone or{' '}
                <a
                    style={{ color: theme.colors.neutrals.textAlpha }}
                    href={`${constAccessParams.API_STOREFRONT_HOSTNAME}refill-and-delivery/?utm_refill_and_delivery_leads=${email}`}
                >
                    click here
                </a>{' '}
                and we'll contact you with more details!
            </P>
        </Takeover>
    );
};

export default MobileOnly;
