import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Step } from '@hero/ui-kit/inputs/WizardForm';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import H from '@hero/ui-kit/typography/H';
import Loader from '@hero/ui-kit/components/Loader';

import {
    enteredPillOptionsSelector,
    strengthPillOptionsSelector
} from '@hero/redux-data/pillOptions/selectors';
import {
    fetchPillOptionsStrength,
    setPillOptionsStrength
} from '@hero/redux-data/pillOptions/actionCreators';
import { isLoadingSelector } from '@hero/redux-data/utils/helperSelectors';

import validationSchema from '@hero/validators/forms/addMedicationSchema/dbStepStrength';

type Props = {
    setStepValues: (stepNo: number) => (values: object) => void;
};
const DbStepStrength: React.FC<Props> = ({ setStepValues }) => {
    const dispatch = useDispatch();
    const { stub, is_generic, name, form, strength } = useSelector(
        enteredPillOptionsSelector,
        shallowEqual
    );
    const strengthOptions = useSelector(strengthPillOptionsSelector, shallowEqual);
    const isLoading = useSelector(isLoadingSelector('pillOptions'));
    const handleSetStepValues = (values: { strength: string }) => {
        if (!strengthOptions) return;
        analytics.track('Dose Strength Completed');
        const selectedStrengthOptions = strengthOptions.find(
            (option) => option.strength === values.strength
        )!;
        dispatch(setPillOptionsStrength(selectedStrengthOptions));
    };

    useEffect(() => {
        if (!strengthOptions || strength || isLoading) return;

        if (strengthOptions.length === 1) {
            const strengthValue = !!strengthOptions[0].strength
                ? strengthOptions[0].strength
                : 'N/A';

            setStepValues(2)({ strength: strengthValue });
            dispatch(setPillOptionsStrength({ ...strengthOptions[0], strength: strengthValue }));
        }
        if (strengthOptions.length === 0) {
            setStepValues(2)({ strength: 'N/A' });
            dispatch(setPillOptionsStrength({ strength: 'N/A' }));
        }
    }, [dispatch, setStepValues, strengthOptions, strength, isLoading]);

    useEffect(() => {
        form && dispatch(fetchPillOptionsStrength({ stub, is_generic, name, form }));
    }, [stub, is_generic, name, form, dispatch]);

    return (
        <Step<typeof validationSchema>
            order={2}
            validationSchema={validationSchema}
            onSetStepValues={handleSetStepValues}
        >
            {isLoading || !strengthOptions ? (
                <Loader />
            ) : (
                <>
                    <H role="h4">Select the dose strength.</H>
                    <RadioGroup name="strength" displayName="Dose strength">
                        {strengthOptions.map(({ strength }) => (
                            <Radio
                                id={strength || 'na'}
                                key={strength}
                                value={strength || 'N/A'}
                                labelText={strength || 'N/A'}
                            />
                        ))}
                    </RadioGroup>
                </>
            )}
        </Step>
    );
};

export default DbStepStrength;
