import React, { ReactNode, createContext, useContext } from 'react';

import { ScreenType } from '@hero/ui-kit/types';
import useScreenType from '@hero/ui-kit/hooks/useScreenType';
import { ScreenTypeConfig } from '@hero/ui-kit/hooks/useScreenType/getScreenType';

const ScreenSizeContext = createContext<ScreenType | undefined>(undefined);

export const useScreenSize = () => {
    const screenSizeContext = useContext(ScreenSizeContext);

    if (screenSizeContext === undefined) {
        throw new Error(`'useScreenSize' must be used within a 'ScreenSizeProvider'`);
    }

    return screenSizeContext;
};

type ProviderProps = {
    screenTypeConfig: ScreenTypeConfig;
    children?: ReactNode;
};

export const ScreenSizeProvider: React.FC<ProviderProps> = ({ children, screenTypeConfig }) => {
    const screenType = useScreenType(screenTypeConfig);

    return <ScreenSizeContext.Provider value={screenType}>{children}</ScreenSizeContext.Provider>;
};
