import React, { CSSProperties, useMemo } from 'react';

import Container from '@hero/ui-kit/layout/Container';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';

const ControlsWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const padding = useComponentPadding({ paddingTop: 'regular', paddingHorizontal: 'regular' });
    const styles: CSSProperties = useMemo(
        () => ({
            ...padding,
            marginBottom: '-4.8rem'
        }),
        [padding]
    );

    return <Container styles={styles}>{<>{children}</>}</Container>;
};

export default ControlsWrapper;
