import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import MembershipPlanPriceDetails from './MembershipPlanPriceDetails';

const MembershipActivePlans: React.FC = () => {
    const marginTop = useComponentMargin({ marginTop: 'large' });
    const navigate = useNavigate();
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);

    const handleClick = React.useCallback(() => {
        navigate(`/settings/membership/plans/${membershipDetails?.current_plan.key}`);
    }, [navigate, membershipDetails]);

    return (
        <Section centered paddingHorizontal="small">
            <H role="h4" centered>
                Your Hero subscription
            </H>
            <MembershipPlanPriceDetails selected />
            <Button styles={{ ...marginTop, width: '30rem' }} onClick={handleClick}>
                Continue
            </Button>
        </Section>
    );
};

export default MembershipActivePlans;
