import useTheme from '@hero/branding/theme';
import { PillStatus } from '@hero/hero-types';

const usePillStatuses = (): Record<PillStatus, { name: string; warningColor?: string }> => {
    const { colors } = useTheme();

    return {
        empty: { name: 'Empty', warningColor: colors.status.fail.alpha },
        running_low: { name: 'Running low', warningColor: colors.brand.secondary.alpha },
        expired: { name: 'Expired', warningColor: colors.status.fail.alpha },
        expiring_soon: { name: 'About to expire', warningColor: colors.brand.secondary.alpha }
    };
};

export default usePillStatuses;
