import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Step } from '@hero/ui-kit/inputs/WizardForm';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import H from '@hero/ui-kit/typography/H';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { setPillOptionsType } from '@hero/redux-data/pillOptions/actionCreators';

import validationSchema from '@hero/validators/forms/addMedicationSchema/userStepType';

const UserStepType: React.FC = () => {
    const dispatch = useDispatch();
    const { name } = useSelector(enteredPillOptionsSelector, shallowEqual);

    const handleSetStepValues = (values: { medication_type: string }) =>
        dispatch(setPillOptionsType(values));

    return (
        <Step<typeof validationSchema>
            order={0}
            validationSchema={validationSchema}
            onSetStepValues={handleSetStepValues}
            dependentSteps={[1]}
        >
            <H role="h4">{`Tell us more about "${name}"`}</H>
            <RadioGroup name="medication_type" displayName="Medication Type">
                <Radio
                    id="medication_type_supplement"
                    value="Supplement"
                    labelText="Vitamin / Supplement"
                />
                <Radio
                    id="medication_type_otc"
                    value="OTC"
                    labelText="Over the Counter Medication"
                />
                <Radio id="medication_type_rx" value="Rx" labelText="Prescription Medication" />
            </RadioGroup>
        </Step>
    );
};

export default UserStepType;
