import React, { useEffect } from 'react';

import { NavGroup, LinkNavItem } from '@hero/ui-kit/inputs/NavGroup';

import ListView from '../../../components/ListView';
import { useScreenSize } from '../../../context/screenSize';

const Support: React.FC = () => {
    useEffect(() => {
        analytics.page('Get Support Viewed');
    }, []);

    const screenSize = useScreenSize();

    return (
        <ListView title="Get Support">
            <NavGroup id="support-menu">
                <LinkNavItem to="https://herohealth.com/instructionmanual">
                    Download User Guide
                </LinkNavItem>
                <LinkNavItem
                    to={screenSize === 'mobile' ? 'tel://1-855-855-9962' : ''}
                    infoText="1 (855) 855 9962"
                >
                    Call Customer Support
                </LinkNavItem>
                <LinkNavItem to="/settings/support/email">Contact Customer Support</LinkNavItem>
            </NavGroup>
        </ListView>
    );
};

export default Support;
