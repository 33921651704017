import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { unsubscribeRequest, unsubscribeRequestReset } from '@hero/redux-data/unsubscribe/actionCreators';
import {
    isUnsubscribeLoadingSelector,
    isUnsubscribeUpdatedSelector,
    unsubscribeErrorSelector
} from '@hero/redux-data/unsubscribe/selectors';
import SplashView from '@hero/ui-kit/components/SplashView';
import Section from '@hero/ui-kit/layout/Section';
import EyebrowHeading from '@hero/ui-kit/components/EyebrowHeading';
import EmailIcon from '@hero/ui-kit/icons/branding/EmailIcon';
import Button from '@hero/ui-kit/inputs/Button';
import Loader from '@hero/ui-kit/components/Loader';
import InputError from '@hero/ui-kit/inputs/InputError';

import NotFound from '../../views/NotFound';
import UnsubscribeSuccessModal from './UnsubscribeSuccessModal';

const Unsubscribe: React.FC = () => {
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();
    const isLoading = useSelector(isUnsubscribeLoadingSelector);
    const isUpdated = useSelector(isUnsubscribeUpdatedSelector);
    const { error, errorMessage } = useSelector(unsubscribeErrorSelector);

    const email = searchParams.get('email');
    const account_id = searchParams.get('account_id');
    const mailing_list = searchParams.get('mailing_list');

    console.log(mailing_list, 'mailing_list');
    const handleUnsubscribe = useCallback(() => {
        email &&
            account_id &&
            mailing_list &&
            dispatch(unsubscribeRequest({ email, account_id, mailing_list }));
    }, [dispatch, email, account_id, mailing_list]);

    useEffect(
        () => () => {
            dispatch(unsubscribeRequestReset());
        },
        [dispatch]
    );

    if (isLoading) return <Loader />;

    if (isUpdated) return <UnsubscribeSuccessModal />;

    return email && account_id && mailing_list ? (
        <SplashView type="warn">
            <Section centered subtheme={['regular', 'background']} padding="large">
                <EyebrowHeading
                    eyebrow={mailing_list.replace(/[_-]/g, ' ')}
                    heading="We're sad to see you go"
                    centered
                />
                <EmailIcon type="utility" size="large" />
                <Button onClick={handleUnsubscribe} width="large">
                    Unsubscribe
                </Button>
                {error && errorMessage && <InputError id={errorMessage}>{errorMessage}</InputError>}
            </Section>
        </SplashView>
    ) : (
        <NotFound />
    );
};

export default Unsubscribe;
