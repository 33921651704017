import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setPhoneReset } from '@hero/redux-data/setPhone/actionCreators';
import { getUserStatus } from '@hero/redux-data/userLogin/actionCreators';

import Takeover from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';

type Props = {
    redirectUrl: string;
};

const SetPhoneNumberSuccessModal: React.FC<Props> = ({ redirectUrl }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleRedirect = () => navigate(redirectUrl);

    useEffect(() => {
        analytics.track('Phone Verification Request Succeeded');
    }, []);
    useEffect(() => {
        return () => {
            dispatch(setPhoneReset());
            dispatch(getUserStatus());
        };
    }, [dispatch]);

    return (
        <Takeover type="success" title="Phone number confirmed" onClose={handleRedirect}>
            <P size="large">You can now receive voice or text reminders</P>
        </Takeover>
    );
};

export default SetPhoneNumberSuccessModal;
