import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { passcodeSelector } from '@hero/redux-data/medication/selectors';
import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import validationSchema from '@hero/validators/forms/addMedicationSchema/stepPasscode';

import { Step } from '@hero/ui-kit/inputs/WizardForm';
import Passcode from '../../../formSections/Passcode';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

type Props = {
    setStepValues: (stepNo: number) => (values: object) => void;
};

const StepPasscode: React.FC<Props> = ({ setStepValues }) => {
    const { currentStep } = useStateMachineContext();
    const { source, stored_in_hero } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const stepNo = source === 'db' ? 5 : 4;
    const isCurrentStep = currentStep === stepNo;
    const passcode = useSelector(passcodeSelector, shallowEqual);
    const handleComplete = ({ requires_pin }: { requires_pin: boolean }) => {
        analytics.track(`${requires_pin ? 'Add' : 'Skip'} Safety Lock Clicked`);
    };

    useEffect(() => {
        if (isCurrentStep && (!passcode || !stored_in_hero)) {
            setStepValues(stepNo)({ requires_pin: false });
        }
    }, [isCurrentStep, passcode, stored_in_hero, stepNo, setStepValues]);

    return (
        <Step<typeof validationSchema>
            order={stepNo}
            validationSchema={validationSchema}
            onSetStepValues={handleComplete}
            dependentSteps={source === 'db' ? [6] : [5]}
        >
            <Passcode />
        </Step>
    );
};

export default StepPasscode;
