import React from 'react';
import Takeover from '@hero/ui-kit/components/Takeover';
import SentToPhoneIcon from '@hero/ui-kit/icons/utility/SentToPhoneIcon';
import P from '@hero/ui-kit/typography/P';

const SelectAPlan: React.FC = () => {
    return (
        <Takeover
            icon={SentToPhoneIcon}
            type="brand"
            title="Selecting a plan is available on Hero's mobile app"
        >
            <P centered strong size="large">
                Open the email on your phone and click the button to update your plan
            </P>
        </Takeover>
    );
};

export default SelectAPlan;
