import React from 'react';

import Takeover, { Props as TakeoverProps } from '@hero/ui-kit/components/Takeover';

type Props = {
    externalControls: TakeoverProps['externalControls'];
    isUpgrade: boolean;
};

const MembershipUpdated: React.FC<Props> = ({ isUpgrade, externalControls }) => {
    const title = isUpgrade ? 'Membership Upgraded' : 'Downgrade Complete';

    return (
        <Takeover title={title} type="success" externalControls={externalControls}>
            {isUpgrade
                ? 'Your Hero membership plan has been upgraded.'
                : 'You have downgraded your Hero membership.'}
        </Takeover>
    );
};

export default MembershipUpdated;
