import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';
import getScheduledDoses from '@hero/hero-utils/getScheduledDoses';

import calcSchedulesFromState from '../../../utils/calcSchedulesFromState';
import { hasMedicationMaximumDosage, hasMedicationUnlimited } from './typeGuards';

const StepMaximumDosage: React.FC = () => {
    const { goToStep, steps } = useStateMachineContext();
    const { source, schedules } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const scheduledDosesCount = getScheduledDoses(calcSchedulesFromState(schedules));
    const stepNo = source === 'db' ? 7 : 6;
    const thisStep = steps[stepNo];
    const maximumDosageStepValues = thisStep?.values || {};
    const maximumDosage =
        (hasMedicationMaximumDosage(maximumDosageStepValues) &&
            maximumDosageStepValues.max_doses &&
            +maximumDosageStepValues.max_doses) ||
        0;
    const maxCount =
        hasMedicationUnlimited(maximumDosageStepValues) && maximumDosageStepValues.isUnlimited
            ? 0
            : scheduledDosesCount + maximumDosage;
    const handleClick = useCallback(() => goToStep(stepNo), [goToStep, stepNo]);

    return thisStep.isComplete ? (
        <SidebarButton onClick={handleClick}>
            {maxCount === 0
                ? 'No dosage limit'
                : maxCount === 1
                ? 'Maximum 1 dose'
                : `Maximum ${maxCount} doses`}
        </SidebarButton>
    ) : null;
};

export default StepMaximumDosage;
