import React, { useMemo, useCallback, useEffect, CSSProperties } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { InferType } from 'yup';

import setPhoneNumberSchema from '@hero/validators/forms/setPhoneNumber';
import { sendSmsAppLink, resetSendSmsAppLink } from '@hero/redux-data/smsAppLink/actionCreators';
import {
    isSmsAppLinkLoadingSelector,
    isSmsAppLinkUpdatedSelector,
    smsAppLinkErrorSelector,
    smsAppLinkSelector
} from '@hero/redux-data/smsAppLink/selector';

import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import P from '@hero/ui-kit/typography/P';
import Loader from '@hero/ui-kit/components/Loader';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';
import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';
import Section from '@hero/ui-kit/layout/Section';

import Dialog from '../../components/Dialog';
import InnerWrapper from '../../components/Dialog/InnerWrapper';
import SubmitFailedModal from '../SubmitFailedModal';

import SmsAppLinkSuccess from './SmsAppLinkSuccess';

const SmsAppLink: React.FC = () => {
    const dispatch = useDispatch();
    const mobile = useSelector(smsAppLinkSelector);
    const isLoading = useSelector(isSmsAppLinkLoadingSelector);
    const isUpdated = useSelector(isSmsAppLinkUpdatedSelector);

    const { error, errorMessage } = useSelector(smsAppLinkErrorSelector, shallowEqual);

    const defaults = useMemo(() => ({ mobile: '+1' }), []);
    const handleSubmit = useCallback(
        (values: InferType<typeof setPhoneNumberSchema>) => {
            dispatch(sendSmsAppLink(values));
        },
        [dispatch]
    );
    const iconStyles: CSSProperties = useMemo(
        () => ({
            width: '7.2rem',
            height: '7.2rem'
        }),
        []
    );

    useEffect(
        () => () => {
            dispatch(resetSendSmsAppLink());
        },
        [dispatch]
    );

    if (isLoading) return <Loader />;

    return (
        <Dialog title="Download the Hero Mobile App">
            {error && <SubmitFailedModal>{errorMessage}</SubmitFailedModal>}
            {isUpdated && <SmsAppLinkSuccess mobile={mobile} />}
            <InnerWrapper size="small">
                <Section centered>
                    <MobileIcon size="large" type="utility" styles={iconStyles} />
                </Section>
                <Form
                    validationSchema={setPhoneNumberSchema}
                    defaultValues={defaults}
                    submitFn={handleSubmit}
                    render={(_, formState) => (
                        <>
                            <P size="large" centered>
                                For the most full-featured Hero experience we recommend you download
                                our mobile app before continuing setup. Access your daily dashboard,
                                view your adherence metrics, chat with support and more. Available
                                for iOS and Android.
                            </P>
                            <P size="large" centered>
                                Just enter your mobile number below and we will text you a link for
                                easy install.
                            </P>
                            <Input type="tel" name="mobile" displayName="Mobile Number" />
                            <Button type="submit" disabled={!formState.isValid} width="full">
                                Send SMS Link
                            </Button>
                            <LinkButton variant="secondary" to="/" width="full">
                                {isUpdated ? 'Go home' : 'Skip'}
                            </LinkButton>
                        </>
                    )}
                />
            </InnerWrapper>
        </Dialog>
    );
};

export default SmsAppLink;
