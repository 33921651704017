import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';

import ConstAccessParams from './consts/envVars';
import { store } from './store';
import { ScreenSizeProvider } from './context/screenSize';
import { ThemeProvider } from '@hero/branding/theme';
import defaultTheme from '@hero/branding/defaultTheme';

if (ConstAccessParams.SENTRY_ENVIRONMENT && ConstAccessParams.SENTRY_ENVIRONMENT !== 'local') {
    Sentry.init({
        dsn: ConstAccessParams.SENTRY_DSN,
        environment: ConstAccessParams.SENTRY_ENVIRONMENT
    });
}

const theme = { ...defaultTheme, isWebView: !!window.ReactNativeWebView };

const Wrappers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ScreenSizeProvider screenTypeConfig={{ mobile: 590, tablet: 1000 }}>
                    <BrowserRouter>{children}</BrowserRouter>
                </ScreenSizeProvider>
            </ThemeProvider>
        </Provider>
    );
};

export default Wrappers;
