import React, { useCallback, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import newPasswordSchema, { NewPasswordParams } from '@hero/validators/forms/newPasswordSchema';
import CreatePassword from '@hero/ui-kit/inputs/CreatePassword';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import Section from '@hero/ui-kit/layout/Section';
import {
    oldPasswordSelector,
    isLoadingSelector,
    isUpdatedSelector,
    errorSelector
} from '@hero/redux-data/accountNewPassword/selectors';
import { updatePassword, updatePasswordReset } from '@hero/redux-data/accountNewPassword/actionCreators';
import { checkOldPasswordReset } from '@hero/redux-data/accountPassword/actionCreators';

import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';
import InputError from '@hero/ui-kit/inputs/InputError';

import Dialog from '../../../components/Dialog';
import InnerWrapper from '../../../components/Dialog/InnerWrapper';

const Support: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const oldPassword = useSelector(oldPasswordSelector);
    const isLoading = useSelector(isLoadingSelector);
    const isUpdated = useSelector(isUpdatedSelector);
    const { error, errorMessage } = useSelector(errorSelector);

    const submitFn = (params: NewPasswordParams) => {
        analytics.track('Reset Password Started');
        oldPassword && dispatch(updatePassword({ oldPassword, newPassword: params.password }));
    };
    const handleRedirectOnSuccess = useCallback(() => {
        navigate('/settings');
    }, [navigate]);

    useEffect(() => {
        return () => {
            dispatch(updatePasswordReset());
            dispatch(checkOldPasswordReset());
        };
    }, [dispatch]);
    useEffect(() => {
        isUpdated && analytics.track('Reset Password Succeeded');
    }, [isUpdated]);
    useEffect(() => {
        error && analytics.track('Reset Password Failed');
    }, [error]);

    if (isLoading) return <Loader />;
    if (!oldPassword) return <Navigate to="/settings/security/password" />;

    return (
        <Dialog title="Update Password">
            {isUpdated && (
                <Takeover title="Password Changed" type="success" onClose={handleRedirectOnSuccess}>
                    Your password has been changed.
                </Takeover>
            )}
            <InnerWrapper>
                <Form validationSchema={newPasswordSchema} submitFn={submitFn}>
                    <Section centered>
                        <CreatePassword name="password" displayName="New Password" />
                        <Input
                            type="password"
                            name="newPassword"
                            size="large"
                            displayName="Confirm New Password"
                        />
                        <Button width="full" type="submit">
                            Update
                        </Button>
                    </Section>
                </Form>
                {error && errorMessage && <InputError id={errorMessage}>{errorMessage}</InputError>}
            </InnerWrapper>
        </Dialog>
    );
};

export default Support;
