import React, { useCallback, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { InferType } from 'yup';

import verifyPhoneSchema from '@hero/validators/forms/verifyPhoneSchema';

import { verifyPhone } from '@hero/redux-data/setPhone/actionCreators';
import {
    phoneConfigSelector,
    isLoadingSetPhoneSelector,
    setPhoneErrorSelector
} from '@hero/redux-data/setPhone/selectors';

import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import Loader from '@hero/ui-kit/components/Loader';

import Dialog from '../../../components/Dialog';
import InnerWrapper from '../../../components/Dialog/InnerWrapper';
import ControlsWrapper from '../../../components/Dialog/ControlsWrapper';
import SubmitFailedModal from '../../../views/SubmitFailedModal';

type Props = {
    handleStartOver: () => void;
};

const SetPhoneNumberVerify: React.FC<Props> = ({ handleStartOver }) => {
    const phoneConfig = useSelector(phoneConfigSelector, shallowEqual);
    const isLoading = useSelector(isLoadingSetPhoneSelector, shallowEqual);
    const { error, errorMessage } = useSelector(setPhoneErrorSelector, shallowEqual);
    const title = phoneConfig?.is_landline
        ? 'We’ll give you a call to verify your number'
        : 'We’ll send you a text to verify your number';

    const dispatch = useDispatch();
    const handleSubmit = useCallback(
        (values: InferType<typeof verifyPhoneSchema>) => {
            analytics.track('Phone Verification Request Started');
            phoneConfig &&
                phoneConfig.mobile &&
                dispatch(verifyPhone({ pin: +values.pin, mobile: phoneConfig.mobile }));
        },
        [dispatch, phoneConfig]
    );

    useEffect(() => {
        error && analytics.track('Phone Verification Request Failed');
    }, [error]);

    if (isLoading) return <Loader />;

    return (
        <Dialog title={title}>
            {error && <SubmitFailedModal>{errorMessage}</SubmitFailedModal>}
            <InnerWrapper size="small">
                <Form
                    validationSchema={verifyPhoneSchema}
                    submitFn={handleSubmit}
                    render={(_, formState) => (
                        <>
                            <Input
                                type="text"
                                name="pin"
                                displayName="Verification Code"
                                placeholder="e.g. 7761"
                            />
                            <ControlsWrapper>
                                <Button type="submit" disabled={!formState.isValid}>
                                    Next
                                </Button>
                                <Button variant="secondary" onClick={handleStartOver}>
                                    Didn’t receive a code? Resend.
                                </Button>
                            </ControlsWrapper>
                        </>
                    )}
                />
            </InnerWrapper>
        </Dialog>
    );
};

export default SetPhoneNumberVerify;
