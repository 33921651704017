import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { NavGroup, LinkNavItem } from '@hero/ui-kit/inputs/NavGroup';
import Loader from '@hero/ui-kit/components/Loader';
import { accountDetailsSelector } from '@hero/redux-data/accountDetailsUpdate/selectors';
import { getDayToDayPills } from '@hero/redux-data/medication/actionCreators';
import { mobileSelector, mobileUnconfirmedSelector } from '@hero/redux-data/userStatus/selectors';
import { arePillsLoadingSelector, passcodeSelector } from '@hero/redux-data/medication/selectors';

import ListView from '../../../components/ListView';
import ProtectedNavItem from '../../../components/ProtectedNavItem';

const Security: React.FC = () => {
    const dispatch = useDispatch();
    const { email, email_unconfirmed } = useSelector(accountDetailsSelector, shallowEqual);
    const passcode = useSelector(passcodeSelector, shallowEqual);
    const mobile = useSelector(mobileSelector, shallowEqual);
    const unconfirmedMobile = useSelector(mobileUnconfirmedSelector, shallowEqual);
    const isLoading = useSelector(arePillsLoadingSelector, shallowEqual);

    useEffect(() => {
        analytics.page('Account and Security Viewed');
        dispatch(getDayToDayPills());
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <ListView title="Account and Security">
            <NavGroup id="security-menu">
                <LinkNavItem
                    to="/settings/security/update"
                    infoText={email_unconfirmed || email}
                    {...(email_unconfirmed
                        ? { description: 'Unconfirmed Email', isWarning: true }
                        : {})}
                >
                    Your Account
                </LinkNavItem>
                <LinkNavItem to="/settings/security/password" infoText="******">
                    Your Account Password
                </LinkNavItem>
                {(mobile || unconfirmedMobile) && (
                    <LinkNavItem
                        to="/settings/set-phone-number/mobile"
                        infoText={unconfirmedMobile || mobile || ''}
                        {...(unconfirmedMobile && { isWarning: true, description: 'Unconfirmed' })}
                    >
                        Your Phone Number
                    </LinkNavItem>
                )}
                <ProtectedNavItem
                    to={
                        passcode
                            ? '/settings/security/passcode'
                            : '/settings/security/confirm-set-passcode'
                    }
                    infoText={passcode || ''}
                >
                    {passcode ? 'Your Passcode' : 'Add Passcode'}
                </ProtectedNavItem>
                <LinkNavItem to="/settings/security/privacy">Privacy Settings</LinkNavItem>
            </NavGroup>
        </ListView>
    );
};

export default Security;
