import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';
import useHasValueChanged from '@hero/react-hooks/useHasValueChanged';
import useIsFirstMount from '@hero/react-hooks/useIsFirsMount';

import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import Input from '@hero/ui-kit/inputs/Input';
import H from '@hero/ui-kit/typography/H';
import Button from '@hero/ui-kit/inputs/Button';

type Props = {
    stepNo: number;
};

const UserStepStrengthInner: React.FC<Props> = ({ stepNo }) => {
    const { watch } = useFormContext();
    const { setStepValues } = useStateMachineContext();
    const { pill_type, strength } = useSelector(enteredPillOptionsSelector, shallowEqual);

    const handleSkipClick = useCallback(() => {
        analytics.track('Dose Strength Skipped');
        setStepValues(stepNo)({});
    }, [setStepValues, stepNo]);

    const unit = watch('unit');
    const hasUnitChanged = useHasValueChanged(unit);
    const isFirstMount = useIsFirstMount();

    useEffect(() => {
        if (!isFirstMount && hasUnitChanged && unit) {
            analytics.track('Dose Strength Unit Changed', {
                value: unit
            });
        }
    }, [hasUnitChanged, isFirstMount, unit]);

    return (
        <>
            <H role="h4">Select the dose strength.</H>
            <Input name="strength" displayName="Strength" type="number" min={1} />
            <RadioGroup name="unit" displayName="Unit">
                <Radio id="g" value="g" labelText="g" />
                <Radio id="mg" value="mg" labelText="mg" />
                <Radio id="iu" value="iu" labelText="iu" />
                <Radio id="mcg" value="mcg" labelText="mcg" />
            </RadioGroup>
            {pill_type !== 'Rx' && (
                <div>
                    <Button variant="secondary" onClick={handleSkipClick}>
                        {!strength ? 'Skip step' : 'Set to none'}
                    </Button>
                </div>
            )}
        </>
    );
};

export default UserStepStrengthInner;
