import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import Takeover from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';
import Span from '@hero/ui-kit/typography/Span';
import Section from '@hero/ui-kit/layout/Section';
import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import { formatDate } from '@hero/hero-utils/date';

const MembershipExpired: React.FC = () => {
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const navigate = useNavigate();
    const [isOpen, handleOpen] = useState(true);
    const handleUpdateClick = useCallback(() => {
        navigate('/settings/membership/payment');
        handleOpen(false);
    }, [handleOpen, navigate]);

    if (!membershipDetails || !membershipDetails.payment_failed_at || !membershipDetails.expires_at)
        return null;

    const failureDate = formatDate(membershipDetails.payment_failed_at);
    const remainingDays = membershipDetails.days_until_lock;
    const pluralizedRemainingDays = remainingDays === 1 ? '1 day' : `${remainingDays} days`;

    return (
        <Takeover
            title="Membership Expired"
            type="fail"
            externalControls={[isOpen, () => handleOpen(!isOpen)]}
            actions={[
                {
                    label: 'Update Payment Method',
                    action: handleUpdateClick
                },
                { label: 'Skip for now' }
            ]}
        >
            <Section>
                <P size="large" centered inverted>
                    Your prepaid app subscription has expired and we were unable to charge your
                    membership payment on <Span strong>{failureDate}</Span>. Please update your
                    payment method.
                </P>
                <P size="large" centered inverted>
                    <Span strong>{`${pluralizedRemainingDays} remaining `}</Span>
                    until your account is locked.
                </P>
            </Section>
        </Takeover>
    );
};

export default MembershipExpired;
