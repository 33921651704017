import React from 'react';

import ReadOnly from '../../takeovers/ReadOnly';
import DisconnectedFeatureDisabled from '../../takeovers/DisconnectedFeatureDisabled';
import VacationMode from '../../takeovers/VacationMode';
import TravelMode from '../../takeovers/TravelMode';

type Props = {
    isReadOnly: boolean;
    isDisconnected: boolean;
    isInVacationMode: boolean;
    isInTravelMode: boolean;
};

const ProtectiveModals: React.FC<Props> = ({
    isReadOnly,
    isDisconnected,
    isInVacationMode,
    isInTravelMode
}) => (
    <>
        {isReadOnly && <ReadOnly />}
        {isDisconnected && !isInTravelMode && !isInVacationMode && <DisconnectedFeatureDisabled />}
        {isInVacationMode && <VacationMode />}
        {isInTravelMode && <TravelMode />}
    </>
);

export default ProtectiveModals;
