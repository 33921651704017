import React, { CSSProperties } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import { isLoadingSelector } from '@hero/redux-data/utils/helperSelectors';
import {
    checkIfUserMembershipPaymentFailed,
    checkIfUserMembershipExpired,
    checkIfLegacyPrepaid,
    checkIfLegacyMonthly,
    checkIfLegacyLimitedFree,
    getPerPlanLabel
} from '@hero/hero-utils/membership';
import { formatDate } from '@hero/hero-utils/date';
import P from '@hero/ui-kit/typography/P';
import Span from '@hero/ui-kit/typography/Span';
import Eyebrow from '@hero/ui-kit/typography/Eyebrow';
import Divider from '@hero/ui-kit/components/Divider';
import Loader from '@hero/ui-kit/components/Loader';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import useTheme from '@hero/branding/theme';

import Dialog from '../../../../components/Dialog';
import InnerWrapper from '../../../../components/Dialog/InnerWrapper';
import MembershipPlanPriceDetails from './activePlans/MembershipPlanPriceDetails';
import A from '@hero/ui-kit/typography/A';
import Circle from '@hero/ui-kit/graphics/Circle';
import CheckIcon from '@hero/ui-kit/icons/utility/CheckIcon';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import Section from '@hero/ui-kit/layout/Section';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import { getIsPlanActive } from './utils';
import H from '@hero/ui-kit/typography/H';

const Membership: React.FC = () => {
    const { colors } = useTheme();
    const margin = useComponentMargin({ marginTop: 'regular' });
    const paddingBottom = useComponentPadding({ paddingBottom: 'regular' });
    const sectionStyles: CSSProperties = { ...margin, textAlign: 'center' };
    const errorStyles: CSSProperties = { color: colors.status.fail.alpha };

    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const isLoading = useSelector(isLoadingSelector('user'));

    if (!membershipDetails) return <Loader />;

    const { current_plan, next_renewal_date, payment_card, expires_at } = membershipDetails;
    const isPlanActive = getIsPlanActive(membershipDetails);

    const renewalDate = formatDate(next_renewal_date);
    const expiresAt = expires_at && formatDate(expires_at);
    const isPaymentFailed =
        checkIfUserMembershipPaymentFailed(membershipDetails) ||
        checkIfUserMembershipExpired(membershipDetails);

    const renderPlanButton = () => {
        if (!checkIfLegacyPrepaid(membershipDetails)) {
            if (!checkIfLegacyMonthly(membershipDetails))
                return <A to={`/settings/membership/plans/${current_plan.key}`}>Cancel</A>;
            else {
                return <A to={`/settings/membership/plans/${current_plan.key}`}>See details</A>;
            }
        }
    };

    const renderPlanText = () => {
        if (checkIfLegacyPrepaid(membershipDetails)) {
            return (
                <P centered>
                    You are currently in your free membership period for Hero app. You will be
                    automatically enrolled in our Hero subscription program starting
                    <Span strong>{expiresAt || '-'}</Span>
                </P>
            );
        } else if (!checkIfLegacyLimitedFree(membershipDetails)) {
            return (
                <>
                    {['biannual', 'annual'].includes(current_plan?.key || '') ? (
                        <P centered>
                            Your {getPerPlanLabel(current_plan?.storefront_key, true)} subscription
                            will be automatically renewed on <Span strong>{renewalDate}</Span>
                        </P>
                    ) : (
                        <P centered>
                            Your next payment is due on <Span strong>{renewalDate}</Span>
                        </P>
                    )}
                    {current_plan.key === 'biannual' ? (
                        <P centered>Note: Renewal will be at the annual rate.</P>
                    ) : null}
                </>
            );
        }
    };

    return (
        <Dialog title="Manage your subscription" hideBorders>
            {isLoading && <Loader />}
            <InnerWrapper size="regular">
                {isPlanActive ? (
                    <section style={{ ...sectionStyles }}>
                        <MembershipPlanPriceDetails />
                    </section>
                ) : (
                    <H role="h6" centered styles={{ fontSize: '20px' }}>
                        {current_plan.name}
                    </H>
                )}
                <section style={sectionStyles}>
                    {renderPlanText()}
                    {renderPlanButton()}
                </section>
                <Divider styles={margin} />
                <section style={sectionStyles}>
                    <Eyebrow centered styles={{ fontSize: '20px' }}>
                        Payment Method
                    </Eyebrow>
                    <Section centered noDefaultPadding styles={{ ...paddingBottom }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <P styles={{ ...margin, fontSize: '16px', marginRight: '1rem' }}>
                                {`Credit card: `}
                                <span>****</span> - <span>****</span> - <span>****</span> -
                                {` ${payment_card?.last4}` || ' ****'}
                            </P>
                            <Circle kind={isPaymentFailed ? 'fail' : 'success'} size="small">
                                {isPaymentFailed ? (
                                    <AlertIcon width="80%" height="80%" x="10%" y="10%" />
                                ) : (
                                    <CheckIcon width="80%" height="80%" x="10%" y="10%" />
                                )}
                            </Circle>
                        </div>
                        {isPaymentFailed ? (
                            <P centered styles={errorStyles}>
                                Uh-oh! We were unable to process your payment. Please update your
                                credit card.
                            </P>
                        ) : null}
                    </Section>
                    <A to="/settings/membership/payment">
                        {payment_card ? 'Update Payment Method' : 'Add Payment Method'}
                    </A>
                </section>
            </InnerWrapper>
        </Dialog>
    );
};

export default Membership;
