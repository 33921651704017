import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';
import { hasMedicationSchedules } from './typeGuards';

const StepSchedule: React.FC = () => {
    const { goToStep, steps } = useStateMachineContext();
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const stepNo = source === 'db' ? 6 : 5;
    const scheduleStepValues = steps[stepNo]?.values || {};
    const hasSchedule = hasMedicationSchedules(scheduleStepValues);

    const handleClick = useCallback(() => goToStep(stepNo), [goToStep, stepNo]);

    return hasSchedule ? <SidebarButton onClick={handleClick}>Schedules</SidebarButton> : null;
};

export default StepSchedule;
