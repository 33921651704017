import { PillSchedule, Schedule } from '@hero/hero-types';

type CalcSchedulesFromState = (schedules?: PillSchedule[]) => Schedule[];
const calcSchedulesFromState: CalcSchedulesFromState = (schedules) =>
    schedules
        ? schedules.map((schedule) => ({
              ...schedule,
              dow: schedule.dow
                  .map(([first, ...rest]) => `${first.toUpperCase()}${rest.join('')}`)
                  .join(', ')
          }))
        : [];

export default calcSchedulesFromState;
