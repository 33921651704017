import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';

import useTheme from '@hero/branding/theme';
import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Button from '@hero/ui-kit/inputs/Button';
import Loader from '@hero/ui-kit/components/Loader';
import InputError from '@hero/ui-kit/inputs/InputError';
import oldPasswordSchema, { OldPasswordParams } from '@hero/validators/forms/oldPasswordSchema';
import {
    isLoadingSelector,
    isUpdatedSelector,
    oldPasswordErrorSelector
} from '@hero/redux-data/accountPassword/selectors';
import { checkOldPassword, checkOldPasswordReset } from '@hero/redux-data/accountPassword/actionCreators';

import Dialog from '../../../components/Dialog';
import InnerWrapper from '../../../components/Dialog/InnerWrapper';

const SecurityPassword: React.FC = () => {
    const dispatch = useDispatch();
    const submitFn = (params: OldPasswordParams) => dispatch(checkOldPassword(params));
    const isLoading = useSelector(isLoadingSelector);
    const isUpdated = useSelector(isUpdatedSelector);
    const { error, errorMessage } = useSelector(oldPasswordErrorSelector, shallowEqual);

    const { colors } = useTheme();
    const errorStyles = useMemo(() => ({ color: colors.status.fail.alpha }), [colors]);

    useEffect(() => {
        return () => {
            error && dispatch(checkOldPasswordReset());
        };
    }, [dispatch, error]);

    if (isLoading) return <Loader />;
    if (isUpdated) return <Navigate to="/settings/security/new-password" />;

    return (
        <Dialog title="Update Password">
            <InnerWrapper>
                <Form
                    validationSchema={oldPasswordSchema}
                    submitFn={submitFn}
                    render={(_, formState) => (
                        <>
                            <Input
                                size="large"
                                type="password"
                                name="password"
                                displayName="Current Password"
                            />

                            {error && errorMessage && (
                                <InputError id={errorMessage} styles={errorStyles}>
                                    {errorMessage}
                                </InputError>
                            )}
                            <Button type="submit" width="full" disabled={!formState.isValid}>
                                Next
                            </Button>
                        </>
                    )}
                />
            </InnerWrapper>
        </Dialog>
    );
};
export default SecurityPassword;
