import React, { useEffect } from 'react';

// ignore the ignores, this copy-pasted legacy code that will handle legacy
// activation & confirmation links until mobile switches to using IAM
const LegacyActivate: React.FC = () => {
    useEffect(() => {
        /* eslint-disable */
        if (window.location.hash) {
            var urlParts = window.location.hash.substr(1).split('/');
            var action = decodeURIComponent(urlParts[0]);
            var queryMobile = '';
            var actionWeb = '';
            var queryWeb = '';

            switch (action) {
                case 'register':
                    queryMobile = `/${urlParts[1]}/${urlParts[2]}`;
                    actionWeb = 'register-activate';
                    queryWeb = `?email=${urlParts[1]}&activation_code=${urlParts[2]}`;
                    break;
                case 'forgotpass':
                    queryMobile = `/${urlParts[1]}/${urlParts[2]}`;
                    actionWeb = 'reset-password';
                    queryWeb = `?email=${urlParts[1]}&activation_code=${urlParts[2]}`;
                    break;
                case 'changemail':
                    queryMobile = `/${urlParts[1]}/${urlParts[2]}`;
                    actionWeb = 'change-email-confirm';
                    queryWeb = `?email=${urlParts[1]}&activation_code=${urlParts[2]}`;
                    break;
                case 'unsubscribe':
                    queryMobile = `/${urlParts[1]}/${urlParts[2]}`;
                    actionWeb = 'unsubscribe';
                    queryWeb = `?email=${urlParts[1]}&account_id=${urlParts[2]}`;
                    break;
            }

            // @ts-ignore
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            var isAndroid = /android/i.test(userAgent);
            var isiOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;

            var currentUrl = window.location;
            var fallbackUrl =
                currentUrl.protocol + '//' + currentUrl.host + '/' + actionWeb + queryWeb;

            if (isAndroid || isiOS) {
                setTimeout(function () {
                    window.location.href = fallbackUrl;
                }, 500);

                window.location.href = 'heroapp://mobile/' + action + queryMobile;
            } else {
                window.location.href = fallbackUrl;
            }
        }
        /* eslint-enable */
    });
    return null;
};

export default LegacyActivate;
