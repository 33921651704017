import React from 'react';

import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Input from '@hero/ui-kit/inputs/Input';

const PillCount: React.FC = () => (
    <>
        <H role="h4">Pill Count (Optional)</H>
        <P>After 10 dispenses Hero will estimate how much you have remaining.</P>
        <P>
            For exact pill quantity you can track the exact number of pills you have by entering it
            here:
        </P>
        <Input name="exact_pill_count" type="number" displayName="Exact pill count" min={0} />
    </>
);

export default PillCount;
