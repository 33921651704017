import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import { hasMedicationForm } from './typeGuards';

const StepForm: React.FC = () => {
    const { goToStep, steps } = useStateMachineContext();

    const formStepValues = steps[1]?.values || {};
    const form = hasMedicationForm(formStepValues) ? formStepValues.form : '';
    const handleClick = useCallback(() => goToStep(1), [goToStep]);
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);

    return source === 'db' && form && form !== 'N/A' ? (
        <SidebarButton onClick={handleClick}>{form}</SidebarButton>
    ) : null;
};

export default StepForm;
