import React, { useCallback, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { parseDowShort } from '@hero/hero-utils/schedules';
import useLocalization from '@hero/localization';
import { pillSelector } from '@hero/redux-data/medication/selectors';
import { editPill } from '@hero/redux-data/pill/actionCreators';
import validationSchema from '@hero/validators/forms/addMedicationSchema/stepSchedule';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Takeover from '@hero/ui-kit/components/Takeover';
import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';

import Schedule from '../../sections/medications/formSections/Schedule';
import InnerWrapper from '../../components/Dialog/InnerWrapper';
import ControlsWrapper from '../../components/Dialog/ControlsWrapper';
import ConfirmRemoveSchedule from '../ConfirmRemoveSchedule';

import getEditScheduleDefaults from './getEditScheduleDefaults';
import submitSchedule from './submitSchedule';

type Props = {
    id: string;
    scheduleId: string;
    pillScheduleIds?: number[] | undefined;
    handleScheduleModalClose: () => void;
    onSuccess?: () => void;
};
const EditSingleSchedule: React.FC<Props> = ({
    id,
    scheduleId,
    handleScheduleModalClose,
    onSuccess,
    pillScheduleIds
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { formatOnlyTime } = useLocalization();
    const [showRemoveScheduleModal, handleShowRemoveScheduleModal] = useState(false);
    const [isUnique, setIsUnique] = useState(true);
    const goBack = useCallback(() => navigate(-1), [navigate]);
    const pill = useSelector(pillSelector(id), shallowEqual);
    const isNewSchedule = scheduleId === 'new';

    const handleRemoveSchedule = useCallback(() => {
        if (pill && pill.schedules) {
            const schedules = pill?.schedules?.filter((schedule) => schedule.id !== scheduleId);

            const pill_schedules = pill?.pill_schedules?.filter(
                (schedule) => !pillScheduleIds?.includes(schedule.hero_schedule_id || -1)
            );

            dispatch(
                editPill({
                    ...pill,
                    schedules,
                    pill_schedules
                })
            );
            handleShowRemoveScheduleModal(false);
        }
    }, [dispatch, pill, scheduleId, pillScheduleIds]);

    if (
        !pill ||
        !pill?.schedules ||
        (!isNewSchedule && !pill.schedules.find((schedule) => schedule.id === scheduleId))
    ) {
        goBack();
        return null;
    }

    const { dow, times } = isNewSchedule
        ? { dow: undefined, times: [''] }
        : pill.schedules.find((schedule) => schedule.id === scheduleId)!;
    const scheduleName =
        dow && times
            ? `${parseDowShort(dow)} at ${times.map(formatOnlyTime).join(' & ')}`
            : 'As needed';
    const defaults = getEditScheduleDefaults(pill, scheduleId);
    const handleSubmit = submitSchedule(dispatch, setIsUnique, onSuccess)(pill, scheduleId);

    return (
        <Modal onClose={handleScheduleModalClose} isCancelable>
            {showRemoveScheduleModal && (
                <ConfirmRemoveSchedule
                    handleRemove={handleRemoveSchedule}
                    schedule={scheduleName}
                    externalControls={[showRemoveScheduleModal, handleShowRemoveScheduleModal]}
                />
            )}
            {!isUnique && (
                <Takeover
                    type="fail"
                    title="Schedule already exists"
                    actions={[{ label: 'Ok', action: () => setIsUnique(true) }]}
                >
                    {`You already have a scheduled dose of ${pill.name} at this time. Please edit
                    the existing schedule or select other time.`}
                </Takeover>
            )}
            <H role="h4" centered>
                How often do you take it?
            </H>
            <InnerWrapper>
                <Form
                    validationSchema={validationSchema}
                    defaultValues={!isNewSchedule ? defaults : undefined}
                    // @ts-ignore
                    submitFn={handleSubmit}
                    render={(_, formState) => (
                        <>
                            <Schedule isInEditMode />
                            <ControlsWrapper>
                                <Button type="submit" disabled={!formState.isValid}>
                                    Save schedule
                                </Button>
                                {!isNewSchedule && (
                                    <Button
                                        variant="secondary"
                                        onClick={() => handleShowRemoveScheduleModal(true)}
                                    >
                                        Remove schedule
                                    </Button>
                                )}
                                <Button variant="secondary" onClick={handleScheduleModalClose}>
                                    Cancel
                                </Button>
                            </ControlsWrapper>
                        </>
                    )}
                />
            </InnerWrapper>
        </Modal>
    );
};

export default EditSingleSchedule;
