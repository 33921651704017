import React from 'react';

import Takeover from '@hero/ui-kit/components/Takeover';
import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';

const VacationMode: React.FC = () => {
    return (
        <Takeover
            actions={[{ label: 'Close' }]}
            icon={MobileIcon}
            type="bold"
            title="Feature Disabled"
        >
            This feature is disabled when Hero is in Vacation Mode.
        </Takeover>
    );
};

export default VacationMode;
