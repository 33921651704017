import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import usePageTitle from '@hero/react-hooks/usePageTitle';
import { getDayToDayPills } from '@hero/redux-data/medication/actionCreators';
import {
    selectPillsSelector,
    arePillsLoadingSelector,
    arePillsUpdatedSelector
} from '@hero/redux-data/medication/selectors';
import validationSchema from '@hero/validators/forms/enterMedicationName';
import { isAutoAppliedSelector } from '@hero/redux-data/pills/selectors';

import Form from '@hero/ui-kit/inputs/Form';
import H from '@hero/ui-kit/typography/H';
import Button from '@hero/ui-kit/inputs/Button';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import Loader from '@hero/ui-kit/components/Loader';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';

import EditSingleSchedule from '../../../../takeovers/EditSingleSchedule/EditSingleSchedule';
import SaveChangesModal from '../../../../views/SaveChangesModal';

const NewSchedule: React.FC = () => {
    usePageTitle('Hero | Add Schedule');
    const dispatch = useDispatch();
    const [editPillScheduleId, setEditPillScheduleId] = useState(0);
    const pillsList = useSelector(selectPillsSelector, shallowEqual);
    const isLoading = useSelector(arePillsLoadingSelector);
    const isUpdated = useSelector(arePillsUpdatedSelector);
    const isAutoApplied = useSelector(isAutoAppliedSelector);
    const styles: CSSProperties = {
        width: '100%',
        maxWidth: '92rem',
        margin: '0 auto'
    };

    const handleSubmit = useCallback(
        (values: { stub: string }) => {
            setEditPillScheduleId(+values.stub);
        },
        [setEditPillScheduleId]
    );
    const handleSuccess = useCallback(() => {
        dispatch(getDayToDayPills());
    }, [dispatch]);

    useEffect(() => {
        analytics.page('Add Schedule Viewed');
        dispatch(getDayToDayPills());
    }, [dispatch]);

    if (isLoading) return <Loader />;
    if (isUpdated)
        return <SaveChangesModal onClose={handleSuccess} isAutoApplied={isAutoApplied} />;

    return (
        <Section width="full" marginVertical="large" centeredHorizontally>
            {editPillScheduleId ? (
                <EditSingleSchedule
                    id={String(editPillScheduleId)}
                    scheduleId="new"
                    handleScheduleModalClose={() => setEditPillScheduleId(0)}
                    onSuccess={() => setEditPillScheduleId(0)}
                />
            ) : null}
            <div style={styles}>
                <Form
                    validationSchema={validationSchema}
                    submitFn={handleSubmit}
                    render={(_, formState) => (
                        <>
                            <H role="h4">Add schedule</H>
                            <P size="large">Which medication would you like to add schedule</P>
                            <Select name="stub" displayName="Select from your medication list">
                                {pillsList.map((pill) => (
                                    <Option key={pill.value} {...pill} />
                                ))}
                            </Select>
                            <Button type="submit" disabled={!formState.isValid}>
                                Continue
                            </Button>
                        </>
                    )}
                />
            </div>
        </Section>
    );
};

export default NewSchedule;
