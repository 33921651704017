import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Takeover, { TakeoverAction } from '@hero/ui-kit/components/Takeover';

type Props = { msg: string | null };

const Fail: React.FC<Props> = ({ msg }) => {
    const navigate = useNavigate();
    const handleOk = useCallback(() => {
        navigate('/schedules');
    }, [navigate]);

    const actions: [TakeoverAction] = useMemo(
        () => [{ label: 'OK', action: handleOk }],
        [handleOk]
    );

    return (
        <Takeover type="fail" title="An Error Ocurred" actions={actions}>
            {msg}
        </Takeover>
    );
};

export default Fail;
