import React, { CSSProperties, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Section from '@hero/ui-kit/layout/Section';
import { PillSource } from '@hero/redux-data/pillOptions/actionCreators';
import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import StepExpirationDate from './StepExpirationDate';
import StepStoredInHero from './StepStoredInHero';
import StepPasscode from './StepPasscode';
import StepSchedules from './StepSchedules';
import StepMaximumDosage from './StepMaximumDosage';
import StepPillCount from './StepPillCount';

import DbStepName from './DbStepName';
import DbStepForm from './DbStepForm';
import DbStepStrength from './DbStepStrength';

import UserStepType from './UserStepType';
import UserStepStrength from './UserStepStrength';
import PreventNavigation from '../../../../../components/PreventNavigation';

type Props = {
    source: PillSource;
};

const Steps: React.FC<Props> = ({ source }) => {
    const { setStepValues, currentStep, isComplete } = useStateMachineContext();
    const { name } = useSelector(enteredPillOptionsSelector, shallowEqual);

    const styles: CSSProperties = useMemo(
        () =>
            currentStep === 0 && !name
                ? {
                      width: '100%',
                      maxWidth: '92rem',
                      paddingLeft: 0,
                      paddingRight: 0,
                      margin: '0 auto'
                  }
                : {},
        [currentStep, name]
    );

    return (
        <Section styles={styles}>
            {source === 'db' ? (
                <>
                    <DbStepName setStepValues={setStepValues} />
                    <DbStepForm setStepValues={setStepValues} />
                    <DbStepStrength setStepValues={setStepValues} />
                </>
            ) : (
                <>
                    <UserStepType />
                    <UserStepStrength />
                </>
            )}
            <StepExpirationDate />
            <StepStoredInHero />
            <StepPasscode setStepValues={setStepValues} />
            <StepSchedules />
            <StepMaximumDosage />
            <StepPillCount />
            <PreventNavigation when={!isComplete} />
        </Section>
    );
};

export default Steps;
