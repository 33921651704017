import React from 'react';

import Takeover, { WarnTakeoverProps } from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';

const MembershipConfirmDowngrade: React.FC<Pick<WarnTakeoverProps, 'actions'>> = ({ actions }) => (
    <Takeover type="warn" title="Are you Sure?" actions={actions}>
        <P size="large" centered>
            If you downgrade your membership you will only be able to update your schedule and
            regimen through the web interface at app.herohealth.com. Your Hero device will continue
            to be operable.
        </P>
        <P size="large">You will lose access to the Hero mobile app including:</P>
        <ul>
            <li>
                <P size="large">Medication reminders</P>
            </li>
            <li>
                <P size="large">Today Screen</P>
            </li>
            <li>
                <P size="large">Daily Journal</P>
            </li>
            <li>
                <P size="large">Drug Interactions</P>
            </li>
            <li>
                <P size="large">Hero Fill</P>
            </li>
            <li>
                <P size="large">Statistics</P>
            </li>
        </ul>
    </Takeover>
);

export default MembershipConfirmDowngrade;
