import { useSelector, shallowEqual } from 'react-redux';

import { isDevModeSelector } from '@hero/redux-data/devMode';
import {
    isFreSelector,
    isOwnerSelector,
    hasCompletedAdminCaregiverSetupSelector,
    hasCaregiverAccountsSelector,
    isCaregiverSelector,
    membershipDetailsSelector
} from '@hero/redux-data/userStatus/selectors';
import {
    hasPendingChangesSelector,
    isDisconnectedSelector,
    isInTravelModeSelector,
    isInVacationModeSelector
} from '@hero/redux-data/userFlags/selectors';
import {
    checkIfUserMembershipPaymentFailed,
    checkIfUserMembershipExpired,
    checkIfUserMembershipLocked,
    checkIfLegacyUserLock,
    checkIfLegacyLimitedFree
} from '@hero/hero-utils/membership';

const useAlerts = () => {
    // device
    const isDevMode = useSelector(isDevModeSelector, shallowEqual);
    const isFre = useSelector(isFreSelector, shallowEqual);
    const isOwner = useSelector(isOwnerSelector);
    const hasCompletedAdminCaregiverSetup = useSelector(hasCompletedAdminCaregiverSetupSelector);
    const isDisconnected = useSelector(isDisconnectedSelector, shallowEqual);
    const isInVacationMode = useSelector(isInVacationModeSelector, shallowEqual);
    const isInTravelMode = useSelector(isInTravelModeSelector, shallowEqual);
    const hasPendingChanges = useSelector(hasPendingChangesSelector, shallowEqual);
    const hasCaregiverAccounts = useSelector(hasCaregiverAccountsSelector);
    const isCaregiver = useSelector(isCaregiverSelector);

    // membership
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const isLegacyLimitedFree = !!membershipDetails && checkIfLegacyLimitedFree(membershipDetails);
    const hasMembershipExpired =
        !!membershipDetails && checkIfUserMembershipExpired(membershipDetails);
    const isMembershipLocked =
        !!membershipDetails && checkIfUserMembershipLocked(membershipDetails);
    const isLegacyMembershipLocked =
        !!membershipDetails && checkIfLegacyUserLock(membershipDetails);
    const hasMembershipPaymentFailed =
        !!membershipDetails && checkIfUserMembershipPaymentFailed(membershipDetails);

    const shouldShowAlertBar =
        !window.ReactNativeWebView &&
        ((isDisconnected && !isInVacationMode) ||
            isInTravelMode ||
            hasPendingChanges ||
            hasMembershipPaymentFailed);

    return {
        isDevMode,
        isFre,
        hasNotCompletedAdminCaregiverSetup: !isOwner && !hasCompletedAdminCaregiverSetup,
        isDisconnected,
        isInVacationMode,
        isInTravelMode,
        hasPendingChanges,
        isLegacyLimitedFree,
        hasMembershipExpired,
        isMembershipLocked,
        isLegacyMembershipLocked,
        hasMembershipPaymentFailed,
        isOwner,
        isCaregiver,
        hasCaregiverAccounts,
        shouldShowAlertBar
    };
};

export default useAlerts;
