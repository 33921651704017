import React from 'react';

import Takeover from '@hero/ui-kit/components/Takeover';
import LockIcon from '@hero/ui-kit/icons/branding/LockIcon';

const ReadOnly: React.FC = () => (
    <Takeover actions={[{ label: 'Close' }]} icon={LockIcon} type="bold" title="Feature Disabled">
        You are not allowed to make any changes.
    </Takeover>
);

export default ReadOnly;
