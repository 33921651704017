import React from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFound from '../../views/NotFound';

import Menu from './Menu';
import EditSchedule from './subsections/EditSchedule';
import NewSchedule from './subsections/NewSchedule';
import ScheduleDetails from './subsections/ScheduleDetails';
import useAuthStatus from '@hero/hero-auth/useAuthStatus';
import constAccessParams from '../../consts/envVars';

const Schedules: React.FC = () => {
    const { accessToken } = useAuthStatus(constAccessParams);

    return accessToken ? (
        <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="new" element={<NewSchedule />} />
            <Route path=":id/edit" element={<EditSchedule />} />
            <Route path=":id" element={<ScheduleDetails />} />
            <Route element={<NotFound />} />
        </Routes>
    ) : null;
};

export default Schedules;
