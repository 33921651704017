import { MiddlewareAPI, Dispatch, Action } from 'redux';

import HeroUserApi from '@hero/api-services/HeroUserApi';
import { REFRESH_TOKEN } from '@hero/redux-data/auth/actionTypes';
import {
    loginDone,
    refreshTokenError,
    refreshToken as saveRefreshToken,
    logout
} from '@hero/redux-data/auth/actionCreators';
import { ConstAccessParams } from '@hero/api-services/types';
import hasRefreshSuccessGuard from './hasRefreshSuccessGuard';

let timeout: NodeJS.Timeout;

type ModifiedAction = {
    data?: { msg?: string; statusCode?: number };
} & Action<any>;

const refreshMobileTokenMiddleware =
    <Actions extends ModifiedAction>(constAccessParams: ConstAccessParams) =>
    (api: MiddlewareAPI) =>
    (next: Dispatch<Actions>) =>
    (action: Actions) => {
        if (typeof action?.data === 'object' && action.data?.statusCode === 4) {
            api.dispatch(saveRefreshToken());
        }
        if (action.type === REFRESH_TOKEN) {
            const { accessToken, access_token, refreshToken, refresh_token } = window.userObj; // two same values, differences between new and old mobile app
            const accessParams = {
                ...constAccessParams,
                access_token: access_token || accessToken,
                refresh_token: refresh_token || refreshToken
            };
            HeroUserApi.refreshPrivateToken(accessParams)
                // eslint-disable-next-line consistent-return
                .then((response) => {
                    if (hasRefreshSuccessGuard(response)) {
                        timeout && clearTimeout(timeout);
                        const { access_token, refresh_token, ...rest } = response.data;
                        window.userObj = {
                            ...window.userObj,
                            accessToken: access_token,
                            refreshToken: refresh_token,
                            ...rest
                        };
                        api.dispatch(loginDone(response.data));
                        // persistAuthStatus('loggedIn'); ?
                        timeout = setTimeout(
                            () => {
                                api.dispatch(saveRefreshToken());
                                // there seems to be a TS bug making this assertion necessary
                            },
                            response.data.expires_in * 1000 - 30000
                        ) as any as NodeJS.Timeout;
                    }
                })
                .catch((err) => {
                    err.error && api.dispatch(refreshTokenError(err.error));
                    api.dispatch(logout());
                });
        }
        next(action);
    };

export default refreshMobileTokenMiddleware;
