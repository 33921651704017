import React from 'react';
import useLocalization from '@hero/localization';

import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Button from '@hero/ui-kit/inputs/Button';

type Props = {
    doses: { time: string; qty: number }[];
    append: (dose: { time: string; qty: number }) => void;
};

const ScheduleDoses: React.FC<Props> = ({ doses, append }) => {
    const buttonMargin = useComponentMargin({ marginRight: 'small' });
    const { formatOnlyTime } = useLocalization();

    return (
        <>
            {doses.map((dose) => (
                <Button
                    key={dose.time}
                    onClick={() => {
                        append(dose);
                        analytics.track('Scheduled Time Option Clicked');
                    }}
                    styles={buttonMargin}
                >
                    {formatOnlyTime(dose.time)}
                </Button>
            ))}
        </>
    );
};

export default ScheduleDoses;
