import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import {
    isPasscodeRemovedSelector,
    isPasscodeLoadingSelector,
    isPasscodeUpdatedSelector,
    passcodeErrorSelector,
    isPasscodeAutoAppliedSelector
} from '@hero/redux-data/accountPasscode/selectors';
import { passcodeSelector, protectedPillsSlotNosSelector } from '@hero/redux-data/medication/selectors';
import {
    setPasscode,
    removePasscode,
    setPasscodeReset
} from '@hero/redux-data/accountPasscode/actionCreators';

import Takeover from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';
import Loader from '@hero/ui-kit/components/Loader';
import InputError from '@hero/ui-kit/inputs/InputError';

import Dialog from '../../../components/Dialog';
import HeroWheel from '../../../components/HeroWheel';
import SaveChangesModal from '../../../views/SaveChangesModal';

type LocationState = {
    slots?: number[];
};

const SecurityPasscode: React.FC = () => {
    const [showRemovePasscode, handleShowRemovePasscode] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(isPasscodeLoadingSelector, shallowEqual);
    const isUpdated = useSelector(isPasscodeUpdatedSelector, shallowEqual);
    const isRemoved = useSelector(isPasscodeRemovedSelector, shallowEqual);
    const isAutoApplied = useSelector(isPasscodeAutoAppliedSelector, shallowEqual);
    const protectedPillsSlotNos = useSelector(protectedPillsSlotNosSelector, shallowEqual);
    const { error, errorMessage } = useSelector(passcodeErrorSelector);
    const oldPasscode = useSelector(passcodeSelector, shallowEqual);
    const location = useLocation();
    const locationState = location.state as LocationState;
    const slots = locationState?.slots;

    const passcode = useSelector(passcodeSelector);
    const handleAddPasscode = (pin: string) => {
        oldPasscode && analytics.track('Edit Safety Locked Clicked');
        dispatch(setPasscode({ pin, slots: slots || protectedPillsSlotNos }));
    };
    const handleRemovePasscode = () => {
        handleShowRemovePasscode(false);
        dispatch(removePasscode());
    };
    const handleOkClick = useCallback(() => {
        navigate('/settings');
        analytics.track('Safety Lock Confirmed');
    }, [navigate]);

    useEffect(() => {
        return () => {
            dispatch(setPasscodeReset());
        };
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <Dialog title="Enter Passcode">
            {showRemovePasscode && (
                <Takeover
                    type="warn"
                    title="Remove passcode"
                    actions={[
                        { label: 'Remove passcode', action: handleRemovePasscode },
                        { label: 'Cancel', action: () => handleShowRemovePasscode(false) }
                    ]}
                >
                    <P size="large">Are you sure you want to remove passcode?</P>
                </Takeover>
            )}
            {(isUpdated || isRemoved) && (
                <SaveChangesModal onClose={handleOkClick} isAutoApplied={isAutoApplied} />
            )}
            <HeroWheel
                addPasscode={handleAddPasscode}
                removePasscode={() => handleShowRemovePasscode(true)}
                passcode={!!passcode}
            />
            {error && errorMessage && <InputError id={errorMessage}>{errorMessage}</InputError>}
        </Dialog>
    );
};

export default SecurityPasscode;
