import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import validationSchema from '@hero/validators/forms/addMedicationSchema/stepSchedule';
import { Step } from '@hero/ui-kit/inputs/WizardForm';
import H from '@hero/ui-kit/typography/H';
import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { setPillOptionsSchedules } from '@hero/redux-data/pillOptions/actionCreators';

import Schedule from '../../../formSections/Schedule';
import calcSchedulesFromForm, {
    SchedulesFormValues
} from '../../../../../takeovers/EditSingleSchedule/calcScheduleFromForm';

const StepSchedules: React.FC = () => {
    const dispatch = useDispatch();
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const handleSetStepValues = (values: SchedulesFormValues) => {
        dispatch(setPillOptionsSchedules({ schedules: calcSchedulesFromForm(values) }));
    };

    return (
        <Step<typeof validationSchema>
            order={source === 'db' ? 6 : 5}
            validationSchema={validationSchema}
            onSetStepValues={handleSetStepValues as any}
        >
            <div>
                <H role="h4">How often do you take it?</H>
                <Schedule />
            </div>
        </Step>
    );
};

export default StepSchedules;
