import React from 'react';
import Modal from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Span from '@hero/ui-kit/typography/Span';

type Props = {
    handleClose: () => void;
};
const MaximumDosageModal: React.FC<Props> = ({ handleClose }) => (
    <Modal onClose={handleClose} isCancelable>
        <H role="h4" centered>
            Manual dispense
        </H>
        <P size="large">
            Manual dispsense of medications is available on the Hero device via{' '}
            <Span strong>Dispense &gt; Medication</Span>. Your list of medications will be
            presented, followed by the requested number of pills to dispense.
        </P>
        <P size="large">
            Note: The Hero device will not dispense pills that exceed the maximum doses allowed per
            day.
        </P>
        <Button onClick={handleClose} width="large">
            Ok
        </Button>
    </Modal>
);

export default MaximumDosageModal;
