import React from 'react';

import SplashView from '@hero/ui-kit/components/SplashView';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';

const NotFound = () => (
    <SplashView>
        <Section>
            <H>404 - Not found</H>
            <H role="h2">Uh Oh. We can’t find this page</H>
            <LinkButton variant="inverted" to="/">
                Hero Home
            </LinkButton>
        </Section>
    </SplashView>
);

export default NotFound;
