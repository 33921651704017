import { logout } from '@hero/redux-data/auth/actionCreators';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { confirmEmailUpdate } from '@hero/redux-data/accountEmailUpdate/actionCreators';
import persistAuthStatus from '@hero/hero-auth/persistAuthStatus';
import {
    accountEmailUpdateErrorSelector,
    isAccountEmailUpdateLoadingSelector,
    isAccountEmailUpdateUpdatedSelector
} from '@hero/redux-data/accountEmailUpdate/selectors';
import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';

const ChangeEmailConfirm: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const params = new URLSearchParams(search.substr(1));
    const email = params.get('email');
    const activation_code = params.get('activation_code');
    const isLoading = useSelector(isAccountEmailUpdateLoadingSelector, shallowEqual);
    const isUpdated = useSelector(isAccountEmailUpdateUpdatedSelector, shallowEqual);
    const { error, errorMessage } = useSelector(accountEmailUpdateErrorSelector, shallowEqual);
    const handleCloseClick = useCallback(() => {
        navigate('/');
    }, [navigate]);

    useEffect(() => {
        if (!!email && !!activation_code) {
            analytics.track('Email Verification Request Started');
            dispatch(confirmEmailUpdate({ email, activation_code }));
        }
    }, [dispatch, email, activation_code]);
    useEffect(() => {
        if (isUpdated) {
            analytics.track('Email Verification Request Succeeded');
            persistAuthStatus('loggedOut');
            dispatch(logout());
            navigate('/');
        }
    }, [navigate, isUpdated, dispatch]);
    useEffect(() => {
        if (email) {
            analytics.track('Email Verification Request Failed');
        }
    }, [email]);

    if (isLoading) return <Loader />;

    return (
        <div>
            {error && (
                <Takeover
                    title="Email verification failed"
                    type="fail"
                    actions={[{ label: 'Ok', action: handleCloseClick }]}
                >
                    {errorMessage}
                </Takeover>
            )}
        </div>
    );
};

export default ChangeEmailConfirm;
