import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MembershipPlan } from '@hero/hero-types';
import { isLoadingSelector } from '@hero/redux-data/utils/helperSelectors';
import { getListPlans } from '@hero/redux-data/membershipListPlans/actionCreators';
import { listPlansSelector } from '@hero/redux-data/membershipListPlans/selectors';
import Loader from '@hero/ui-kit/components/Loader';
import { NavGroup, LinkNavItem } from '@hero/ui-kit/inputs/NavGroup';

import ListView from '../../../../components/ListView';
import { getPerPlanLabel } from '@hero/hero-utils/membership';

const MembershipPlans: React.FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getListPlans());
    }, [dispatch]);

    const listPlans = useSelector(listPlansSelector);
    const isLoading = useSelector(isLoadingSelector('listPlans'));

    if (isLoading) return <Loader />;

    return (
        <ListView title="Membership Plans">
            <NavGroup id="membership-plans-menu">
                {listPlans.map((item: MembershipPlan, index: number) => (
                    <LinkNavItem
                        key={index}
                        to={`/settings/membership/plans/${item.key}`}
                        description={`$${item.monthly_fee / 100} / ${getPerPlanLabel(
                            item?.storefront_key
                        )}`}
                        infoText={item.is_current ? 'Current Plan' : 'View Plan'}
                    >
                        {item.name}
                    </LinkNavItem>
                ))}
            </NavGroup>
        </ListView>
    );
};

export default MembershipPlans;
