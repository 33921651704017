import React from 'react';

import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';

import ScheduleInner from './ScheduleInner';

type Props = {
    isInEditMode?: boolean;
};

const Schedule: React.FC<Props> = ({ isInEditMode = false }) => (
    <>
        {isInEditMode ? (
            <RadioGroup name="regimen" displayName="Select frequency">
                <Radio id="regimen_daily" value="every_day" labelText="Every Day" />
                <Radio id="regimen_select_days" value="select_days" labelText="Select Days" />
            </RadioGroup>
        ) : (
            <RadioGroup name="regimen" displayName="Select frequency">
                <Radio id="regimen_daily" value="every_day" labelText="Every Day" />
                <Radio id="regimen_select_days" value="select_days" labelText="Select Days" />
                <Radio id="regimen_as_needed" value="as_needed" labelText="As Needed" />
            </RadioGroup>
        )}
        <ScheduleInner />
    </>
);

export default Schedule;
