import React, { CSSProperties, useEffect, useMemo } from 'react';

import P from '@hero/ui-kit/typography/P';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';
import LockIcon from '@hero/ui-kit/icons/branding/LockIcon';

import Dialog from '../../../components/Dialog';
import ControlsWrapper from '../../../components/Dialog/ControlsWrapper';
import InnerWrapper from '../../../components/Dialog/InnerWrapper';

const SecuritySetPasscodeConfirm: React.FC = () => {
    const iconStyles: CSSProperties = useMemo(
        () => ({
            width: '100%',
            margin: '3.6rem auto'
        }),
        []
    );

    useEffect(() => {
        analytics.page('Safety Lock Viewed');
    }, []);

    return (
        <Dialog title="Add a passcode">
            <InnerWrapper>
                <LockIcon type="utility" size="large" styles={iconStyles} />
                <P size="large" centered>
                    Keep your medication safe by requiring a four-digit code to dispense certain
                    medications.
                </P>
                <ControlsWrapper>
                    <LinkButton id="usePasscode" to="/settings/security/pills-lock">
                        Use Passcode
                    </LinkButton>
                    <LinkButton id="skipPasscode" variant="secondary" to="/settings/security">
                        No thanks
                    </LinkButton>
                </ControlsWrapper>
            </InnerWrapper>
        </Dialog>
    );
};

export default SecuritySetPasscodeConfirm;
