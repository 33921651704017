import React, { useCallback, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Takeover from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';
import Span from '@hero/ui-kit/typography/Span';
import Section from '@hero/ui-kit/layout/Section';
import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import { formatDate } from '@hero/hero-utils/date';

const PaymentFailed: React.FC = () => {
    const [isOpen, handleOpen] = useState(true);
    const navigate = useNavigate();
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const handleUpdateClick = useCallback(() => {
        navigate('/settings/membership/payment');
        handleOpen(false);
    }, [navigate]);

    if (
        !membershipDetails ||
        !membershipDetails.payment_failed_at ||
        membershipDetails.days_until_lock === null
    )
        return null;

    const failureDate = formatDate(membershipDetails?.payment_failed_at);
    const pluralizedRemainingDays =
        membershipDetails.days_until_lock === 1
            ? '1 day'
            : `${membershipDetails.days_until_lock} days`;

    return (
        <Takeover
            actions={[
                {
                    label: 'Update Payment Method',
                    action: handleUpdateClick
                },
                { label: 'Skip for now' }
            ]}
            type="fail"
            title="Payment Failed"
            externalControls={[isOpen, () => handleOpen(!isOpen)]}
        >
            <Section>
                <P size="large" centered inverted>
                    We were unable to charge your membership payment on{' '}
                    <Span strong>{failureDate}</Span>. Please update your payment method.
                </P>
                <P size="large" centered inverted>
                    <Span strong>{`${pluralizedRemainingDays} remaining `}</Span> until your account
                    is locked.
                </P>
            </Section>
        </Takeover>
    );
};

export default PaymentFailed;
