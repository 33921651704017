import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Pill } from '@hero/hero-types';
import { editPill } from '@hero/redux-data/pill/actionCreators';
import { parseScheduleDoses, parseDow } from '@hero/hero-utils/schedules';
import useLocalization from '@hero/localization';
import Button from '@hero/ui-kit/inputs/Button';
import { NavGroup, HandlerNavItem } from '@hero/ui-kit/inputs/NavGroup';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Takeover from '@hero/ui-kit/components/Takeover';
import EditSingleSchedule from '../../../../takeovers/EditSingleSchedule/EditSingleSchedule';

type Props = {
    pill: Pill;
};
const EditSchedules: React.FC<Props> = ({ pill }) => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const { formatOnlyTime } = useLocalization();
    const [showEditSchedule, handleShowEditSchedule] = useState(false);
    const [showRemoveAllSchedules, hadnleShowRemoveAllSchedule] = useState(false);
    const [currentSchedule, setCurrentSchedule] = useState<string | null>(null);
    const schedules = pill.schedules || [];
    const parsedScheduleDoses = parseScheduleDoses(schedules);

    const handleScheduleClick = useCallback(
        (scheduleId: string | null) => {
            handleShowEditSchedule(true);
            setCurrentSchedule(scheduleId);
        },
        [handleShowEditSchedule, setCurrentSchedule]
    );
    const hadnleScheduleModalClose = useCallback(() => {
        handleShowEditSchedule(false);
        setCurrentSchedule(null);
    }, [handleShowEditSchedule, setCurrentSchedule]);
    const handleRemoveSchedules = useCallback(() => {
        hadnleShowRemoveAllSchedule(false);
        dispatch(
            editPill({
                ...pill,
                schedules: [],
                pill_schedules: []
            })
        );
    }, [dispatch, pill]);

    const pillScheduleIds: number[] | undefined = React.useMemo(() => {
        const schedule = pill.schedules?.find((item) => item.id === currentSchedule);

        if (schedule) {
            const pillSchedules = pill.pill_schedules?.filter(
                (item) => item.dow === schedule.dow && item.qty === schedule.qty
            );

            return pillSchedules?.map((schedule) => schedule.hero_schedule_id || 0);
        }
        return undefined;
    }, [currentSchedule, pill]);

    return (
        <>
            {showEditSchedule && currentSchedule && (
                <EditSingleSchedule
                    id={id || ''}
                    scheduleId={currentSchedule}
                    pillScheduleIds={pillScheduleIds}
                    handleScheduleModalClose={hadnleScheduleModalClose}
                />
            )}
            {showRemoveAllSchedules && (
                <Takeover
                    type="warn"
                    title="Remove all schedules"
                    actions={[
                        { label: 'Remove All Schedules', action: handleRemoveSchedules },
                        { label: 'Cancel', action: () => hadnleShowRemoveAllSchedule(false) }
                    ]}
                >
                    <P size="large">Are you shure you want to remove all schedules?</P>
                </Takeover>
            )}
            <H role="h4">Schedules</H>
            {parsedScheduleDoses.length ? (
                <>
                    <NavGroup id="edit-schedules-menu">
                        {parsedScheduleDoses.map((item) => (
                            <HandlerNavItem
                                key={item.id}
                                infoText={item.qty}
                                onClick={() => handleScheduleClick(item.id)}
                            >
                                {`${parseDow(item.dow)} at ${formatOnlyTime(item.time)} `}
                            </HandlerNavItem>
                        ))}
                    </NavGroup>
                    <Button
                        variant="secondary"
                        width="full"
                        onClick={() => hadnleShowRemoveAllSchedule(true)}
                    >
                        Change medication schedule to "As needed"
                    </Button>
                </>
            ) : (
                <P>Used as needed.</P>
            )}
            <Button variant="secondary" onClick={() => handleScheduleClick('new')} width="full">
                Add new schedule
            </Button>
        </>
    );
};

export default EditSchedules;
