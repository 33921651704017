import React, { CSSProperties, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import AlertComponent from '@hero/ui-kit/components/AlertBar';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import P from '@hero/ui-kit/typography/P';

import useAlerts from '../../hooks/useAlerts';

const stylesContainer: CSSProperties = {
    marginBottom: '7.2rem',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 66666
};
const stylesWrapper: CSSProperties = { width: '100%', marginBottom: '-6rem', zIndex: 9999 };

const AlertBar: React.FC = () => {
    const navigate = useNavigate();
    const padding = useComponentPadding({ paddingVertical: 'regular' });
    const {
        isDisconnected,
        isInVacationMode,
        isInTravelMode,
        hasPendingChanges,
        hasMembershipPaymentFailed,
        shouldShowAlertBar
    } = useAlerts();

    const paymentBarWrapperStyles: CSSProperties = useMemo(
        () => ({
            ...padding,
            width: '100%'
        }),
        [padding]
    );

    const handlePaymentAlertClick = useCallback(() => {
        navigate('/settings/membership/payment');
    }, [navigate]);

    const renderAlert = () => {
        if (hasMembershipPaymentFailed) {
            return (
                <AlertComponent>
                    <ClickableOpacity
                        alt="Update your payment method"
                        onClick={handlePaymentAlertClick}
                        styles={paymentBarWrapperStyles}
                    >
                        <P size="small" centered strong noDefaultMargin>
                            UPDATE YOUR PAYMENT METHOD
                        </P>
                    </ClickableOpacity>
                </AlertComponent>
            );
        }
        if (isInTravelMode) {
            return <AlertComponent>TRAVEL MODE. CONNECT HERO TO WI-FI.</AlertComponent>;
        }
        if (isDisconnected && !isInVacationMode) {
            return <AlertComponent>HERO DISCONNECTED. RECONNECT WI-FI.</AlertComponent>;
        }
        if (hasPendingChanges) {
            return <AlertComponent>CONFIRMING CHANGES ON HERO</AlertComponent>;
        }
    };

    if (!shouldShowAlertBar) return null;

    return (
        <div style={stylesContainer}>
            <div style={stylesWrapper}>{renderAlert()}</div>
        </div>
    );
};

export default AlertBar;
