import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { sendTicket } from '@hero/redux-data/accountSupport/actionCreators';
import { userEmailSelector } from '@hero/redux-data/userStatus/selectors';
import { isUpdatedSelector } from '@hero/redux-data/accountSupport/selectors';

import Takeover from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';
import Section from '@hero/ui-kit/layout/Section';

import MembershipConfirmCancellation from './MembershipConfirmCancellation';

const MembershipLocked: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, handleOpen] = useState(true);
    const [hasCancelled, handleHasCancelled] = useState(false);
    const [showUpdatedModal, handleShowUpdatedModal] = useState(true);
    const [showConfirmCancellation, handleShowConfirmCancellation] = useState(false);
    const userEmail = useSelector(userEmailSelector, shallowEqual);
    const isUpdated = useSelector(isUpdatedSelector);

    const handleUpdateClick = useCallback(() => {
        navigate('/locked-payment');
        handleOpen(false);
    }, [handleOpen, navigate]);
    const handleUpdatedOkClick = useCallback(() => {
        if (!hasCancelled) {
            navigate('/settings');
            handleOpen(false);
        }
        handleShowUpdatedModal(false);
    }, [navigate, hasCancelled]);
    const handleCancelClick = useCallback(() => {
        handleHasCancelled(true);
        handleShowConfirmCancellation(false);
        handleOpen(true);
        handleShowUpdatedModal(true);
        dispatch(
            sendTicket({
                message: 'Cancel Membership',
                subject: `User ${userEmail} address is requesting to Cancel Membership.`
            })
        );
    }, [dispatch, userEmail]);

    return (
        <Takeover
            title="Membership Locked"
            type="fail"
            externalControls={[isOpen, () => handleOpen(!isOpen)]}
            actions={[
                {
                    label: 'Update Payment Method',
                    action: handleUpdateClick
                },
                {
                    label: 'Cancel Membership',
                    action: () => handleShowConfirmCancellation(true)
                }
            ]}
        >
            <Section>
                <P size="large" centered inverted>
                    Please update your payment method to resume your membership.
                </P>
                <P size="large" centered inverted>
                    It may take up to 2 hour to process your payment and unlock your account.
                </P>
            </Section>
            {showConfirmCancellation && (
                <MembershipConfirmCancellation
                    actions={[
                        { label: 'No', action: () => handleShowConfirmCancellation(false) },
                        { label: 'Yes - Cancel Membership', action: handleCancelClick }
                    ]}
                />
            )}
            {isUpdated && (
                <Takeover
                    title="Processing Request"
                    type="success"
                    externalControls={[showUpdatedModal, handleUpdatedOkClick]}
                >
                    Your request has been received, we will contact you within 24 hours. If you have
                    any questions, please give us a call at 855-855-9962.
                </Takeover>
            )}
        </Takeover>
    );
};

export default MembershipLocked;
