import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import { membershipChangePlanReset } from '@hero/redux-data/membershipChangePlan/actionCreators';
import {
    isMembershipPlanChangeLoadingSelector,
    isMembershipPlanChangeUpdatedSelector,
    membershipPlanChangeErrorSelector
} from '@hero/redux-data/membershipChangePlan/selectors';

import P from '@hero/ui-kit/typography/P';
import InputError from '@hero/ui-kit/inputs/InputError';
import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';

import MembershipUpdated from './MembesrhipUpdated';
import { MembershipPlan } from '@hero/hero-types';

type Props = {
    pickedPlan: MembershipPlan;
    showUpdatedModal: boolean;
    handleUpdatedOkClick: () => void;
};

const MembershipPlanSelected: React.FC<Props> = ({
    pickedPlan,
    showUpdatedModal,
    handleUpdatedOkClick
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, handleShowModal] = useState(true);
    const isLoading = useSelector(isMembershipPlanChangeLoadingSelector, shallowEqual);
    const isUpdated = useSelector(isMembershipPlanChangeUpdatedSelector, shallowEqual);
    const { error, errorMessage } = useSelector(membershipPlanChangeErrorSelector, shallowEqual);
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const handleUpgradedClick = useCallback(() => {
        handleShowModal(false);
        dispatch(membershipChangePlanReset());
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('hero_payment_success');
        } else {
            navigate('/settings');
        }
    }, [navigate, dispatch]);

    if (isLoading || !pickedPlan || !membershipDetails) return <Loader />;

    const { current_plan } = membershipDetails;
    const isUpgrade =
        pickedPlan?.key === 'super-hero' ||
        (current_plan.key === 'legacy-limited-free' && pickedPlan?.key === 'legacy-1-year-prepaid');
    const prorateText = isUpgrade
        ? 'Your additional membership charge will be prorated for the remaining days of the month.'
        : 'We will prorate the refund for the membership charge difference based upon the remaining days of the month.';

    return (
        <>
            {pickedPlan && pickedPlan.key !== current_plan.key && (
                <P>
                    Your new membership plan will start immediately once you confirm the membership
                    change. {prorateText}
                </P>
            )}
            {isUpdated &&
                (isUpgrade ? (
                    <MembershipUpdated
                        externalControls={[showModal, handleUpgradedClick]}
                        isUpgrade={isUpgrade}
                    />
                ) : (
                    <Takeover
                        type="success"
                        title="Membership Downgraded"
                        externalControls={[showUpdatedModal, handleUpdatedOkClick]}
                    >
                        <P size="large" centered>
                            You will no longer have access to the Hero App, notifications,
                            statistics and more. You may use the basic Hero web application to
                            manage your medication and schedules.
                        </P>
                    </Takeover>
                ))}
            {error && errorMessage && <InputError id={errorMessage}>{errorMessage}</InputError>}
        </>
    );
};

export default MembershipPlanSelected;
