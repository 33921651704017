import React from 'react';
import { isIOS, isAndroid } from 'react-device-detect';

import useTheme from '@hero/branding/theme';
import Section from '@hero/ui-kit/layout/Section';
import Takeover from '@hero/ui-kit/components/Takeover';
import SplashView from '@hero/ui-kit/components/SplashView';
import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import useSectionPadding from '@hero/ui-kit/hooks/useSectionPadding';

const AcceptInvitation: React.FC = () => {
    const { graphics } = useTheme();
    const splashPadding = useSectionPadding({
        paddingHorizontal: 'small',
        paddingVertical: 'regular'
    });

    return isIOS || isAndroid ? (
        <SplashView type="brand" styles={splashPadding}>
            <Section centered width="contained">
                <H role="h3" centered>
                    Continue on the Hero app
                </H>
                <P size="large" centered>
                    To continue, download and install the Hero mobile app.
                </P>
                <MobileIcon type="utility" styles={graphics.iconIllustration} />
                <LinkButton
                    variant="inverted"
                    to={
                        isIOS
                            ? 'https://apps.apple.com/us/app/hero-medication-manager/id1352848484'
                            : 'https://play.google.com/store/apps/details?id=com.herohealth.heroconnect'
                    }
                    styles={{ whiteSpace: 'nowrap' }}
                >
                    Get the Hero mobile app
                </LinkButton>
            </Section>
        </SplashView>
    ) : (
        <Takeover icon={MobileIcon} type="brand" title="Accept invitation on your smartphone">
            Please tap on the accept invitation link on your smartphone to get started.
        </Takeover>
    );
};

export default AcceptInvitation;
