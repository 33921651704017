import { isIOS, isAndroid } from 'react-device-detect';

import checkIfValidUrl from '@hero/hero-utils/checkIfValidUrl';
import isHandledRedirectPath from './isHandledRedirectPath';

type GetRedirectPath = (expandedLink: string) => string;
const getRedirectPath: GetRedirectPath = (expandedLink) => {
    const isUrlValid = checkIfValidUrl(expandedLink);

    if (!isUrlValid) return '';

    const { pathname, search } = new URL(expandedLink);
    const isHandled = isHandledRedirectPath(pathname);

    if (isHandled) return pathname + search;

    if (isIOS) return 'https://apps.apple.com/us/app/hero-medication-manager/id1352848484';

    if (isAndroid)
        return 'https://play.google.com/store/apps/details?id=com.herohealth.heroconnect';

    return '/mobile-only';
};

export default getRedirectPath;
