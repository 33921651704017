import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import { getUserStatus } from '@hero/redux-data/userLogin/actionCreators';
import { isLoadingSelector } from '@hero/redux-data/utils/helperSelectors';
import Loader from '@hero/ui-kit/components/Loader';
import { shouldShowMembership } from '@hero/hero-utils/membership';
import useIsFirstMount from '@hero/react-hooks/useIsFirsMount';
import useIsReadOnly from '../../hooks/useIsReadOnly';

import Menu from './Menu';
import SafetySettings from './subsections/SafetySettings';
import Security from './subsections/Security';
import SecurityPasscode from './subsections/SecurityPasscode';
import SecurityPillsLock from './subsections/SecurityPillsLock';
import SecurityPassword from './subsections/SecurityPassword';
import SecurityNewPassword from './subsections/SecurityNewPassword';
import SecurityUpdateAccount from './subsections/SecurityUpdateAccount';
import Membership from './subsections/Membership';
import MembershipPayment from './subsections/MembershipPayment';
import MembershipPlans from './subsections/Membership/MembershipPlans';
import MembershipPlanDetails from './subsections/Membership/MembershipPlanDetails';
import Notifications from './subsections/Notifications';
import NotificationsHeroReminders from './subsections/NotificationsHeroReminders';
import NotificationsMedicationReminder from './subsections/NotificationsMedicationReminder';
import About from './subsections/About';
import AboutFindCode from './subsections/AboutFindCode';
import Support from './subsections/Support';
import SupportEmail from './subsections/SupportEmail';
import SetPhoneNumber from './subsections/SetPhoneNumber';
import SecurityPrivacySettings from './subsections/SecurityPrivacySettings';
import SecuritySetPasscodeConfirm from './subsections/SecuritySetPasscodeConfirm';
import { getIsPlanActive } from './subsections/Membership/utils';
import MembershipActivePlanDetails from './subsections/Membership/activePlans/MembershipActivePlanDetails';
import MembershipActivePlans from './subsections/Membership/activePlans/MembershipActivePlans';
import useAuthStatus from '@hero/hero-auth/useAuthStatus';
import constAccessParams from '../../consts/envVars';

const Settings: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const membership = useSelector(membershipDetailsSelector, shallowEqual);
    const isLoading = useSelector(isLoadingSelector('user'));
    const isFirstMount = useIsFirstMount();
    const isReadOnly = useIsReadOnly();
    const { pathname } = useLocation();
    const isPlanActive = getIsPlanActive(membership || undefined);
    const { accessToken } = useAuthStatus(constAccessParams);

    useEffect(() => {
        accessToken && dispatch(getUserStatus());
    }, [dispatch, accessToken]);

    useEffect(() => {
        if (
            pathname === '/settings/membership' &&
            membership &&
            (isReadOnly || !shouldShowMembership(membership))
        ) {
            navigate('/not-found');
        }
    }, [pathname, isReadOnly, membership, navigate]);

    // on first mount isLoading is false, which causes routed components to be
    // mounted twice, which leads to double segment page track reports
    return isLoading || isFirstMount || !accessToken ? (
        <Loader />
    ) : (
        <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="safety-settings" element={<SafetySettings />} />
            <Route path="security" element={<Security />} />
            <Route path="security/confirm-set-passcode" element={<SecuritySetPasscodeConfirm />} />
            <Route path="security/passcode" element={<SecurityPasscode />} />
            <Route path="security/pills-lock" element={<SecurityPillsLock />} />
            <Route path="security/password" element={<SecurityPassword />} />
            <Route path="security/new-password" element={<SecurityNewPassword />} />
            <Route path="security/update" element={<SecurityUpdateAccount />} />
            <Route path="security/privacy" element={<SecurityPrivacySettings />} />
            <Route path="membership" element={<Membership />} />
            <Route path="membership/payment" element={<MembershipPayment />} />
            <Route
                path="membership/plans"
                element={isPlanActive ? <MembershipActivePlans /> : <MembershipPlans />}
            />
            <Route
                path="membership/plans/:key"
                element={isPlanActive ? <MembershipActivePlanDetails /> : <MembershipPlanDetails />}
            />
            <Route path="notifications" element={<Notifications />} />
            <Route path="notifications/hero-reminders" element={<NotificationsHeroReminders />} />
            <Route
                path="notifications/medication-reminder"
                element={<NotificationsMedicationReminder />}
            />
            <Route path="about" element={<About />} />
            <Route path="about/find-code" element={<AboutFindCode />} />
            <Route path="support" element={<Support />} />
            <Route path="support/email" element={<SupportEmail />} />
            <Route path="set-phone-number/:type" element={<SetPhoneNumber />} />
        </Routes>
    );
};

export default Settings;
