import React from 'react';
import DeviceIcon from '@hero/ui-kit/icons/branding/DeviceIcon';
import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';
import Support2Icon from '@hero/ui-kit/icons/branding/Support2Icon';
import Refill2Icon from '@hero/ui-kit/icons/branding/Refill2Icon';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import MembershipPlanPriceDetails from './MembershipPlanPriceDetails';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MembershipConfirmCancellation from '../../../../../takeovers/MembershipConfirmCancellation';
import { isUpdatedSelector, isLoadingSelector } from '@hero/redux-data/accountSupport/selectors';
import { sendTicket, sendTicketReset } from '@hero/redux-data/accountSupport/actionCreators';
import Takeover from '@hero/ui-kit/components/Takeover';
import Loader from '@hero/ui-kit/components/Loader';
import {
    membershipDetailsSelector,
    userEmailSelector
} from '@hero/redux-data/userStatus/selectors';

const MembershipActivePlanDetails: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector(isLoadingSelector, shallowEqual);
    const isUpdated = useSelector(isUpdatedSelector, shallowEqual);
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const userEmail = useSelector(userEmailSelector, shallowEqual);
    const [showUpdatedModal, handleShowUpdatedModal] = React.useState(true);
    const [showConfirmCancellation, handleShowConfirmCancellation] = React.useState(false);
    const marginTop = useComponentMargin({ marginTop: 'regular' });
    const marginLeft = useComponentMargin({ marginLeft: 'large' });
    const handleCancelMembershipClick = React.useCallback(() => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('cancel_membership');
        } else {
            handleShowConfirmCancellation(true);
        }
    }, []);
    const handleCancelClick = React.useCallback(() => {
        handleShowConfirmCancellation(false);
        dispatch(
            sendTicket({
                message: 'Cancel Membership',
                subject: `User ${userEmail} address is requesting to Cancel Membership.`
            })
        );
    }, [dispatch, userEmail]);

    const handleUpdatedOkClick = React.useCallback(() => {
        handleShowUpdatedModal(false);
        dispatch(sendTicketReset());

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('hero_payment_success');
        } else {
            navigate('/settings');
        }
    }, [dispatch, navigate]);

    const container: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        minHeight: '4rem',
        marginBottom: '2rem'
    };
    const handleKeepSubscription = React.useCallback(() => {
        navigate(`/settings/membership`);
    }, [navigate]);

    if (isLoading || !membershipDetails) return <Loader />;

    return (
        <>
            <Section centered paddingHorizontal="small">
                <P centered strong styles={{ fontSize: '28px', lineHeight: '38px' }}>
                    Are you sure you want to <br /> cancel your subscription?
                </P>
                <MembershipPlanPriceDetails />
                <P strong size="large" centered styles={{ ...marginTop }}>
                    By cancelling your subscription,
                    <br /> you'll lose access to:
                </P>
                <Section noDefaultPadding>
                    <div style={container}>
                        <DeviceIcon />
                        <P
                            noDefaultMargin
                            styles={{ ...marginLeft }}
                        >{`Award-winning smart dispenser`}</P>
                    </div>
                    <div style={container}>
                        <MobileIcon />
                        <P noDefaultMargin styles={{ ...marginLeft }}>
                            Med management app with your med <br /> list & schedule, reminders and
                            insights
                        </P>
                    </div>
                    <div style={container}>
                        <Refill2Icon />
                        <P
                            noDefaultMargin
                            styles={{ ...marginLeft }}
                        >{`Medication refill with free delivery`}</P>
                    </div>
                    <div style={container}>
                        <Support2Icon />
                        <P noDefaultMargin styles={{ ...marginLeft }}>{`24/7 live support`}</P>
                    </div>
                </Section>
                <Button
                    variant="primary"
                    styles={{ ...marginTop, width: '30rem' }}
                    onClick={handleKeepSubscription}
                >
                    Keep Subscription
                </Button>
                <Button
                    variant="outline"
                    styles={{ width: '30rem' }}
                    onClick={handleCancelMembershipClick}
                >
                    Cancel Subscription
                </Button>
            </Section>
            {showConfirmCancellation ? (
                <MembershipConfirmCancellation
                    actions={[
                        { label: 'No', action: () => handleShowConfirmCancellation(false) },
                        { label: 'Yes - Cancel Membership', action: handleCancelClick }
                    ]}
                />
            ) : null}
            {isUpdated ? (
                <Takeover
                    title="Processing Request"
                    type="success"
                    externalControls={[showUpdatedModal, handleUpdatedOkClick]}
                >
                    Your request has been received, we will contact you within 24 hours. If you have
                    any questions, please give us a call at 855-855-9962.
                </Takeover>
            ) : null}
        </>
    );
};

export default MembershipActivePlanDetails;
