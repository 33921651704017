import React from 'react';
import Takeover from '@hero/ui-kit/components/Takeover';
import P from '@hero/ui-kit/typography/P';

type Props = {
    handleRemove: () => void;
    schedule: string;
    externalControls: [boolean, (state: boolean) => void];
};

const ConfirmRemoveSchedule: React.FC<Props> = ({ handleRemove, schedule, externalControls }) => {
    return (
        <Takeover
            type="warn"
            title="Remove schedule"
            actions={[
                { label: 'Remove Schedule', action: handleRemove },
                { label: 'Cancel', action: () => externalControls[1](false) }
            ]}
        >
            <P size="large">{`Are you sure you want to remove ${schedule} schedule?`}</P>
        </Takeover>
    );
};

export default ConfirmRemoveSchedule;
