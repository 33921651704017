import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '../../../components/Dialog';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import Form from '@hero/ui-kit/inputs/Form';
import supportValidator, { SupportParams } from '@hero/validators/forms/supportEmail';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import { sendTicket, sendTicketReset } from '@hero/redux-data/accountSupport/actionCreators';
import Loader from '@hero/ui-kit/components/Loader';
import {
    userEmailSelector,
    isLoadingSelector,
    isUpdatedSelector,
    errorSelector
} from '@hero/redux-data/accountSupport/selectors';
import Takeover from '@hero/ui-kit/components/Takeover';
import InputError from '@hero/ui-kit/inputs/InputError';

const SupportEmail: React.FC = () => {
    const dispatch = useDispatch();
    const userEmail = useSelector(userEmailSelector);
    const isLoading = useSelector(isLoadingSelector);
    const isUpdated = useSelector(isUpdatedSelector);
    const { error, errorMessage } = useSelector(errorSelector);
    const subject = `User ${userEmail} sent ticket`;

    const submitFn = (params: SupportParams) => {
        dispatch(sendTicket({ subject, message: params.message }));
    };

    useEffect(() => {
        return () => {
            dispatch(sendTicketReset());
        };
    }, [dispatch]);

    if (isLoading) return <Loader />;
    return (
        <Dialog title="Email Customer Support">
            {isUpdated && (
                <Takeover type="success" title="Thanks!">
                    We'll respond by e-mail within 48 hours. If urgent, give us a call.
                    1-855-855-9962
                </Takeover>
            )}
            <Form validationSchema={supportValidator} submitFn={submitFn}>
                <Section centered>
                    <TextArea
                        placeholder="Give us a little more detail."
                        resize="vertical"
                        rows={6}
                        cols={50}
                        name="message"
                        displayName="How we can help you?"
                    />
                    <Button width="full" type="submit">
                        Next
                    </Button>
                    {error && errorMessage && (
                        <InputError id={errorMessage}>{errorMessage}</InputError>
                    )}
                </Section>
            </Form>
        </Dialog>
    );
};

export default SupportEmail;
