import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Unsubscribe from '../views/Unsubscribe';
import ChangeEmailConfirm from '../views/ChangeEmailConfirm';
import AcceptInvitation from '../views/AcceptInvitation';
import NotFound from '../views/NotFound';
import Medications from '../sections/medications';
import MedicationList from '../sections/medications/Menu';
import Schedules from '../sections/schedules';
import Settings from '../sections/settings';
import LockedPayment from '../sections/settings/subsections/MembershipPayment/LockedPayment';

import LegacyActivate from '../views/LegacyActivate';
import Shortlink from '../views/Shortlink';
import SmsAppLink from '../views/SmsAppLink';
import MobileOnly from '../views/MobileOnly';
import Refill from '../views/Refill';
import VerifyEmail from '../views/VerifyEmail';
import ActivateUser from '../views/ActivateUser';
import ResetPassword from '../views/ResetPassword';
import MembershipMobileViewWrapper from '../webview/MembershipMobileViewWrapper';
import ContinueSetup from '../takeovers/ContinueSetup';
import SelectAPlan from '../views/SelectAPlan';

const WebAppRoutes: React.FC = () => {
    const { search } = useLocation();

    const heroWeekly = React.useMemo(() => {
        return ['/unsubscribe', '/unsubscribe/hero-weekly'];
    }, []);
    const heroFill = React.useMemo(() => {
        return ['/unsubscribe-hero-fill', '/unsubscribe/hero-fill'];
    }, []);
    const heroDisconnected = React.useMemo(() => {
        return ['/unsubscribe-hero-disconnected', '/unsubscribe/hero-disconnected'];
    }, []);

    const weeklySearchParams = React.useMemo(() => {
        return search
            ? `${search}&mailing_list=weekly_newsletter`
            : '?mailing_list=weekly_newsletter';
    }, [search]);

    const fillSearchParams = React.useMemo(() => {
        return search
            ? `${search}&mailing_list=hero_fill_notifications`
            : '?mailing_list=hero_fill_notifications';
    }, [search]);

    const disconnectedSearchParams = React.useMemo(() => {
        return search
            ? `${search}&mailing_list=hero_disconnected`
            : '?mailing_list=hero_disconnected';
    }, [search]);

    return (
        <Routes>
            {/* Protected routes inside components */}
            <Route path="/" element={<MedicationList />} />
            <Route path="/medication/*" element={<Medications />} />
            <Route path="/schedules/*" element={<Schedules />} />
            <Route path="/locked-payment" element={<LockedPayment />} />
            <Route path="/settings/*" element={<Settings />} />
            {/* END Protected routes inside components */}

            <Route path="/link/:shortlink" element={<Shortlink />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/unsubscribe/mailing-list" element={<Unsubscribe />} />
            {/* TODO: REMOVE SUPPORT FOR LEGACY UNSUBSCRIBE LINKS IN Q3/Q4 2021 */}
            {heroWeekly.map((weeklyPath) => (
                <Route
                    key={weeklyPath}
                    path={weeklyPath}
                    element={<Navigate to={`/unsubscribe/mailing-list${weeklySearchParams}`} />}
                />
            ))}
            {heroFill.map((fillPath) => (
                <Route
                    key={fillPath}
                    path={fillPath}
                    element={<Navigate to={`/unsubscribe/mailing-list${fillSearchParams}`} />}
                />
            ))}
            {heroDisconnected.map((disconnectedPath) => (
                <Route
                    key={disconnectedPath}
                    path={disconnectedPath}
                    element={
                        <Navigate to={`/unsubscribe/mailing-list${disconnectedSearchParams}`} />
                    }
                />
            ))}
            {/* END REMOVE */}
            <Route path="/invite/caregiver" element={<AcceptInvitation />} />
            <Route path="/register-caregiver" element={<AcceptInvitation />} />
            <Route path="/register-activate" element={<ActivateUser />} />
            <Route path="/change-email-confirm" element={<ChangeEmailConfirm />} />
            <Route path="/static/action.html" element={<LegacyActivate />} />
            <Route path="/mobile-only" element={<MobileOnly />} />
            <Route path="/refill" element={<Refill />} />
            <Route path="/select-a-plan" element={<SelectAPlan />} />
            <Route path="/welcome" element={<SmsAppLink />} />
            <Route path="/continue-setup-with-hero-app" element={<ContinueSetup />} />
            <Route path="/verify-email" element={<VerifyEmail is_v4_fre={false} />} />
            <Route path="/v2/verify-email" element={<VerifyEmail is_v4_fre />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

const TopLevelRoutes: React.FC = () =>
    window.ReactNativeWebView ? <MembershipMobileViewWrapper /> : <WebAppRoutes />;

export default TopLevelRoutes;
