import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { verifyEmail } from '@hero/redux-data/emailVerification/actionCreators';
import {
    isEmailVerificationLoadingSelector,
    emailVerificationErrorSelector,
    emailVerificationSelector
} from '@hero/redux-data/emailVerification/selectors';
import SplashView from '@hero/ui-kit/components/SplashView';
import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';
import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';
import getURLParams from '@hero/hero-utils/URLSearchParams';

const VerifyEmail: React.FC<{ is_v4_fre: boolean }> = ({ is_v4_fre }) => {
    const { search } = useLocation();

    const email = getURLParams(search, 'email');
    const verification_code = getURLParams(search, 'verification_code');
    const session_id = getURLParams(search, 'session_id') || undefined;

    const dispatch = useDispatch();
    const isLoading = useSelector(isEmailVerificationLoadingSelector);
    const { error } = useSelector(emailVerificationErrorSelector, shallowEqual);
    const verificationResult = useSelector(emailVerificationSelector, shallowEqual);

    const isSuccess = verificationResult?.email_confirmed === true;
    const isFailure =
        !email || !verification_code || error || verificationResult?.email_confirmed === false;

    useEffect(() => {
        email &&
            verification_code &&
            dispatch(verifyEmail({ email, verification_code, is_v4_fre, session_id }));
    }, [dispatch, email, verification_code, is_v4_fre, session_id]);

    const handleContactUs = useCallback(() => {
        window.location.replace('https://herohealth.com/membership-support');
    }, []);

    return isLoading ? (
        <Loader />
    ) : isFailure ? (
        <Takeover
            type="brand"
            title="Sorry, unable to confirm this email"
            actions={[{ label: 'Contact Us', action: handleContactUs }]}
        >
            Please contact our Member Experience team to help resolve the issue.
        </Takeover>
    ) : isSuccess ? (
        <Takeover type="brand" title="Email confirmed!" icon={MobileIcon}>
            Please return to the Hero app to continue the setup process.
        </Takeover>
    ) : (
        <SplashView type="brand" />
    );
};

export default VerifyEmail;
