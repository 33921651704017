type CalcNameOptions = (name: string) => { name: string; is_generic: boolean };
const calcNameOptions: CalcNameOptions = (name) => {
    const genericMatch = name.startsWith('Generic (');

    return {
        name: genericMatch ? name.substring(name.indexOf('(') + 1, name.length - 1) : name,
        is_generic: genericMatch
    };
};

export default calcNameOptions;
