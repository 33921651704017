import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import refreshTokenMiddleware from '@hero/hero-auth/refreshTokenMiddleware';
import socketsMiddleware from '@hero/sockets-middleware';

import constAccessParams from '../consts/envVars';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import refreshMobileTokenMiddleware from '../webview/refreshMobileTokenMiddleware';

const sagaMiddleware = createSagaMiddleware();
const refreshMobTokenMiddleware = refreshMobileTokenMiddleware(constAccessParams);
const refreshDTokenMiddleware = refreshTokenMiddleware(
    constAccessParams.API_ID_HOSTNAME,
    constAccessParams.API_ID_CLIENT_ID
);
const socketMiddleware = socketsMiddleware();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
    applyMiddleware(
        window.ReactNativeWebView ? refreshMobTokenMiddleware : refreshDTokenMiddleware,
        sagaMiddleware,
        socketMiddleware
    )
);

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga, constAccessParams);

export { store };
