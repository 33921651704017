import { useState, Dispatch } from 'react';
import {
    CardNumberElement,
    CardNumberElementProps,
    CardExpiryElementProps,
    CardCvcElementProps,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/browser';
import { usStates } from '@hero/hero-utils/address/states';

import { AdditionalStripeFields } from './useAdditionalFields';
import { paymentUpdate } from '@hero/redux-data/membershipPaymentUpdate/actionCreators';

const findState = (value: string) => {
    return usStates.find((usState) => {
        return value.length === 2
            ? usState.code.toLowerCase() === value.toLowerCase()
            : usState.name.toLowerCase() === value.toLowerCase();
    });
};

const isInvalidState = (value: string) => {
    const state = findState(value);

    return state ? false : true;
};

export const useStripeFields = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardNumberError, updateCardNumberError] = useState('');
    const [cardExpError, updateCardExpErrorError] = useState('');
    const [cardCvcError, updateCardCvcErrorError] = useState('');
    const handleCardNumberChange: CardNumberElementProps['onChange'] = (e) =>
        updateCardNumberError(e.error?.message || '');
    const handleCardExpChange: CardExpiryElementProps['onChange'] = (e) =>
        updateCardExpErrorError(e.error?.message || '');
    const handleCardCvcChange: CardCvcElementProps['onChange'] = (e) =>
        updateCardCvcErrorError(e.error?.message || '');

    const handleSubmit =
        (
            dispatch: Dispatch<any>,
            additionalValues: AdditionalStripeFields,
            setErrors: (values: AdditionalStripeFields) => void
        ) =>
        async (event: any) => {
            event.preventDefault();

            const errors: AdditionalStripeFields = Object.entries(additionalValues).reduce(
                (acc, [key, val]) => ({
                    ...acc,
                    ...(key === 'state' && isInvalidState(val) ? { [key]: 'Invalid state' } : {}),
                    ...(key === 'zip_code' && (!/^\d+$/.test(val) || val.length !== 5)
                        ? { [key]: 'Invalid zip code' }
                        : {}),
                    ...(key !== 'address2' && !val ? { [key]: 'This field is required' } : {})
                }),
                {} as AdditionalStripeFields
            );
            const hasErrors = Object.values(errors).some((err) => !!err);

            setErrors(errors);

            if (!stripe || !elements) return;

            const cardElement = elements.getElement(CardNumberElement)!;

            const { token, error } = await stripe.createToken(cardElement, {
                name: additionalValues.name_on_card,
                address_line1: additionalValues.address1,
                address_line2: additionalValues.address2,
                address_city: additionalValues.city,
                address_state: additionalValues.state,
                address_zip: additionalValues.zip_code
            });

            if (error) {
                Sentry.captureException({ sentry_error: error });
            } else {
                const state = findState(additionalValues.state);
                token &&
                    token.id &&
                    !hasErrors &&
                    state &&
                    dispatch(
                        paymentUpdate({
                            stripe_token: token.id,
                            zip_code: +additionalValues.zip_code,
                            name_on_card: additionalValues.name_on_card,
                            province: state.code,
                            city: additionalValues.city,
                            address1: additionalValues.address1,
                            address2: additionalValues.address2
                        })
                    );
            }
        };

    return {
        cardNumberError,
        updateCardNumberError,
        handleCardNumberChange,
        cardExpError,
        updateCardExpErrorError,
        handleCardExpChange,
        cardCvcError,
        updateCardCvcErrorError,
        handleCardCvcChange,
        handleSubmit
    };
};

export default useStripeFields;
