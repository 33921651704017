import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import { SidebarButton } from '@hero/ui-kit/components/Sidebar';
import { useStateMachineContext } from '@hero/react-hooks/useStateMachine/context';

import { hasMedicationStoredInHero } from './typeGuards';

const StepStoredInHero: React.FC = () => {
    const { goToStep, steps } = useStateMachineContext();
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const stepNo = source === 'db' ? 4 : 3;
    const storedInHeroStepValues = steps[stepNo]?.values || {};
    const storedInHero =
        hasMedicationStoredInHero(storedInHeroStepValues) &&
        storedInHeroStepValues.stored_in_hero === 'Yes';
    const handleClick = useCallback(() => goToStep(stepNo), [goToStep, stepNo]);

    return steps[stepNo].isComplete ? (
        <SidebarButton onClick={handleClick}>{`${
            !storedInHero ? 'Not stored' : 'Stored'
        } in Hero`}</SidebarButton>
    ) : null;
};

export default StepStoredInHero;
