import React from 'react';

import { BENEFIT_LIST } from '@hero/hero-utils/membership';
import { MembershipDetails, MembershipPlan, MembershipPlanAction } from '@hero/hero-types';
import BenefitListItem from '../../../../components/BenefitListItem';

type Props = {
    membershipPlanAction: MembershipPlanAction;
    pickedPlan: MembershipPlan;
    currentPlan: MembershipDetails['current_plan'];
    isLegacyLimitedFree: boolean;
    isLegacyMonthly: boolean;
};

const MembershipPlanBenefits: React.FC<Props> = ({
    membershipPlanAction,
    pickedPlan,
    currentPlan
}) => {
    return (
        <>
            {BENEFIT_LIST.filter(
                (benefit) =>
                    benefit.keys.indexOf(
                        membershipPlanAction === 'upgrade' ? pickedPlan.key : currentPlan.key
                    ) >= 0
            ).map((item, key) => {
                return (
                    <BenefitListItem
                        key={key}
                        status={
                            membershipPlanAction === 'disgrade' ||
                            membershipPlanAction === 'current'
                                ? 'fail'
                                : 'success'
                        }
                        benefit={item.text}
                    />
                );
            })}
        </>
    );
};

export default MembershipPlanBenefits;
