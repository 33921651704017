import useTheme from '@hero/branding/theme';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import Circle from '@hero/ui-kit/graphics/Circle';
import CheckIcon from '@hero/ui-kit/icons/utility/CheckIcon';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';

type Details = {
    planName: string;
    planLabel: string;
    pricePerMonth: number;
    label?: string;
    saveOf?: string;
};

type Props = {
    selected?: boolean;
};

const MembershipPlanPriceDetails: React.FC<Props> = ({ selected = false }) => {
    const membershipDetails = useSelector(membershipDetailsSelector, shallowEqual);
    const theme = useTheme();

    let details: Details = React.useMemo(() => {
        const price =
            membershipDetails?.current_plan?.prepaid_amount ||
            membershipDetails?.current_plan?.monthly_fee ||
            0;
        const formattedPrice = Math.round(price / 100);
        if (membershipDetails?.current_plan.key === 'annual') {
            return {
                planName: 'Annual',
                planLabel: `$${formattedPrice} upfront`,
                pricePerMonth: 34,
                label: 'MOST POPULAR',
                saveOf: '22% OFF'
            };
        }
        if (membershipDetails?.current_plan.key === 'biannual') {
            return {
                planName: '24 Months',
                planLabel: `$${formattedPrice} upfront`,
                pricePerMonth: 29,
                label: 'BEST VALUE',
                saveOf: '33% OFF'
            };
        }

        return {
            planName: 'Monthly',
            planLabel: '12 months commitment',
            pricePerMonth: 44,
            label: undefined,
            saveOf: undefined
        };
    }, [membershipDetails]);

    return (
        <Section
            subtheme={['brand', 'secondary', 'gamma']}
            noDefaultPadding
            paddingHorizontal={'regular'}
            paddingVertical={'small'}
            styles={{ borderRadius: '8px', minWidth: selected ? '36rem' : '30rem' }}
        >
            {selected ? (
                <Circle
                    kind={'success'}
                    size="small"
                    styles={{
                        float: 'left',
                        marginRight: '2rem',
                        minHeight: '30px',
                        minWidth: '30px'
                    }}
                >
                    <CheckIcon width="80%" height="80%" x="10%" y="10%" />
                </Circle>
            ) : null}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontFamily: 'Gilroy, Monserrat, "Open Sans", Arial, Helvetica, sans-serif'
                }}
            >
                <div>
                    <H role="h6" noDefaultMargin>
                        {details.planName}
                    </H>
                    <P
                        noDefaultMargin
                        styles={
                            details.planName === 'Monthly'
                                ? { marginTop: '6px', fontSize: '14px' }
                                : undefined
                        }
                    >
                        {details.planLabel}
                    </P>
                    <Section
                        subtheme={['brand', 'primary', 'alpha']}
                        noDefaultPadding
                        styles={{
                            borderRadius: '8px',
                            backgroundColor: theme.colors.brand.primary.alpha,
                            paddingLeft: '2rem',
                            paddingRight: '2rem'
                        }}
                    >
                        {details.label ? (
                            <P
                                noDefaultMargin
                                inverted
                                styles={{ marginTop: '2rem', fontSize: '12px', fontWeight: 600 }}
                            >
                                {details.label}
                            </P>
                        ) : null}
                    </Section>
                </div>
                <div>
                    <div style={{ display: 'grid' }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <div
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    paddingRight: '4px'
                                }}
                            >
                                $
                            </div>
                            <div
                                style={{
                                    fontSize: '3rem',
                                    fontWeight: '700'
                                }}
                            >
                                {details.pricePerMonth}
                                <sup style={{ fontSize: '14px' }}>.99</sup>
                            </div>
                        </div>
                        <div style={{ fontSize: '12px' }}>per month</div>
                    </div>
                    {details?.saveOf ? (
                        <div
                            style={{
                                fontSize: '14px',
                                fontWeight: '800',
                                color: theme.colors.brand.primary.alpha,
                                marginTop: '2rem'
                            }}
                        >
                            {details.saveOf}
                        </div>
                    ) : null}
                </div>
            </div>
        </Section>
    );
};

export default MembershipPlanPriceDetails;
