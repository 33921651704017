import React from 'react';

import P from '@hero/ui-kit/typography/P';
import Section from '@hero/ui-kit/layout/Section';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';

import Dialog from '../../../components/Dialog';

const AboutFindCode: React.FC = () => {
    return (
        <Dialog title="Connection Code?">
            <Section width="contained" centered>
                <P centered>
                    The connection code appears on your Hero screen when setting up for the first
                    time right after you connect to Wi-Fi
                </P>
                <LinkButton width="large" to="/settings/about/device">
                    Got it
                </LinkButton>
                <LinkButton variant="secondary" width="large" to="/settings/support">
                    I still need help
                </LinkButton>
            </Section>
        </Dialog>
    );
};

export default AboutFindCode;
