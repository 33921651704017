import { Pill } from '@hero/hero-types';

export type EditPillFormValues = {
    requires_pin: boolean;
    isUnlimited: boolean;
    max_doses?: string;
    exact_pill_count?: number;
};

type GetFormDefaults = (pill: Pill) => EditPillFormValues;
const getFormDefaults: GetFormDefaults = (pill) => {
    const hasMaxDoses = pill.max_doses != null;

    return {
        requires_pin: !!pill.requires_pin,
        isUnlimited: !hasMaxDoses && !pill.schedules?.length,
        max_doses: String(hasMaxDoses ? pill.max_doses : 0),
        ...(pill.exact_pill_count && { exact_pill_count: pill.exact_pill_count })
    };
};

export default getFormDefaults;
