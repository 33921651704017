import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useToggle from '@hero/ui-kit/hooks/useToggle';
import {
    isDisconnectedSelector,
    isInVacationModeSelector,
    isInTravelModeSelector
} from '@hero/redux-data/userFlags/selectors';

import useIsReadOnly from './useIsReadOnly';

const useClickProtection = () => {
    const isDisconnected = useSelector(isDisconnectedSelector);
    const isInVacationMode = useSelector(isInVacationModeSelector);
    const isInTravelMode = useSelector(isInTravelModeSelector);
    const isReadOnly = useIsReadOnly();

    const isProtected = useMemo(
        () => [isReadOnly, isDisconnected, isInVacationMode, isInTravelMode].some((el) => el),
        [isReadOnly, isDisconnected, isInVacationMode, isInTravelMode]
    );
    const [showModal, handleShowModal] = useToggle();
    return {
        isProtected,
        isReadOnly,
        isDisconnected,
        isInVacationMode,
        isInTravelMode,
        showModal,
        handleShowModal
    };
};

export default useClickProtection;
