import React from 'react';
import { createRoot } from 'react-dom/client';
import { deviceDetect } from 'react-device-detect';

import conf from '../package.json';
import App from './App';
import Wrappers from './Wrappers';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './components/ErrorBoundary';

type UserObj = {
    accessToken: string; // new app (new mobile app version rewrote token params, thats why we have duplicates -> perfect fatality)
    refreshToken: string; // new app
    access_token?: string; // old app
    refresh_token?: string; // old app
    expires_in: number;
    obtain_time: number;
    account_id?: string | null; // this is how it should be set in newer mobile app versions
    status?: {
        account_id: string | null; // this is for backward compatibility
    };
};

// segment's api is so 2012 we absolutely need to use triple-slash directives to make it
// work with ts: https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html
/// <reference types="@types/segment-analytics" />
declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS;
        refresh_token: string;
        userObj: UserObj;
        ReactNativeWebView: {
            postMessage: (message: string) => void;
        };
        ReactNativeWebViewNoGoBack: boolean;
        _CLIENT_TYPE: string;
    }
}

const { browserName, osName } = deviceDetect(window.navigator.userAgent);
window._CLIENT_TYPE = `HeroWeb;${browserName}-${osName};${conf.version}`;

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <ErrorBoundary>
        <Wrappers>
            <App />
        </Wrappers>
    </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
