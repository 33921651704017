import React from 'react';

import Takeover from '@hero/ui-kit/components/Takeover';
import DeviceIcon from '@hero/ui-kit/icons/branding/DeviceIcon';
import P from '@hero/ui-kit/typography/P';

const DisconnectedFeatureDisabled: React.FC = () => {
    return (
        <Takeover
            actions={[{ label: 'Close' }]}
            icon={DeviceIcon}
            type="bold"
            title="Feature Disabled"
        >
            <P size="large" inverted>
                This feature is locked when Hero is disconnected.{' '}
            </P>
            <P size="large" inverted>
                Please check that it is plugged in and connected to WIFI.
            </P>
        </Takeover>
    );
};

export default DisconnectedFeatureDisabled;
