import React from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFound from '../../views/NotFound';

import Menu from './Menu';
import AddMedication from './subsections/AddMedication';
import EditMedication from './subsections/EditMedication';
import MedicationDetails from './subsections/MedicationDetails';
import useAuthStatus from '@hero/hero-auth/useAuthStatus';
import constAccessParams from '../../consts/envVars';

const Medications: React.FC = () => {
    const { accessToken } = useAuthStatus(constAccessParams);

    return accessToken ? (
        <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="add" element={<AddMedication />} />
            <Route path=":id/edit" element={<EditMedication />} />
            <Route path=":id" element={<MedicationDetails />} />
            <Route element={<NotFound />} />
        </Routes>
    ) : null;
};

export default Medications;
