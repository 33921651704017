import { Pill } from '@hero/hero-types';
import getUniqueArrVals from '@hero/hero-utils/getUniqueArrVals';

import { daysOfWeek } from '../../consts/daysOfWeek';
import { SchedulesFormValues } from './calcScheduleFromForm';

type DowFormValues = Pick<
    SchedulesFormValues,
    'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'
>;

export type EditScheduleFormValues = DowFormValues & {
    regimen: SchedulesFormValues['regimen'];
    doses: { time: string; qty: string }[];
};

type GetFormDefaults = (pill: Pill, scheduleId: string | null) => EditScheduleFormValues;
const getFormDefaults: GetFormDefaults = (pill, scheduleId) => {
    const schedule =
        pill.schedules?.length && pill.schedules.find((schedule) => schedule.id === scheduleId)
            ? pill.schedules.find((schedule) => schedule.id === scheduleId)
            : { dow: '', times: [], qty: '' };
    const parsedDow =
        schedule && schedule.dow ? schedule.dow.split(', ').map((dow) => dow.toLowerCase()) : [];
    const scheduleLength = parsedDow.length;

    const regimen =
        scheduleLength === 0 ? 'as_needed' : scheduleLength === 7 ? 'every_day' : 'select_days';
    const daysForForm: DowFormValues = Object.keys(daysOfWeek).reduce<DowFormValues>(
        (acc, cur) => ({ ...acc, [cur]: parsedDow.includes(cur) }),
        ({} as any) as DowFormValues
    );
    const uniqueTimes = schedule ? getUniqueArrVals(schedule.times) : [];
    const doses =
        pill.schedules && pill.schedules.length > 0
            ? uniqueTimes.map((time) => ({
                  time,
                  qty: String(pill.schedules?.find(({ times }) => times.includes(time))?.qty as any)
              }))
            : [];

    return {
        ...daysForForm,
        regimen,
        doses
    };
};

export default getFormDefaults;
