import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import useLocalization from '@hero/localization';
import { fetchSchedules } from '@hero/redux-data/schedules/actionCreators';
import { schedulesSelector, isLoadingSchedulesSelector } from '@hero/redux-data/schedules/selectors';
import { isFreSelector } from '@hero/redux-data/userStatus/selectors';
import Loader from '@hero/ui-kit/components/Loader';
import { NavGroup, LinkNavItem } from '@hero/ui-kit/inputs/NavGroup';

import ListView from '../../components/ListView';
import { parseDow, getMedicationCountDisplay } from '@hero/hero-utils/schedules';
import ProtectedButton from '../../components/ProtectedButton';

const Menu: React.FC = () => {
    const dispatch = useDispatch();
    const { formatOnlyTime } = useLocalization();
    const schedules = useSelector(schedulesSelector, shallowEqual);
    const isLoading = useSelector(isLoadingSchedulesSelector, shallowEqual);
    const isFre = useSelector(isFreSelector);

    useEffect(() => {
        // 403 is returned when FRE not completed
        if (isFre === false) {
            analytics.page('My Schedules Viewed');
            dispatch(fetchSchedules());
        }
    }, [dispatch, isFre]);

    return isLoading || isFre === null ? (
        <Loader />
    ) : (
        <ListView
            title="Schedules"
            actionTrigger={
                <ProtectedButton variant="secondary" to="/schedules/new">
                    Add Schedule
                </ProtectedButton>
            }
        >
            <NavGroup id="schedules-menu">
                {schedules.map((schedule) => (
                    <LinkNavItem
                        key={schedule.schedule_id}
                        to={`${schedule.schedule_id}`}
                        infoText={getMedicationCountDisplay(schedule.pills.length)}
                    >
                        {`${parseDow(schedule.dow)} at ${formatOnlyTime(schedule.time)}`}
                    </LinkNavItem>
                ))}
            </NavGroup>
        </ListView>
    );
};

export default Menu;
