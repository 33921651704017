import React from 'react';

import Takeover from '@hero/ui-kit/components/Takeover';
import DeviceIcon from '@hero/ui-kit/icons/branding/DeviceIcon';
import P from '@hero/ui-kit/typography/P';

type Props = {
    isAutoApplied?: boolean;
    onClose?: () => void;
};

const Success: React.FC<Props> = ({ onClose, isAutoApplied = false }) =>
    isAutoApplied ? (
        <Takeover type="success" title="Done!" onClose={onClose}>
            <P size="large">Your changes will be automatically applied on the Hero device</P>
        </Takeover>
    ) : (
        <Takeover type="success" title="Save Changes on Hero" icon={DeviceIcon} onClose={onClose}>
            <P size="large">Press the center button on Hero when prompted</P>
        </Takeover>
    );

export default Success;
