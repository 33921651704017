import React from 'react';

const HeroWheel: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="236" height="236" viewBox="0 0 236 236">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <circle cx="117.81" cy="117.81" r="117.81" fill="#E7E7E7" />
                <circle
                    cx="118.192"
                    cy="118.192"
                    r="109.778"
                    fill="#FFF"
                    stroke="#FF9858"
                    strokeWidth="4.59"
                />
                <path
                    stroke="#434343"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.53"
                    d="M124.862 191.621l-4.327 4.328m-2.412 1.916l-6.243-6.244M124.997 43.855l-4.327-4.327m-2.412-1.916l-6.243 6.243M39.69 123.392l-4.327-4.327m-1.916-2.412l6.243-6.243M195.446 123.392l4.328-4.327m1.916-2.412l-6.244-6.243"
                />
                <circle cx="118.575" cy="118.575" r="53.55" fill="#E7E7E7" />
            </g>
            <text
                fill="#434343"
                fontFamily="Gilroy-Bold, Gilroy"
                fontSize="12.24"
                fontWeight="bold"
            >
                <tspan x="116" y="60">
                    1
                </tspan>
            </text>
            <text
                fill="#434343"
                fontFamily="Gilroy-Bold, Gilroy"
                fontSize="12.24"
                fontWeight="bold"
            >
                <tspan x="184.308" y="123.69">
                    2
                </tspan>
            </text>
            <text
                fill="#434343"
                fontFamily="Gilroy-Bold, Gilroy"
                fontSize="12.24"
                fontWeight="bold"
            >
                <tspan x="115.397" y="189.48">
                    3
                </tspan>
            </text>
            <text
                fill="#434343"
                fontFamily="Gilroy-Bold, Gilroy"
                fontSize="12.24"
                fontWeight="bold"
            >
                <tspan x="43.43" y="122.925">
                    4
                </tspan>
            </text>
        </g>
    </svg>
);

export default HeroWheel;
