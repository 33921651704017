import { PillSchedule } from '@hero/hero-types';
import getUniqueArrVals from '@hero/hero-utils/getUniqueArrVals';

import { daysOfWeek } from '../../consts/daysOfWeek';

export type SchedulesFormValues = {
    regimen: 'as_needed' | 'every_day' | 'select_days';
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
    doses: { time: string; qty: string }[];
};

export type CalcSchedulesFromForm = (values: SchedulesFormValues) => Omit<PillSchedule, 'id'>[];
const calcSchedulesFromForm: CalcSchedulesFromForm = ({
    regimen,
    mon,
    tue,
    wed,
    thu,
    fri,
    sat,
    sun,
    doses
}) => {
    if (regimen === 'as_needed') return [];

    const checkedDaysFromForm: Record<string, boolean> = { mon, tue, wed, thu, fri, sat, sun };
    const checkedDays = Object.keys(checkedDaysFromForm).reduce<string[]>(
        (acc, cur) => (!!checkedDaysFromForm[cur] ? [...acc, cur] : acc),
        []
    );
    const dow = regimen === 'select_days' ? checkedDays : Object.keys(daysOfWeek);

    const uniqueQtys = getUniqueArrVals(doses.map((dose) => dose.qty));
    const schedules = uniqueQtys.map((qty) => ({
        dow,
        qty: +qty,
        times: getUniqueArrVals(doses.filter((dose) => dose.qty === qty).map((dose) => dose.time))
    }));

    return schedules;
};

export default calcSchedulesFromForm;
