import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import constAccessParams from '../../consts/envVars';

const ResetPassword: React.FC = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const email = query.get('email') || '';
    const token = query.get('activation_code') || '';
    const newSearch = `?email=${encodeURIComponent(email)}&token=${token}&client_id=${
        constAccessParams.API_ID_CLIENT_ID
    }`;

    useEffect(() => {
        window.location.replace(constAccessParams.API_ID_HOSTNAME + 'set-new-pass/' + newSearch);
    }, [newSearch]);

    return null;
};

export default ResetPassword;
