import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InferType } from 'yup';

import useLocalization from '@hero/localization';
import setPhoneNumberSchema from '@hero/validators/forms/setPhoneNumber';

import { setPhone } from '@hero/redux-data/setPhone/actionCreators';
import {
    isLoadingSetPhoneSelector,
    isUpdatedSetPhoneSelector,
    setPhoneErrorSelector,
    phoneConfigSelector
} from '@hero/redux-data/setPhone/selectors';
import { mobileSelector } from '@hero/redux-data/userStatus/selectors';

import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import Loader from '@hero/ui-kit/components/Loader';

import Dialog from '../../../components/Dialog';
import InnerWrapper from '../../../components/Dialog/InnerWrapper';
import SubmitFailedModal from '../../../views/SubmitFailedModal';
import SetPhoneNumberVerify from './SetPhoneNumberVerify';
import SetPhoneNumberSuccessModal from './SetPhoneNumberSuccessModal';

const SetPhoneNumber: React.FC = () => {
    const { type } = useParams<{ type: 'landline' | 'mobile' }>();
    const [shouldStartOver, startOver] = useState(false);
    const { t } = useLocalization();
    const mobile = useSelector(mobileSelector, shallowEqual);
    const phoneConfig = useSelector(phoneConfigSelector, shallowEqual);
    const isLoading = useSelector(isLoadingSetPhoneSelector, shallowEqual);
    const isUpdated = useSelector(isUpdatedSetPhoneSelector, shallowEqual);
    const { error, errorMessage } = useSelector(setPhoneErrorSelector, shallowEqual);
    const is_landline = type === 'landline';
    const title = is_landline ? t('Enter Phone Number to Call') : t('Enter Phone Number for SMS');
    const defaults = useMemo(() => ({ mobile: mobile || '+1' }), [mobile]);

    const dispatch = useDispatch();
    const handleStartOver = useCallback(() => {
        startOver(true);
    }, [startOver]);
    const handleSubmit = useCallback(
        (values: InferType<typeof setPhoneNumberSchema>) => {
            startOver(false);
            analytics.track('Phone Update Request Started');
            dispatch(setPhone({ ...values, is_landline }));
        },
        [dispatch, is_landline]
    );
    useEffect(() => {
        isUpdated &&
            phoneConfig?.requestVerification &&
            analytics.track('Phone Update Request Succeeded');
    }, [isUpdated, phoneConfig]);
    useEffect(() => {
        error && analytics.track('Phone Update Request Failed');
    }, [error]);

    if (isLoading) return <Loader />;
    if (!error && isUpdated && phoneConfig?.requestVerification && !shouldStartOver)
        return <SetPhoneNumberVerify handleStartOver={handleStartOver} />;
    if (!error && isUpdated && phoneConfig?.mobile && !phoneConfig?.requestVerification)
        return (
            <SetPhoneNumberSuccessModal redirectUrl="/settings/notifications/medication-reminder" />
        );

    return (
        <Dialog title={title}>
            {error && <SubmitFailedModal>{errorMessage}</SubmitFailedModal>}
            <InnerWrapper size="small">
                <Form
                    validationSchema={setPhoneNumberSchema}
                    defaultValues={defaults}
                    submitFn={handleSubmit}
                    render={(_, formState) => (
                        <>
                            <Input type="tel" name="mobile" displayName="Phone Number" />
                            <Button type="submit" disabled={!formState.isValid} width="full">
                                Next
                            </Button>
                        </>
                    )}
                />
            </InnerWrapper>
        </Dialog>
    );
};

export default SetPhoneNumber;
