import React, { CSSProperties, useMemo } from 'react';

import { Status } from '@hero/branding/types';
import StatusCircle from '@hero/ui-kit/graphics/StatusCircle';
import Span from '@hero/ui-kit/typography/Span';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import P from '@hero/ui-kit/typography/P';

type Props = { status: Status; benefit: string };

const BenefitListItem: React.FC<Props> = ({ status, benefit }) => {
    const wrapperStyles: CSSProperties = useMemo(
        () => ({
            display: 'flex',
            alignItems: 'center'
        }),
        []
    );
    const statusCircleMargin = useComponentMargin({ marginRight: 'regular' });

    return (
        <P styles={wrapperStyles}>
            <StatusCircle size="tiny" status={status} styles={statusCircleMargin} />
            <Span>{benefit}</Span>
        </P>
    );
};

export default BenefitListItem;
