import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { activateUser } from '@hero/redux-data/userActivation/actionCreators';
import {
    isUserActivationLoadingSelector,
    isUserActivationUpdatedSelector,
    userActivationErrorSelector
} from '@hero/redux-data/userActivation/selectors';
import SplashView from '@hero/ui-kit/components/SplashView';
import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';
import MobileIcon from '@hero/ui-kit/icons/branding/MobileIcon';
import getURLParams from '@hero/hero-utils/URLSearchParams';

const ActivateUser: React.FC = () => {
    const { search } = useLocation();

    const email = getURLParams(search, 'email');
    const activation_code = getURLParams(search, 'activation_code');
    const caregiver_flag = getURLParams(search, 'caregiver_flag');
    const owner_name = getURLParams(search, 'owner_name');

    const dispatch = useDispatch();
    const isLoading = useSelector(isUserActivationLoadingSelector);
    const isUpdated = useSelector(isUserActivationUpdatedSelector);
    const { error } = useSelector(userActivationErrorSelector, shallowEqual);

    const isSuccess = isUpdated;
    const isFailure = !email || !activation_code || error;

    useEffect(() => {
        email &&
            activation_code &&
            dispatch(
                activateUser({
                    email,
                    activation_code,
                    ...(caregiver_flag === '1' && {
                        caregiver_flag: true,
                        owner_name: owner_name || ''
                    })
                })
            );
    }, [dispatch, email, activation_code, caregiver_flag, owner_name]);

    return isLoading ? (
        <Loader />
    ) : isFailure ? (
        <Takeover icon={MobileIcon} type="brand" title="Accept invitation on your smartphone">
            Please tap on the accept invitation link on your smartphone to get started.
        </Takeover>
    ) : isSuccess ? (
        <Takeover type="brand" title="Account activated!" icon={MobileIcon}>
            Please return to the Hero app to continue the setup process.
        </Takeover>
    ) : (
        <SplashView type="brand" />
    );
};

export default ActivateUser;
