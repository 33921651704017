import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { Step } from '@hero/ui-kit/inputs/WizardForm';
import { enteredPillOptionsSelector } from '@hero/redux-data/pillOptions/selectors';
import validationSchema from '@hero/validators/forms/addMedicationSchema/stepPillCount';

import PillCount from '../../../formSections/PillCount';

const StepPillCount: React.FC = () => {
    const { source } = useSelector(enteredPillOptionsSelector, shallowEqual);
    const handleComplete = useCallback(
        ({ exact_pill_count }: { exact_pill_count?: number | null }) => {
            analytics.track('Exact Pill Count Changed', { exact_pill_count });
        },
        []
    );

    return (
        <Step<typeof validationSchema>
            order={source === 'db' ? 8 : 7}
            validationSchema={validationSchema}
            onSetStepValues={handleComplete}
        >
            <PillCount />
        </Step>
    );
};

export default StepPillCount;
