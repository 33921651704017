import React, { ReactText, ReactNode, CSSProperties, useMemo } from 'react';

import usePageTitle from '@hero/react-hooks/usePageTitle';
import { SubthemeConfig } from '@hero/ui-kit/hooks/useSubtheme';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';

import { useScreenSize } from '../../context/screenSize';

type Props = {
    title: ReactText;
    subtheme?: SubthemeConfig;
    actionTrigger?: ReactNode;
    children?: React.ReactNode;
};

const ListView: React.FC<Props> = ({ children, title, subtheme, actionTrigger }) => {
    usePageTitle(`Hero | ${title}`);

    const screenSize = useScreenSize();

    const wrapperStyles: CSSProperties = useMemo(
        () => ({
            width: '100%',
            maxWidth: '64rem'
        }),
        []
    );

    const headerPadding = useComponentPadding({
        paddingLeft: screenSize === 'mobile' ? 'regular' : undefined
    });

    const listHeaderStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    };

    return (
        <Section subtheme={subtheme} role="wrapper">
            <Section
                centeredHorizontally
                noDefaultPadding
                {...(screenSize !== 'mobile' && { paddingHorizontal: 'regular' })}
                paddingVertical={screenSize === 'mobile' ? 'regular' : 'large'}
            >
                <div style={wrapperStyles}>
                    <div style={listHeaderStyles}>
                        <H role="h5" styles={headerPadding}>
                            {title}
                        </H>
                        {actionTrigger}
                    </div>
                    {children}
                </div>
            </Section>
        </Section>
    );
};

export default ListView;
