import React from 'react';
import H from '@hero/ui-kit/typography/H';
import SplashView from '@hero/ui-kit/components/SplashView';

type Props = {
    children?: React.ReactNode;
};
type State = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
    state = {
        hasError: false
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        const { children } = this.props;

        return (
            <>
                {this.state.hasError ? (
                    <SplashView
                        children={<H centered={true} role="h2" children="Something Went Wrong" />}
                        type="fail"
                    />
                ) : (
                    children
                )}
            </>
        );
    }
}

export default ErrorBoundary;
