import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { membershipDetailsSelector } from '@hero/redux-data/userStatus/selectors';
import { shouldShowMembership } from '@hero/hero-utils/membership';
import Logout from '@hero/hero-auth/Logout';
import { NavGroup, LinkNavItem, HandlerNavItem } from '@hero/ui-kit/inputs/NavGroup';

import constAccessParams from '../../consts/envVars';
import ListView from '../../components/ListView';
import useIsReadOnly from '../../hooks/useIsReadOnly';

const Menu: React.FC = () => {
    const showLogoutModal = useState(false);
    const initLogout = useCallback(() => showLogoutModal[1](true), [showLogoutModal]);
    const membership = useSelector(membershipDetailsSelector, shallowEqual);
    const isReadOnly = useIsReadOnly();

    useEffect(() => {
        showLogoutModal[0] && analytics.page('Logout Viewed');
    }, [showLogoutModal]);

    useEffect(() => {
        analytics.page('Settings Viewed');
    }, []);

    return (
        <ListView title="My Settings">
            <NavGroup id="settings-menu">
                <LinkNavItem to="/settings/safety-settings">Safety Settings</LinkNavItem>
                <LinkNavItem to="/settings/security">Account and Security</LinkNavItem>
                {!isReadOnly && shouldShowMembership(membership) && (
                    <LinkNavItem to="/settings/membership">Manage Membership</LinkNavItem>
                )}
                <LinkNavItem to="/settings/notifications">Notification Preferences</LinkNavItem>
                <LinkNavItem to="/settings/about">About</LinkNavItem>
                <LinkNavItem to="/settings/support">Get Support</LinkNavItem>
                <HandlerNavItem onClick={initLogout}>Logout</HandlerNavItem>
            </NavGroup>
            <Logout showLogoutModal={showLogoutModal} constAccessParams={constAccessParams} />
        </ListView>
    );
};

export default Menu;
